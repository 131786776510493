export const onLoginAdminSuccess = (obj) => {
    return{
        type : 'LOGIN_ADMIN_SUCCESS',
        payload : obj
    }
}

export const onLogout = (obj) => {
    return{
        type : 'LOGOUT_ADMIN'
    }
}