import React, { Component } from 'react';
import LoadingPage from '../../components/LoadingPage';
import Axios from 'axios';
import { urlApi } from '../../support/constant';
// import MyLink from '../../components/Link';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash,faEye} from '@fortawesome/free-solid-svg-icons'
import DataEmpty from '../../components/dataEmplty';
import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from 'reactstrap'
import ModalTable from './../../components/modalTable'
import {connect} from 'react-redux'
import { getHeaderAuth } from '../../support/functions/GetCookies';
// import Moment from 'moment'
// const data = []

class ModalInput extends Component{
    state={
        error : ''
    }
    onSubmit = () => {
        var name = this.refs.nama.value
        // alert(name)
        // alert(this.props.idKamar)
        if(name){
            Axios.post(urlApi + 'admin/addmadin?id=' +this.props.id_user , {
                id_pondok : this.props.idKamar,
                nama : name
            },getHeaderAuth())
            .then((res) => {
                if(res.data.error){
                    this.setState({error : res.data.message})
                }else{
                    // this.setState()
                    this.props.toggle()
                    alert('Data Berhasil Ditambah')
                    // this.componentDidMount()
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else{
            this.setState({error : "Nama Tidak Boleh Kosong"})
            setTimeout(() => this.setState({error : ''}),1000)
        }
    }
    
    render(){
      const closeBtn = <button className="close" onClick={this.props.toggle}>&times;</button>;
        return(
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle} close={closeBtn}>Tambah Kelas</ModalHeader>
            <ModalBody>
                <div className='p-3'> 
                    <input ref='nama' id='error' placeholder='Masukan Nama Kelas ...' type='text' className='form-control' />
                    <h6 for="error" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error} </h6>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
            </ModalFooter>
          </Modal>
        )
    }
}


class DetailKamar extends Component {
  state = {
      data : null,
      openInputModal : false,
      id_kamar : null,
      all_data : [],
      data_selected : []
  }
  componentDidMount(){
      var id = this.props.match.params.id
      Axios.get(urlApi + 'admin/getmadinbyidpondok?id=' + id,getHeaderAuth() )
      .then((res) => {
          this.setState({data :res.data.data , id_kamar : id})
        })
      .catch((err) => {
          console.log(err)
      })
      this.getAllDataSantri()
  }
  toogleModalInput = () => {
      this.setState({openInputModal : !this.state.openInputModal})
      this.componentDidMount()
  }
  onDeleteClick =(id) => {
    //   alert(id)
      if(window.confirm('Yakin Mau Delete Data?')){
          Axios.delete(urlApi + 'admin/deletemadin?id=' +  id +'&id_user=' + this.props.dataAdmin.id,getHeaderAuth() )
          .then((res) => {
              if(res.data.error){
                  console.log(res.data)
              }else{
                  alert(res.data.message)
                  this.componentDidMount()
              }
          })
          .catch((err) => {
              console.log(err)
          })
      }
  }
  onSelectClick = (id_user) => {
    //   console.log(id_user)
    this.setState({data_selected : [...this.state.data_selected,id_user]})
    console.log(this.state.data_selected)
  }

  getAllDataSantri =() => {
    Axios.get(urlApi + 'admin/getdatasantriwithmadin',getHeaderAuth())
    .then((res) => {
        console.log(res.data)
        // const data_temp = res.data.data
        // const rows = data_temp.map((val,index) => {
        //     return{
        //         'id' : val.id_user,
        //         'nama lengkap' : val.nama_lengkap,
        //         'kelamin' : val.kelamin,
        //         'tanggal lahir' : Moment(val.tanggal_lahir).format('DD-MM-YYYY'),
        //         'jenjang pendidikan' : val.jenjang_pendidikan,
        //         'kamar' : val.kamar,
        //         'foto' : <img src={urlApi + val.foto} alt='broken' width='100px' />
        //     }
        // })
        this.setState({all_data:res.data.data})
    })
    .catch((err) => {
        console.log(err)
    })
  }
  onResetData =() => {
      this.componentDidMount()
  }


  renderData = () =>{
      console.log('ini Data')
      console.log(this.state.data)
      console.log(this.state.all_data)
      return this.state.data.map((val) => {
          return(
            <div className='col-md-6 container-card-kelas'>
            <div className='label-data font-18 ml-3 mt-3 text-capitalize'>
                {val.nama}
            </div>
            <div className='mx-3 mb-3 row justify-content-between'>
                <span>
                    <span className='label-data'>Jumlah Santri</span> 
                    <span className='data-label font-weight-bold'> {
                    //    length_kelas/
                    val.jumlah == null ? 0 :val.jumlah
                    }</span>
                </span>
                <span>
                    {/* <FontAwesomeIcon style={{cursor:'pointer'}} className='text-info'
                        icon={faEye}
                    /> */}
                    
                    <span className='mx-4'>
                    <ModalTable data_apa = 'madin' iconcolor='info' icon={faEye} id_kamar_sub={val.id} title={'Data Santri Kelas ' + val.nama} data={
                        this.state.all_data.filter((data) => {
                            return data.madin === val.nama && data.id_pondok === val.id_pondok
                        })
                    }
                        data_all = {this.state.all_data}
                        resetData = {this.onResetData}
                    />
                        {/* <ModalTable iconcolor='success' icon={faPlusCircle} title='Tambah Santri'/> */}
                    </span>
                    <FontAwesomeIcon style={{cursor:'pointer'}} className='text-danger' onClick={() => this.onDeleteClick(val.id)}
                        icon={faTrash}
                    />
                    {/* <button style={{fontSize:'10px'}} className='btn btn-success'> Lihat Daftar Santri </button> */}
                    {/* <button style={{fontSize:'10px'}} className='btn btn-info'> Tambah Santri </button> */}
                </span>
            </div>
        </div>
          )
      })
  }

  render() {
    if(this.state.data === null){
        return(
            <LoadingPage/>
        )
    }
    return (
        <div className='container'>
            <ModalInput isOpen={this.state.openInputModal} toggle={this.toogleModalInput} idKamar={this.state.id_kamar} id_user = {this.props.dataAdmin.id} />
                <div className='row justify-content-between'>
                <span>
                <h1 style={{display:'inline'}} className='judul-form'> Kelas Detail</h1> <span className='ml-3 font-weight-bold'>( {this.state.data.length ===0 ? null : this.state.data[0].pondok})</span> 
                </span>
                <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={() => this.setState({openInputModal : true})}> Tambah Data </button>
            </div>
            <div className='row mt-5'>
                {
                    this.state.data.length === 0 ?
                    <DataEmpty title='Pondok Ini Belum Punya Kelas' fontSize={20} />
                    :
                    this.renderData()
                }
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(DetailKamar);
