import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MDBNav,MDBNavItem,MDBNavLink,MDBTabContent,MDBTabPane, MDBCard, MDBCardBody, MDBCardText, MDBCardFooter } from 'mdbreact'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import { Link } from 'react-router-dom';

export class LihatRaport extends Component {
    
    componentDidMount(){
        this.getDataKelas()

    }
    state = {
        dataPondok : null,
        dataKelas : null,
        activeItem :'1'
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
            activeItem: tab
            });
        }
    };

    getDataKelas = () => {
        Axios.get(urlApi + 'academic/getallkelas',getHeaderAuth())
        .then((res) =>{
            console.log(res.data.data)
            let response = res.data.data
            let dataPondok = []
            response.forEach((val) => {
                if(!dataPondok.includes(val.pondok)){
                    dataPondok.push(val.pondok)
                }
            })
            this.setState({dataPondok})
            this.setState({dataKelas : res.data.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderTabDataPondok = () => {
        return this.state.dataPondok.map((val,index) => {
            return(
                <MDBNavItem>
                    <MDBNavLink style={{color:'black'}} to="#" active={this.state.activeItem === `${index+1}`} onClick={this.toggle(`${index+1}`)} role="tab" >
                    {val}
                    </MDBNavLink>
                </MDBNavItem>
            )
        })
    }

    renderTabContent= () =>{
        return this.state.dataPondok.map((val,index) => {
            return(
                <MDBTabPane tabId={`${index+1}`} role="tabpanel">
                    <div className='row mt-3'>
                        {
                            this.state.dataKelas.filter((kelas) => {
                                return kelas.pondok === val
                            }).map((dataKelas)=>{
                                return(
                                    <div className="col-md-3 mt-4 col-6">
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardText>{dataKelas.nama}</MDBCardText>
                                                <MDBCardFooter>
                                                    <Link to={'/admin/lihat-raport-detail/' + dataKelas.id}>
                                                        <input style={{fontSize:'8px',padding:'5px'}} type='button' className='btn btn-outline-success' value='lihat detail' />
                                                    </Link>
                                                </MDBCardFooter>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </div>
                                )
                            })
                        }
                    </div>
                </MDBTabPane>
            )
        })
    }
    render() {
        if(this.state.dataPondok === null || this.state.dataKelas === null){
            return(
                <LoadingPage />
            )
        }
        return (
            <div>
                <h1>Lihat Raport Santri</h1> 
                <MDBNav className="nav-tabs mt-5">
                    {this.renderTabDataPondok()}
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem} >
                    {this.renderTabContent()}
                </MDBTabContent>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(LihatRaport)
