import React, { Component } from 'react'
import {  Form, FormGroup, Input,Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import {onLoginAdminSuccess} from './../../redux/action/loginAdmin'
// import { saveDataAlamat } from './../../redux/action'
// import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import  Spinner from 'react-loader-spinner'
import Modal from './../../components/Modal'
import {encrypt} from './../../support/functions/encryption'
import {Helmet} from 'react-helmet';
// import Modal from './../../components/Modal'
// import ModalConfrim from './../../components/ModalConfirm'
// import Axios from 'axios';

class LoginPage extends Component {
    state = {
        loading : false,
        modalOpen : false,
        error : null
    }


    onBtnLoginClick = () => {
        this.setState({loading : true})
        const username = this.refs.username.refs.usernameInner.value
        const password = this.refs.password.refs.passwordInner.value
        if(password === '' || username ===''){
            this.setState({modalOpen:true, error : "Username Or Password Must be Filled",loading:false})
        }else{
            console.log(username)
            console.log(password)
            Axios.post(urlApi + 'admin/loginadmin',{username,password})
            .then((res) => {
                if(res.data.error === false){
                    var {key} = require('./../../support/constant/index')
                    var values = res.data.data[0].username
                    var token = res.data.token
                    values =encrypt(values)
                    console.log(key)
                    console.log(values)
                    localStorage.setItem(key , token , {path : '/'})
                    this.props.onLoginAdminSuccess(res.data.data[0])
                    console.log(res.data.data)
                }
                else{
                    this.setState({modalOpen : true , error : res.data.msg , loading:false})
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    render() {
        if(this.props.dataAdmin.username !== ''){
            return <Redirect to='/admin' />
        }
        return (
            <Fade big>
            <div style={{width : '100%'}} className='gradient-green  py-5'>
                <Helmet>
                    <title>
                        Login Admin
                    </title>
                </Helmet>

                <div className='d-flex justify-content-center py-5 mt-5'>
                    <div className='col-md-4'>
                        <div className='d-flex justify-content-between p-4 top-info'>
                            <div>
                                <h3>Selamat Datang Admin</h3>
                                <div> Masukan Username dan Password </div>
                            </div>
                            <div className='icon-user'>
                                <FontAwesomeIcon icon={faAddressBook} size='4x'/>
                            </div>
                        </div>  
                        <div className='cont-small p-4'>
                            <Form>
                                <FormGroup row>
                                    <Col sm={12} md={12} className='mt-md-0 mt-4 mt-sm-0'>
                                        <Input type='text' ref='username' innerRef='usernameInner' placeholder='masukan username'/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12} md={12} className='mt-md-0 mt-4 mt-sm-0'>
                                        <Input type='password' ref='password' innerRef='passwordInner' placeholder='masukan password'/>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    {
                                        this.state.loading ?
                                        <div className='row justify-content-center'>
                                            <Spinner 
                                                type="Oval"
                                                color="#006b53"
                                                height="18"	
                                                width="18"
                                            /> 

                                        </div>
                                        :
                                        <Input type='button' value='LOGIN' onClick={this.onBtnLoginClick} className='btn btn-success' />
                                    }
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    {/*  ===================== MODAL ERROR MESSAGE =================== */}
                    <Modal 
                        onClose={() => this.setState({modalOpen : false})} 
                        title = 'Error' content ={this.state.error} 
                        openModal ={this.state.modalOpen}/>
                </div>

            </div>
            </Fade>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}


export default  connect(mapStateToProps , {onLoginAdminSuccess})(LoginPage)