import React, { Component } from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
} from "@react-pdf/renderer";
import LoadingPage from './../../components/LoadingPage'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import { RoundEpsilon } from '../../support/functions/RoundEpsilon';





const styles = StyleSheet.create({
    page: {
        // backgroundColor: 'red',
        padding:20,

    },
    header: {
        display :"flex",
        flexDirection:'column',
        justifyContent:'center',
        alignItems : "center",
        // backgroundColor : "red",
        borderBottomColor : "black",
        borderBottomWidth : 1.5,
        paddingBottom : 5
    },
    title : {
        flex : 1,
        fontSize : 16,
    },
    subtitle : {
        fontSize : 10,
        flex : 1,
    },
    documenTitle : {
        justifyContent : "center",
        alignItems : "center",
        fontSize : 20,
        marginTop : 10,
    }
    
   
})
 
class Raport extends Component {
    state = {
        dataUser : null,
        dataKelasDanPelajaran : null,
        dataNilai : null,
        rata_rata : null
    }
    componentDidMount(){
        var id = this.props.match.params.id
        this.getDataSantri(id)

    }
    getDataSantri = (id) => {
        Axios.get(urlApi + 'raport/getuserbyid/' + id, getHeaderAuth())
        .then((res) => {
            this.setState({dataUser : res.data.data[0]})
            console.log(res.data.data)
            this.getPelajaranByKelas(res.data.data[0].id_madin)
            this.getDataNilaiByIdUser(id)
            this.getRataRataKelas(res.data.data[0].id_madin)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getDataNilaiByIdUser = (id_user) => {
        Axios.get(urlApi + 'raport/getdatanilaibyiduser/' + id_user,getHeaderAuth())
        .then((res) => {
            this.setState({dataNilai : res.data.data})
            console.log(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getPelajaranByKelas = (idkelas) => {
        Axios.get(urlApi + 'raport/getdatapelajaranustadzbyidkelas/' + idkelas,getHeaderAuth())
        .then((res) => {
            this.setState({dataKelasDanPelajaran : res.data.data})
            console.log(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    getRataRataKelas = (id_kelas) => {
        Axios.get(urlApi + 'raport/getrataratakelas/' + id_kelas , getHeaderAuth())
        .then((res) => {
            console.log(res.data.data)
            this.setState({rata_rata : res.data.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderNilai = () => {
        let data = []
        let avg_siswa = 0;
        this.state.dataKelasDanPelajaran.forEach((val) => {
            var obj = {}
            obj.pelajaran = val.pelajaran
            obj.ustadz = val.ustadz
            obj.panggilan = val.panggilan
            var nilai = this.state.dataNilai.filter((nilai) => {
                return nilai.id_pelajaran === val.id_pelajaran
            })
            obj.nilai = nilai.length > 0 ? nilai[0].nilai : 0
            avg_siswa += nilai.length > 0 ? nilai[0].nilai : 0
            var rata = this.state.rata_rata.filter((rata) => {
                return rata.id_pelajaran === val.id_pelajaran
            })
            obj.rata_rata = rata.length > 0 ? rata[0].rata_rata : 0

            data.push(obj)
        })

        avg_siswa = avg_siswa / this.state.dataNilai.length
        console.log(data)
        var output = data.map((val,index) => {
            return(
                <View style={{display : "flex",flexDirection:'row',fontSize:10,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:1}}>
                    <View style={{flex:1,textAlign:'center', padding:10}}>
                        <Text>{index+1}</Text>
                    </View>
                    <View style={{flex:3,textAlign:'center', padding:10}}>
                        <Text>{val.pelajaran}</Text>
                    </View>
                    <View style={{flex:4,textAlign:'center', padding:10}}>
                        <Text>{val.panggilan + ' ' + val.ustadz}</Text>
                    </View>
                    <View style={{flex:2,textAlign:'center', padding:10}}>
                        <Text>{RoundEpsilon(val.rata_rata ? val.rata_rata : 0)}</Text>
                    </View>
                    <View style={{flex:2,textAlign:'center', padding:10}}>
                        <Text>{RoundEpsilon(val.nilai ? val.nilai : 0)}</Text>
                    </View>
                </View>
            )
        })

        output.push(
            <View style={{display : "flex",flexDirection:'row',fontSize:10,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:1}}>
                <View style={{flex:1,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:3,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:4,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:2,textAlign:'center', padding:10}}>
                    <Text>Rata Rata  </Text>
                </View>
                <View style={{flex:2,textAlign:'center', padding:10}}>
                    <Text>{RoundEpsilon(avg_siswa ? avg_siswa : 0)}</Text>
                </View>
            </View>
          
        )

        output.push(
            <View style={{display : "flex",flexDirection:'row',fontSize:10,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:1}}>
                <View style={{flex:1,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:3,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:4,textAlign:'center', padding:10}}>
                    <Text></Text>
                </View>
                <View style={{flex:2,textAlign:'center', padding:10}}>
                    <Text>Kenaikan Kelas  </Text>
                </View>
                <View style={{flex:2,textAlign:'center', padding:10}}>
                    <Text>{avg_siswa >= 70 ? "Naik Kelas" : "Tidak Naik"} </Text>
                </View>
            </View>
            
        )

        return output
    }

    render() {
        if(this.state.dataUser === null || this.state.dataKelasDanPelajaran === null || this.state.dataNilai === null || this.state.rata_rata === null){
            return(
                <LoadingPage />
            )

        }

        return (
            <PDFViewer style={{width : "100vw" , height:'100vh'}}>
                <Document title='Raport Santri.pdf'>
                    <Page size="A4" orientation="portrait" style={styles.page}>
                        <View style={styles.header}>
                            <View style={styles.title}>
                                <Text>Yayasann Ar-Raudhoh Sirau</Text>
                            </View>
                            <View style={styles.title}>
                                <Text>Pondok Pesantren Roudhotul Qur'an</Text>
                            </View>
                            <View style={styles.title}>
                                <Text>Sirau Kemranjen Banyumas</Text>
                            </View>
                            <View style={{marginTop:10}}>
                                <View style={styles.subtitle}>
                                    <Text>Alamat PPRQ Pusat : Sirau Po Box 02 Kemranjen Banyumas, Kode Pos : 53194, No Telp :  0851-1343-5516</Text>
                                </View>
                                <View style={styles.subtitle}>
                                    <Text>Alamat PPRQ 2 :  Karanggintung, Sumbang, Ciwarak, Banyumas, Kode Pos : 53183, No Telp :  0851-1343-5516</Text>
                                </View>
                            </View>
                        </View>
                        <View>

                            <View style={styles.documenTitle}>
                                <Text>
                                    Laporan Hasil Belajar Santri
                                </Text>
                            </View>

                            <View style={{justifyContent : "center",alignItems:'center'}}>
                                <Text style={{fontSize :14,marginTop : 10,textDecoration :"underline"}}>
                                    Data Santri
                                </Text>
                                <Image 
                                    style={{width:80,height:130,objectFit :"contain",objectPosition:"top",marginTop:5}}
                                    src={urlApi + this.state.dataUser.foto}
                                />
                                <Text style={{fontSize :10,marginTop : 5}}>
                                    Nama  = {this.state.dataUser.nama_lengkap}
                                </Text>
                                <Text style={{fontSize :10}}>
                                    NISN  = {this.state.dataUser.nisn}
                                </Text>
                                <Text style={{fontSize :10}}>
                                    {/* {
                                        console.log(this.state.dataKelasDanPelajaran)
                                    } */}
                                    Kelas  = {this.state.dataKelasDanPelajaran[0].kelas}
                                </Text>
                                

                            </View>
                        </View>
                        <View>
                            <View style={{display : "flex",flexDirection:'row',fontSize:10,marginTop:20,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:2}}>
                                <View style={{flex:1,textAlign:'center', padding:5}}>
                                    <Text>No</Text>
                                </View>
                                <View style={{flex:3,textAlign:'center', padding:5}}>
                                    <Text>Pelajaran</Text>
                                </View>
                                <View style={{flex:4,textAlign:'center', padding:5}}>
                                    <Text>Pengajar</Text>
                                </View>
                                <View style={{flex:2,textAlign:'center', padding:5}}>
                                    <Text>Rata Rata Kelas</Text>
                                </View>
                                <View style={{flex:2,textAlign:'center', padding:5}}>
                                    <Text>Nilai</Text>
                                </View>
                            </View>
                            {/* {
                                dataNilai.map((val,index) => {
                                    return(
                                        <View style={{display : "flex",flexDirection:'row',fontSize:10,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:1}}>
                                            <View style={{flex:1,textAlign:'center', padding:10}}>
                                                <Text>{index+1}</Text>
                                            </View>
                                            <View style={{flex:3,textAlign:'center', padding:10}}>
                                                <Text>{val.pelajaran}</Text>
                                            </View>
                                            <View style={{flex:4,textAlign:'center', padding:10}}>
                                                <Text>{val.pengajar}</Text>
                                            </View>
                                            <View style={{flex:2,textAlign:'center', padding:10}}>
                                                <Text>{val.rata_rata}</Text>
                                            </View>
                                            <View style={{flex:2,textAlign:'center', padding:10}}>
                                                <Text>{val.nilai}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                            <View style={{display : "flex",flexDirection:'row',fontSize:10,justifyContent:'space-between',borderBottomColor:'#dee2e6',borderBottomWidth:1}}>
                                <View style={{flex:1,textAlign:'center', padding:10}}>
                                    <Text></Text>
                                </View>
                                <View style={{flex:3,textAlign:'center', padding:10}}>
                                    <Text></Text>
                                </View>
                                <View style={{flex:4,textAlign:'center', padding:10}}>
                                    <Text></Text>
                                </View>
                                <View style={{flex:2,textAlign:'center', padding:10}}>
                                    <Text>Rata Rata : </Text>
                                </View>
                                <View style={{flex:2,textAlign:'center', padding:10}}>
                                    <Text>80</Text>
                                </View>
                            </View> */}
                            {
                                this.renderNilai()
                            }

                        </View>
                        <View style={{flexDirection :"row" , justifyContent : "flex-end"}}>
                            <View style={{width:'30vw', textAlign:'center',fontSize:10,marginTop:50}}>
                                <Text>
                                    Mengetahui,
                                </Text>
                                <Text style={{marginTop:70,borderTopWidth:1, borderTopColor : 'black',paddingTop:10}}>
                                    Khadimul Ma'had
                                </Text>
                                <Text>
                                    KH. Muhammad Anis Afiqi
                                </Text>
                            </View>
                        </View>
                       
                    </Page>
                </Document>

            </PDFViewer>
        )
    }
}


export default Raport
