import {MDBTooltip} from 'mdbreact'
import PropTypes from 'prop-types'
import React from 'react'

const TooltipText = (props) => {
  if(props.text.length >= props.length){
    return(
        <MDBTooltip
          domElement
          tag="span"
          placement="top"
        >
          <span> {props.text.slice(0,props.length-3)+'...'} </span>
          <span>{props.text}</span>
        </MDBTooltip>
    )
  }else{
    return(
      <span>{props.text}</span>
    )
  }
}

TooltipText.propTypes = {
  text : PropTypes.string.isRequired,
  length : PropTypes.number.isRequired
}


export default TooltipText