export const saveDataSantri = (nama_lengkap , nama_panggilan,jenis_kelamin,tanggal_lahir,anak_ke,jumlah_saudara,nisn,pendidikan_diikuti,foto_profile,instansi,nik) => {
    return{
        type : 'DATA_SANTRI',
        payload : {
            nama_lengkap,
            nama_panggilan,
            jenis_kelamin,
            tanggal_lahir,
            anak_ke,
            jumlah_saudara,
            nisn,
            pendidikan_diikuti,
            foto_profile,
            instansi,
            nik
        }
    }
}

export const saveDataAyah = (obj) => {
    return{
        type : 'DATA_AYAH',
        payload : obj
    }
}

export const saveDataIbu = (obj) => {
    return{
        type : 'DATA_IBU',
        payload : obj
    }
}

export const saveDataAlamat = (obj) => {
    return{
        type : 'DATA_ALAMAT',
        payload : obj
    }
}

export const resetData = () => {
    return{
        type : 'RESET'
    }
}