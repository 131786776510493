import React, { Component } from 'react'

import './../../support/css/admin.css'
import UnderMaintenence from '../../components/UnderMaintenence'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import MyLink from '../../components/Link'
import LoadingPage from '../../components/LoadingPage'
import { getHeaderAuth } from '../../support/functions/GetCookies'

// const data_dummies = [
//     {kamar : 'Asrama Putra',total_kamar : 20},
//     {kamar : 'Asrama Putri' , total_kamar : 20},
// ]

const siap = true

export default class ManageKamar extends Component {
    state = {
        data : null,
        unique : null
    }


    componentDidMount(){
        Axios.get(urlApi + 'admin/getallkamar',getHeaderAuth())
        .then((res) => {
            const unique = [...new Set(res.data.data.map(item => item.id))]
            this.setState({data:res.data.data , unique_id : unique})
            // this.setState({data : res.data.data})
            console.log(unique)
        })
    }
    render() {
        if(siap === false){
            return(
                <UnderMaintenence/>
            )
        }
        if(this.state.data === null){
            return(
                <LoadingPage/>
            )
        }
        return (
            <div className='container'>
                {/* <MyLink */}
                 <div className='row justify-content-between'>
                    <h1 style={{display:'inline'}} className='judul-form'> Manage Kamar</h1> 
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' disabled onClick={this.toggle_tambah_data}> Tambah Data </button> */}
                </div>
                <div className='row mt-5'>
                   {
                       this.state.unique_id.map((val)=> {
                            var length_kelas = this.state.data.filter((data) => {
                                return data.id === val
                            })
                            
                            return(
                            <div className='col-md-6 container-card-kelas'>
                                <div className='label-data font-18 ml-3 mt-3 text-capitalize'>
                                    {this.state.data.find(data => data.id === val).nama}
                                </div>
                                <div className='mx-3 mb-3 row justify-content-between'>
                                    <span>
                                        <span className='label-data'>Jumlah Kamar</span> 
                                        <span className='data-label'> {
                                        //    length_kelas/
                                        length_kelas.length
                                        }</span>
                                    </span>
                                    <MyLink to={'/admin/detail-kamar/' + val}>
                                        <button style={{fontSize:'10px'}} className='btn btn-success'> Lihat List Kamar </button>
                                    </MyLink>
                                </div>
                            </div>
                            )
                        })
                   }
                </div>
            </div>
        )
    }
}
