import React, { Component } from 'react'
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import LoadingPage from '../../components/LoadingPage'
import DataEmpty from '../../components/dataEmplty'
import Swal from 'sweetalert2'
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'

const rajaapi = 'https://x.rajaapi.com/MeP7c5ne'


class EditUser extends Component {
    state = {
        data : null,
        provinsi : null,
        kabupaten : null,
        kecamatan : null,
        loadingKabupaten : false,
        loadingKecamatan : false,
        token : null,
        getIdWilayah : true,
        finish : false
    }
    componentDidMount(){
        this.getDataUser()
    }

    getDataProv = (data_wilayah) => {
        Axios.get('https://x.rajaapi.com/poe')
        .then((res) => {

            if(res.data.success){
                this.setState({token : res.data.token})
                Axios.get(`https://x.rajaapi.com/MeP7c5ne${res.data.token}/m/wilayah/provinsi`)
                .then((resData) => {
                    console.log(resData.data.data)
                    var id_prop = resData.data.data.find((val) => {
                        return val.name === data_wilayah.propinsi
                    })
                    console.log(id_prop)
                    // console.log(propinsi)
                    this.getDataKab(id_prop.id,data_wilayah.kabupaten)
                    this.setState({provinsi : resData.data.data})
                })
                .catch((err) => {
                    Swal.fire(err.message)
                })
            }
        })
        .catch((err) => {
            Swal.fire('Pastikan Perangkat Anda Terhubung dengan Internet')
        })
    }

    getDataKab = (id_prop,kabupaten) => {
        Axios.get(`${rajaapi}${this.state.token}/m/wilayah/kabupaten?idpropinsi=${id_prop}`)
        .then((res) => {
            console.log(res.data.data)
            if(res.data.success){
                this.setState({kabupaten : res.data.data})
                var id_kab = res.data.data.find((val) => {
                    return val.name === kabupaten
                }).id
                this.getDataKec(id_kab)
            }
        })
        .catch((err) => {
            Swal.fire('Pastikan Internet Ada')
        })
    }

    getDataKec = (id_kab) => {
        Axios.get(`${rajaapi}${this.state.token}/m/wilayah/kecamatan?idkabupaten=${id_kab}`)
        .then((res) => {
            console.log(res.data.data)
            if(res.data.success){
                this.setState({kecamatan : res.data.data , loadingKecamatan : false});
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })

    }
    
    onChangeKabHandler = (event) => {
        this.setState({loadingKecamatan : true ,kecamatan : []})
        this.refs.kecamatan.value = 0
        Axios.get(`${rajaapi}${this.state.token}/m/wilayah/kecamatan?idkabupaten=${event.target.value}`)
        .then((res) => {
            if(res.data.success){
                this.setState({kecamatan : res.data.data , loadingKecamatan : false})
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })
    }

    getIdWilayah = (item,tosearch) => {

        return this.state[item].find((val) => {
            return val.name === tosearch
        }).id
    }

    getDataUser = () => {
        var id = this.props.match.params.id
        Axios.get(urlApi + "admin/getsantribyid/" + id + '?id_user=' + this.props.dataAdmin.id,getHeaderAuth())
        .then((res) => {
            if(res.data.data.length > 0){
                
                this.setState({data : res.data.data[0]})
                this.getDataProv({propinsi : res.data.data[0].propinsi, kabupaten : res.data.data[0].kabupaten})
            }
            else{
                this.setState({data : []})
            }
            console.log(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    renderOption = (state) => {
        var jsx =  state.map((val) => {
            return(
                <option key={val.id}  value={val.id} > {val.name} </option>
            )
        })
        return jsx
    }
    onChangeProvHandler = (event) => {
        console.log('trigered')
        this.setState({loadingKabupaten : true , kabupaten : [] , kecamatan : []})
        this.refs.kabupaten.value = 0
        Axios.get(`${rajaapi}${this.state.token}/m/wilayah/kabupaten?idpropinsi=${event.target.value}`)
        .then((res) => {
            console.log(res.data.data)
            if(res.data.success){
                this.setState({kabupaten : res.data.data , loadingKabupaten : false})
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })
    }

    onSaveBtnClick = () => {
        
        var user_detail_refs = ['nama_lengkap','tanggal_lahir','nisn','kelamin','anak_ke','jumlah_saudara','jenjang_pendidikan']
        var user_ayah_refs = ['nama_ayah','no_kk','no_ktp_ayah','pekerjaan_ayah','penghasilan_ayah','pendidikan_ayah','masih_hidup_ayah']
        var user_ibu_refs = ['nama_ibu','no_ktp_ibu','pekerjaan_ibu','penghasilan_ibu','pendidikan_ibu','masih_hidup_ibu']
        var user_kontak_refs = ['alamat','propinsi','kabupaten','kecamatan','kode_pos','no_ibu','no_ayah']
        var id_santri = this.props.match.params.id
        var id_user = this.props.dataAdmin.id
        var data = {}
        data.data_santri = {}
        data.data_ayah = {}
        data.data_ibu = {}
        data.data_kontak = {}

        for(var ref of user_detail_refs){
            if(this.refs[ref].value){
                data.data_santri[ref] = this.refs[ref].value
            }else{
                Swal.fire({text : 'All Form Must be Filled'});
            }
        }
        for(var ref_ayah of user_ayah_refs){
            if(this.refs[ref_ayah].value){
                if(ref_ayah === 'no_ktp_ayah' || ref_ayah === 'pendidikan_ayah' || ref_ayah==='masih_hidup_ayah'){
                    console.log(ref_ayah.replace('_ayah',''))
                    data.data_ayah[ref_ayah.replace('_ayah','')] = this.refs[ref_ayah].value
                }else{
                    data.data_ayah[ref_ayah] = this.refs[ref_ayah].value
                }
            }else{
                return Swal.fire('All Form Must be Filled')
            }
        }
        for(var ref_ibu of user_ibu_refs){
            if(this.refs[ref_ibu].value){
                if(ref_ibu === 'no_ktp_ibu' || ref_ibu === 'pendidikan_ibu' || ref_ibu==='masih_hidup_ibu'){
                    console.log(ref_ibu.replace('_ibu',''))
                    data.data_ibu[ref_ibu.replace('_ibu','')] = this.refs[ref_ibu].value
                }else{
                    data.data_ibu[ref_ibu] = this.refs[ref_ibu].value
                }
            }else{
                return Swal.fire('All Form Must be Filled')
            }
        }
        for(var ref_kontak of user_kontak_refs){
            if(this.refs[ref_kontak].value){
                if(ref_kontak === 'propinsi' || ref_kontak === 'kabupaten' || ref_kontak === 'kecamatan'){
                    var index = this.refs[ref_kontak].selectedIndex
                    data.data_kontak[ref_kontak] = ref_kontak === 'propinsi' ? this.state.provinsi[index-1].name : this.state[ref_kontak][index-1].name
                }else{
                    data.data_kontak[ref_kontak] = this.refs[ref_kontak].value
                }
            }else{
                return Swal.fire('All Form Must be Filled')
            }
        }

        Swal.fire({text : "Are You Sure Want To Update This Data??",icon:'question',showCancelButton:true,confirmButtonColor : "blue"})
        .then((val) => {
            if(val.value){
                Axios.post(urlApi + `admin/edituser?id_user=${id_user}&id_santri=${id_santri}`,data,getHeaderAuth())
                .then((res) => {
                    if(!res.data.error){
                        Swal.fire(res.data.message)
                        this.setState({finish: true})
                    }
                })
                console.log(data)
            }
        })
        
    }
    render() {
        if(this.state.finish){
            if(Number(this.props.dataAdmin.role) === 1){
                return(
                    <Redirect to={'/santri/detail/' + this.props.match.params.id} />
                )
            }
            return(
                <Redirect to={'/user-detail/' + this.props.match.params.id} />
            )
        }
        if(this.state.data === null || this.state.provinsi === null || this.state.kabupaten === null || this.state.kecamatan === null || this.props.dataAdmin.username === '') {
            return(
                <LoadingPage />
            )
        }
        if(this.state.data.length === 0){
            return(
                <div className='my-5 py-5'>
                    <DataEmpty title = 'Data Not Found' />
                </div>
            )
        }
        let {nama_lengkap,kelamin,tanggal_lahir,jenjang_pendidikan,anak_ke,jumlah_saudara,nisn} = this.state.data
        let {nama_ayah, no_kk,no_ktp_ayah,pekerjaan_ayah,penghasilan_ayah,pend_ayah,hidup_ayah} = this.state.data
        let {nama_ibu,no_ktp_ibu,pekerjaan_ibu,penghasilan_ibu,pend_ibu,hidup_ibu} = this.state.data
        return (
            <div className='container my-5 py-5'>
                <div className="row justify-content-between mb-3 align-items-center">
                <h4>Edit Profile</h4>
                    <input onClick={this.onSaveBtnClick} type="button" value="Save" style={{fontSize:'12px',padding:'5px'}} className='btn btn-outline-success ml-2'/>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <MDBCard>
                            <MDBCardHeader>
                                Data Santri
                            </MDBCardHeader>
                            <MDBCardBody>
                                <input defaultValue={nama_lengkap} ref='nama_lengkap' type="text" className='form-control' />
                                <div className='mt-3'>
                                    <span>Nisn</span>
                                    <input defaultValue={nisn} maxLength={10} type="text" ref='nisn' className='form-control' />
                                </div>
                                <select ref='kelamin' className='form-control mt-3' defaultValue={kelamin}>
                                    <option value="Pria">Pria</option>
                                    <option value="Wanita">Wanita</option>
                                </select>
                                {/* {console.log(tanggal_lahir)} */}
                                <input ref='tanggal_lahir' type="date" defaultValue={tanggal_lahir.split(' ')[0]} className='form-control mt-3' />
                                <select ref='jenjang_pendidikan' className='form-control mt-3' defaultValue={jenjang_pendidikan}>
                                    <option value="SD/MI">SD/MI</option>
                                    <option value="SMP/MTS">SMP/MTS</option>
                                    <option value="SMA/SMK">SMA/SMK</option>
                                </select>
                                <div className='mt-3'>
                                    <span>Anak Ke -</span>
                                    <input ref='anak_ke' defaultValue={anak_ke} type="number" className='form-control' />
                                </div>
                                <div className='mt-3'>
                                    <span>Jumlah Saudara</span>
                                    <input ref='jumlah_saudara' defaultValue={jumlah_saudara} type="number" className='form-control' />
                                </div>
                                
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="col-md-3">
                        <MDBCard>
                            <MDBCardHeader>
                                Data Ayah
                            </MDBCardHeader>
                            <MDBCardBody>
                                <input ref='nama_ayah' defaultValue={nama_ayah} type="text" className='form-control' />
                                <div className='mt-3'>
                                    <span>No KK</span>
                                    <input maxLength={16} ref='no_kk' defaultValue={no_kk} type="text" className='form-control' />
                                </div>
                                <div className='mt-3'>
                                    <span>No KTP Ayah</span>
                                    <input maxLength={16} ref='no_ktp_ayah' defaultValue={no_ktp_ayah} type="text" className='form-control' />
                                </div>
                                <select ref='pekerjaan_ayah' className='form-control mt-3' defaultValue={pekerjaan_ayah}>
                                        <option>PNS</option>
                                        <option>Guru</option>
                                        <option>Buruh</option>
                                        <option>Swasta</option>
                                        <option>Petani</option>
                                        <option>Lainnya</option>
                                </select>
                                <select ref='penghasilan_ayah' className='form-control mt-3' defaultValue={penghasilan_ayah}>
                                        <option> Dibawah 500.000</option>
                                        <option> 500.000 - 1.000.000</option>
                                        <option> 1.000.000 - 2.900.000</option>
                                        <option> 3.000.000 - 4.900.000</option>
                                        <option> 5.000.000 - 10.000.000</option>
                                        <option> Di atas 10.000.000</option>
                                </select>
                                <select ref='pendidikan_ayah' className='form-control mt-3' defaultValue={pend_ayah}>
                                        <option>SD</option>
                                        <option>SMP</option>
                                        <option>SMA</option>
                                        <option>S1</option>
                                        <option>S2</option>
                                        <option>S3</option>
                                </select>
                                <select ref='masih_hidup_ayah' className='form-control mt-3' defaultValue={hidup_ayah ? 1 : 0}>
                                        <option value={1}>Masih Hidup</option>
                                        <option value={0}>Sudah Meninggal</option>
                                </select>
                                
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="col-md-3">
                        <MDBCard>
                            <MDBCardHeader>
                                Data Ibu
                            </MDBCardHeader>
                            <MDBCardBody>
                                <input ref='nama_ibu' defaultValue={nama_ibu} type="text" className='form-control' />
                                
                                <div className='mt-3'>
                                    <span>No KTP Ibu</span>
                                    <input maxLength={16} ref='no_ktp_ibu' defaultValue={no_ktp_ibu} type="text" className='form-control' />
                                </div>
                                <select ref='pekerjaan_ibu' className='form-control mt-3' defaultValue={pekerjaan_ibu}>
                                        <option>PNS</option>
                                        <option>Guru</option>
                                        <option>Buruh</option>
                                        <option>Swasta</option>
                                        <option>Petani</option>
                                        <option>Lainnya</option>
                                </select>
                                <select ref='penghasilan_ibu' className='form-control mt-3' defaultValue={penghasilan_ibu}>
                                        <option> Dibawah 500.000</option>
                                        <option> 500.000 - 1.000.000</option>
                                        <option> 1.000.000 - 2.900.000</option>
                                        <option> 3.000.000 - 4.900.000</option>
                                        <option> 5.000.000 - 10.000.000</option>
                                        <option> Di atas 10.000.000</option>
                                </select>
                                <select ref='pendidikan_ibu' className='form-control mt-3' defaultValue={pend_ibu}>
                                        <option>SD</option>
                                        <option>SMP</option>
                                        <option>SMA</option>
                                        <option>S1</option>
                                        <option>S2</option>
                                        <option>S3</option>
                                </select>

                                <select ref='masih_hidup_ibu' className='form-control mt-3' defaultValue={hidup_ibu ? 1 : 0}>
                                        <option value={1}>Masih Hidup</option>
                                        <option value={0}>Sudah Meninggal</option>
                                </select>
                            
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="col-md-3">
                        <MDBCard>
                            <MDBCardHeader>
                                Data Kontak
                            </MDBCardHeader>
                            <MDBCardBody>
                                <select ref='propinsi' defaultValue={this.state.getIdWilayah ? this.getIdWilayah('provinsi',this.state.data.propinsi) : null} onChange={this.onChangeProvHandler} className='form-control' >
                                    <option value={0} disabled> {this.state.provinsi.length > 0 ? 'Pilih Provinsi' : 'loading ...'} </option>
                                    {this.renderOption(this.state.provinsi)}
                                </select>
                                <select ref='kabupaten' onChange={this.onChangeKabHandler} defaultValue={this.state.getIdWilayah ? this.getIdWilayah('kabupaten',this.state.data.kabupaten) : null}  className='form-control mt-3' >
                                    <option value={0} disabled>{this.state.loadingKabupaten ? 'loading ...' : 'Pilih Kabupaten'}</option>
                                    {this.renderOption(this.state.kabupaten)}
                                </select>
                                <select ref='kecamatan'  defaultValue={this.state.getIdWilayah ? this.getIdWilayah('kecamatan',this.state.data.kecamatan) : null} className='form-control mt-3' >
                                    <option value={0}>{this.state.loadingKecamatan ? 'loading...' : 'Pilih Kecamatan'}</option>
                                    {this.renderOption(this.state.kecamatan)}
                                </select>
                                <div className='mt-3'>
                                    <span>Alamat</span>
                                    <input ref='alamat' defaultValue={this.state.data.alamat} type="text" className='form-control' />
                                </div>
                                <div className='mt-3'>
                                    <span>Kode Pos</span>
                                    <input maxLength={5} ref='kode_pos' defaultValue={this.state.data.kode_pos} type="text" className='form-control' />
                                </div>
                                <div className='mt-3'>
                                    <span>Nomor Ayah</span>
                                    <input maxLength={13} ref='no_ayah' defaultValue={this.state.data.no_ayah} type="text" className='form-control' />
                                </div>
                                <div className='mt-3'>
                                    <span>Nomor Ibu</span>
                                    <input maxLength={13} ref='no_ibu' defaultValue={this.state.data.no_ibu} type="text" className='form-control' />
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                        {this.state.getIdWilayah ? this.setState({getIdWilayah : false}) : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}


export default connect(mapStateToProps)(EditUser)
