import React, { Component } from 'react'
import School from './../../support/assets/images/donation/school.png'
import Money from './../../support/assets/images/donation/money-bag.png'
import Cement from './../../support/assets/images/donation/cement.png'

import './donation.css'
import Gallery from './galery'
import {formatRupiah} from './../../support/functions/index'

import {Link} from 'react-router-dom'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import moment from 'moment'
import Swal from 'sweetalert2'
import Fade from 'react-reveal/Fade'

// class DonasiUang {
//     constructor(name,jumlah,message){
//         this.name = name
//         this.jumlah = jumlah
//         this.message = message
//     }
// }

// class DonasiMaterial {
//     constructor(name,bentukDonasi, sebanyak,message){
//         this.name = name
//         this.bentukDonasi = bentukDonasi
//         this.sebanyak = sebanyak
//         this.message = message
//     }
// }

// const dataDonasiUang = [
//     new DonasiUang('Fikri',10000000, 'Salam buat santri putri semangat ngajinya yaa !!'),
//     new DonasiUang('Adam',200000, 'Sukses teruss buat pprq !!'),
//     new DonasiUang('Hawa',5000, 'Pprq semakin di depan !!'),
//     new DonasiUang('Budi',120000, 'Akang menunggumu dek!!'),
//     new DonasiUang('Setyo',1000000, 'Semoga Barokan !!'),
//     new DonasiUang('Karep',1200000, 'Kangen PPRQ !!'),
//     new DonasiUang('Sadem',8000000, 'Kangen Santriwati !!'),
// ]

// const dataDonasiMaterial = [
//     new DonasiMaterial('Fikri','Semen','1 Sak','Semoga Barokah !!'),
//     new DonasiMaterial('Doni','Tanah Urug','10 Truck','Semoga anak saya berhasil di situ !!'),
// ]

export class Donation extends Component {
    state = {
        donasiUang : {
            status : 'unload',
            data : null
        },
        donasiMaterial : {
            status : 'unload',
            data : null
        },
        limitUang : 5,
        limitMaterial : 5
    }

    componentDidMount(){
       this.getDataDonasiUang(this.state.limitUang)
       this.getDataDonasiMaterial(this.state.limitMaterial)
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.limitMaterial !== this.state.limitMaterial){
            this.getDataDonasiMaterial(this.state.limitMaterial)
        }
        if(prevState.limitUang !== this.state.limitUang){
            this.getDataDonasiUang(this.state.limitUang)
        }
    }
   

    getDataDonasiMaterial = (limit) => {
        this.setState({
            donasiMaterial : {
                status : 'loading',
                data : null
            }
        })
        Axios.get(urlApi + 'donation/donasi-material-success?limit=' + limit)
        .then((res) => {
            if(!res.data.error){
                this.setState({
                donasiMaterial : {
                    status : 'loaded',
                    data : {
                        totalDonatur : res.data.qty,
                        dataDonatur : res.data.data
                    }
                }
            })
        
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getDataDonasiUang = (limit) => {
        this.setState({
            donasiUang : {
                status : 'loading',
                data : null
            }
        })

        Axios.get(urlApi + 'donation/donasi-uang-success?limit=' + limit)
        .then((res) => {
            if(!res.data.error){
                this.setState({
                donasiUang : {
                    status : 'loaded',
                    data : {
                        totalDonatur : res.data.qty,
                        totalDonation : res.data.total,
                        dataDonatur : res.data.data
                    }
                }
            })
        
            }
        })
        .catch((err) => {
            console.log(err)
        })

    }

    checkStatusDonasiClick = () => {
        Swal.fire({
            title : "Cek Status Donasi",
            input : "radio",
            inputOptions : {
                'order-uang' : "Donasi Uang",
                'order-material' :  "Donasi Material"
            },
            showCancelButton : true,
            confirmButtonText : "next"
        })
        .then((link) => {
            if(link.value){
                Swal.fire({
                    input : "number",
                    inputPlaceholder :"ID Transaksimu!!",
                    showCancelButton : true,
                    confirmButtonText : "Check"
                })
                .then((id_trx) => {
                    if(id_trx.value){
                        window.location = '/alumni-mbangun-pprq/' + link.value + '/' + id_trx.value
                    }
                })
            }
        })
    }

    render() {
        return (
            <div>
                <div className='py-5 section-one-donation'>
                    <div className="p-5 section-one-donation">
                        <div className="container">
                            <Fade top>
                            <p className='color-white text-center'>Bismillahirrahmanirrahim</p>
                            </Fade>
                            <Fade top>

                            <div className='row justify-content-center my-4'>
                                <div className='image-wrapper p-3'>
                                    <img src={School} width='100%' alt=""/>
                                </div>
                            </div>
                            </Fade>

                            <Fade>

                            <div className="row justify-content-center text-center mt-3">
                                <div className='col-md-6 header-one-donation color-white text-center'>ALUMNI 
                                    <span className='font-weight-bold yellow-color'> MBANGUN PPRQ </span>
                                </div>
                            </div>
                            <div className='row justify-content-center mt-3'>
                                <p className='color-white text-center col-md-4'>
                                    PPRQ mengundang seluruh alumni se-Indonesia untuk bersama sama membangun PPRQ 3 <span className='font-weight-bold yellow-color'>#AlumniMbangunPPRQ</span>
                                </p>

                            
                            </div>
                            </Fade>
                            <Fade bottom>

                            <div className='row justify-content-center mt-3'>
                                <span onClick={
                                    () => {
                                        this.donationEl.scrollIntoView({behavior : "smooth"})
                                    }
                                } className='btn-circle-donation px-5 py-2'>
                                    Kirim Donasi Sekarang !!
                                </span>

                            </div>
                            <div className="row justify-content-center text-center mt-3">
                                <div className='col-12 header-three-donation color-white text-center'>Dana yang Telah Terkumpul :
                                </div>
                                <div className='col-12 header-two-donation font-weight-bold color-white text-center'>{this.state.donasiUang.status !== 'loaded' ? 'loading ...' : formatRupiah(this.state.donasiUang.data.totalDonation,'Rp')}
                                </div>
                            </div>
                            <span onClick={this.checkStatusDonasiClick} style={{textDecoration : "underline",fontStyle:'italic',cursor : "pointer"}} className='row justify-content-center text-center font-weight-bold mt-3'>
                                Cek Status Donasimu disini
                            </span>
                            </Fade>

                        </div>
                        
                    </div>
                </div>

                <div className='section-two-donation py-5'>
                    <div className="text-center font-weight-bold blue-color header-one-donation">
                        SEPUTAR PPRQ 3
                    </div>
                    <div className="container mt-3">
                        <Fade bottom>

                        <div className="row justify-content-center">
                            <div className="col-md-12 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage p-3'>
                                    <h3 className=' text-center title-card-homepage mb-3'>
                                        FOTO LAHAN
                                    </h3>
                                    <div>
                                        <Gallery />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Fade>
                         <Fade>
                        <div className="row mt-4">
                            <div className="col-md-6 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <h3 className='title-card-homepage mt-3'>
                                        INFO LAHAN
                                    </h3>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Luas Lahan :
                                        </div>

                                        127 x 14m = 1778 m persegi
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Lokasi :
                                        </div>

                                        Jl. Sirau - Pacarmalang
                                        Area Sawah, Sirau
                                        Kec. Kemranjen
                                        Kabupaten Banyumas
                                        Jawa Tengah 53194
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Google Map Location :
                                        </div>
                                        <a href="https://goo.gl/maps/6yYxHMoy9XSPqpyz6" rel="noopener noreferrer" target='_blank'>
                                            Klik Disini
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <h3 className='title-card-homepage mt-3'>
                                        INFO PEMBANGUNAN
                                    </h3>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Tujuan Pembangunan :
                                        </div>
                                        Mengatasi Jumlah Santri dan Santriwati PPRQ 1 yang membludak
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Perkiraan Anggaran Untuk Pengurugan :
                                        </div>
                                        Rp. 200.000.000 
                                    </p>
                                    
                                </div>
                            </div>
                            
                        </div>
                        </Fade>   

                       
                    </div>
                </div>

                <div ref={(el) => { this.donationEl = el; }} className="section-three-donation py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <Fade top>
                            <div ref={(el) => { this.donationEl = el; }} className="color-white text-center font-weight-bold header-one-donation">
                                YUKK DONASI !!
                            </div>
                            </Fade>
                        </div>
                        <Fade bottom>
                        <div className="container mb-5">
                            <div className="row mb-3">
                                <div className="col-md-6 mt-3 mt-md-0 px-4">
                                    <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    
                                        <div className="row align-items-center">
                                            <div className="col-md-4 p-3 text-center">
                                                <img src={Money} className='img-logo-donation' width='100%' alt=""/>
                                            </div>
                                            <div className="col-md-8 text-center text-md-left">
                                                    <h3 className='title-card-homepage mt-3'>
                                                        DONASI UANG
                                                    </h3>
                                                
                                                <p className='desc-card-homepage mt-3'>
                                                    Donasikan uang berapapun lewat media pembayaran yang beragam mulai dari Transfer Bank, Saldo Gopay dan Saldo Ovo
                                                </p>
                                                <Link to='/alumni-mbangun-pprq/donasi-uang' style={{display:'inlineBlock', textDecoration:'none',color:'inherit'}}>

                                                <span style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                                    Klik Disini !!
                                                </span>
                                                </Link>
                                            </div>
                                        </div>                       
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-0 px-4">
                                    <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    
                                        <div className="row align-items-center">
                                            <div className="col-md-4 p-3 text-center">
                                                <img className='img-logo-donation' src={Cement} width='100%' alt=""/>
                                            </div>
                                            <div className="col-md-8 text-center text-md-left" >
                                                <h3 className='title-card-homepage mt-3'>
                                                    MATERIAL BANGUNAN
                                                </h3>
                                                <p className='desc-card-homepage mt-3'>
                                                    Material apapun akan kami terima, hanya isi formulir online, tunggu konfirmasi dari pengurus, Material akan kami Jemput !!
                                                </p>
                                                <Link to='/alumni-mbangun-pprq/donasi-material' style={{display:'inlineBlock', textDecoration:'none',color:'inherit'}}>

                                                <span style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                                    Klik Disini !!
                                                </span>
                                                </Link>
                                            </div>
                                        </div>                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>

                <Fade>
                <div className="section-four-donation py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="blue-color text-center font-weight-bold header-one-donation">
                                ALUMNI YANG TELAH BERDONASI
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <h3 className='title-card-homepage mt-3'>
                                        UANG  ({this.state.donasiUang.status !== 'loaded' ? 'loading ...' : this.state.donasiUang.data.totalDonatur})
                                    </h3>
                                    {
                                        this.state.donasiUang.status !== 'loaded' ? 'loading..' :
                                        this.state.donasiUang.data.dataDonatur.map((val)=> {
                                        
                                            return(
                                                <p className='desc-card-homepage mt-3'>
                                                    <div className="font-weight-bold">
                                                        {val.is_anonim ? 'Hamba Alloh' : val.nama_donatur} ({formatRupiah(val.nominal,'Rp')})   <div style={{fontSize:'12px'}} className='font-weight-normal'>{moment(val.created_at).startOf('minutes').fromNow()}</div>
                                                    </div>
                                                    {val.pesan ?  val.pesan : null}
                                                </p>
                                            )
                                        })
                                    }

                                    {
                                        this.state.donasiUang.status === 'loaded' && this.state.donasiUang.data.totalDonatur > this.state.limitUang ?
                                        <span onClick={() => this.setState({limitUang : this.state.limitUang + 10})} className='blue-color font-weight-bold' style={{textDecoration : "underline",cursor : "pointer"}}>
                                             See More
                                        </span>
                                        :
                                        null
                                    }
                                  
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <h3 className='title-card-homepage mt-3'>
                                        MATERIAL BANGUNAN ({this.state.donasiMaterial.status !== 'loaded' ? 'loading ...' : this.state.donasiMaterial.data.totalDonatur})
                                    </h3>
                                    {
                                        this.state.donasiMaterial.status !== 'loaded' ? 'loading..' :
                                        this.state.donasiMaterial.data.dataDonatur.map((val)=> {
                                            return(
                                                <p className='desc-card-homepage mt-3'>
                                                    <div className="font-weight-bold">
                                                        {val.is_anonim ? "Hamba Alloh" : val.nama_donatur} ({val.jenis_barang + ' sebanyak ' + val.kuantitas_barang}) <div style={{fontSize:'12px'}} className='font-weight-normal'>{moment(val.created_at).startOf('minutes').fromNow()}</div>
                                                    </div>
                                                    {val.pesan}
                                                </p>
                                            )
                                        })
                                    }
                                    {
                                        this.state.donasiMaterial.status === 'loaded' && this.state.donasiMaterial.data.totalDonatur > this.state.limitMaterial ?
                                        <span onClick={this.setState({limitUang : this.state.limitMaterial + 10})} className='blue-color font-weight-bold' style={{textDecoration : "underline",cursor : "pointer"}}>
                                                See More
                                        </span>
                                        :
                                        null
                                    }
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Fade>
            </div>
        )
    }
}



export default Donation