import React from 'react';
import {  Modal, ModalHeader, ModalBody,ModalFooter,Alert } from 'reactstrap';
import Spinner from 'react-loader-spinner'
import { connect  } from 'react-redux'
import Axios from 'axios';
import { urlApi } from '../support/constant';
import {Redirect} from 'react-router-dom';


class ModalExample extends React.Component {

    state= {
        modal : false,
        loading : false ,
        error : '',
        redirect : false
    }

    componentWillReceiveProps(newProps){
        if(newProps.openModal){
            this.setState({modal : true})
        }
    }
    toggle = () => {
        this.setState({modal : false , error:''})
        this.props.onClose()
    }
    onBtnConfirm = () => {
      this.setState({loading : true})
      console.log(this.props.data.form)
      var {dataSantri , dataAyah , dataIbu,dataAlamat} = this.props.data.form
      var fd = new FormData()
      var headers = {
        headers: 
        {'Content-Type': 'multipart/form-data'}
      }
      // console.log(this.props.data.form)

      var santri = {
        nama_lengkap : dataSantri.nama_lengkap,
        nama_panggilan : dataSantri.nama_panggilan,
        kelamin : dataSantri.jenis_kelamin,
        tanggal_lahir : dataSantri.tanggal_lahir,
        anak_ke : dataSantri.anak_ke,
        nik : dataSantri.nik,
        instansi : dataSantri.instansi,
        jumlah_saudara : dataSantri.jumlah_saudara,
        jenjang_pendidikan : dataSantri.pendidikan_diikuti,
        nisn : dataSantri.nisn,
        is_verified : this.props.dataAdmin.username ? 1 : 0
      }

      

      var ayah ={
        nama_ayah : dataAyah.nama,
        no_kk : dataAyah.kk,
        no_ktp : dataAyah.ktp,
        pekerjaan_ayah : dataAyah.pekerjaan,
        penghasilan_ayah : dataAyah.penghasilan,
        pendidikan : dataAyah.pendidikan,
        masih_hidup : dataAyah.hidup === 'Masih Hidup' ? true : false
      }

      

      var ibu = {
        nama_ibu : dataIbu.nama,
        no_ktp : dataIbu.ktp,
        pekerjaan_ibu : dataIbu.pekerjaan,
        penghasilan_ibu : dataIbu.penghasilan,
        pendidikan : dataIbu.pendidikan,
        masih_hidup : dataIbu.hidup === 'Masih Hidup' ? true : false
      }

      var kontak = {
        alamat : dataAlamat.alamat,
        propinsi : dataAlamat.prov,
        kabupaten : dataAlamat.kab,
        kecamatan : dataAlamat.kec,
        kode_pos : dataAlamat.pos,
        no_ayah : dataAlamat.ayah,
        no_ibu : dataAlamat.ibu
      }

      

      console.log(santri)
      console.log(ayah)
      console.log(ibu)
      console.log(kontak)

      
      fd.append('santri' ,  this.props.data.form.dataSantri.foto_profile )
      fd.append('ktp' ,  this.props.data.form.dataAyah.scan_ktp )
      fd.append('kk' ,  this.props.data.form.dataAyah.scan_kk )
      fd.append('dataSantri' ,  JSON.stringify(santri) )
      fd.append('dataAyah' ,  JSON.stringify(ayah) )
      fd.append('dataIbu' ,  JSON.stringify(ibu) )
      fd.append('kontak' ,  JSON.stringify(kontak))

      Axios.post(urlApi + 'register/addsantri?nama=' + santri.nama_lengkap  , fd , headers)
      .then((res) => {
        if(res.data.error){
          this.setState({loading : false , error : res.data.msg.split(':')[1]})
        }else{
          this.props.prompt()
          this.setState({loading : false , redirect : true})
        }
      })
      .catch((err) => {
        this.setState({loading : false})
        console.log(err)
      })
      

      }

  render() {
    if(this.state.redirect){
      var unikue = parseInt(this.props.data.form.dataSantri.nisn)
      unikue = unikue*2

      return(
        <Redirect to={'/register-success?n=' + unikue} />
      )
    }
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;

      return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader style={{border : 'none'}} toggle={this.toggle} close={closeBtn}></ModalHeader>
          <ModalBody>
              <div className='d-flex flex-column align-items-center'>
                  <div className='garis-bawah-pendek green'></div>
                    <h1 className='mt-4'>{this.props.title}</h1>
                    <p className='mt-1'>{this.props.content}</p>

              </div>
          </ModalBody>
          <ModalFooter style={{border:'none' ,display:'flex' , flexDirection:'row' , justifyContent:'center'}} className='pb-5'>
            {
              this.state.loading 
              ? 
              <Spinner 
                type="Oval"
                color="#006b53"
                height="18"	
                width="18"
              />   
              :
              this.state.loading === false && this.state.error !== ''
              ?
              <Alert color='danger'>
                {this.state.error}
              </Alert>
              :
              <input type='button' className='mybtn-setengah-gold green' value='Confirm' onClick={this.onBtnConfirm} />
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    data : state,
    dataAdmin : state.admin.dataAdmin
  }
}

export default connect(mapStateToProps)(ModalExample);