import React, { Component } from 'react'
import {  Form, FormGroup, Input,Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFemale } from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade'
import { saveDataIbu } from './../../redux/action'
import { connect } from 'react-redux'
import Modal from './../../components/Modal'

class StepThree extends Component {  
    state = {
        modalOpen : false,
        error : '',
        ktp : null
    }
    handleOnChange = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({ktp : filter})
        }
        // alert(e.target.value)
    }

    onBtnNextClick = () => {
        var ref = 'nama ktp pekerjaan penghasilan pendidikan hidup'.split(' ')
        var check = true
        var dataIbu = {}
        ref.forEach((val) => {
            if(this.refs[val].refs[val + 'Inner'].value === ''){
                this.setState({modalOpen : true , error : 'Semua Form Harus Diisi'})
                check = false
            }else{
                dataIbu[val] = this.refs[val].refs[val + 'Inner'].value
            }
        })
        if(check){
            console.log(dataIbu)
            this.props.saveDataIbu(dataIbu)
            this.props.next()
        }
       
    }
    render() {
        return (
            <Fade big>
            <div className='d-flex justify-content-center'>      
            <Modal 
                onClose={() => this.setState({modalOpen : false})} 
                title = 'Error' content ={this.state.error} 
                openModal ={this.state.modalOpen}/>      
                <div className='col-md-6'>
                    <div className='d-flex justify-content-between p-4 top-info'>
                        <div>
                            <h3>Form 3 dari 4</h3>
                            <div> Data Ibu </div>
                        </div>
                        <div className='icon-user'>
                            <FontAwesomeIcon icon={faFemale} size='4x'/>
                        </div>
                    </div>  
                    <div className='cont-small p-4'>
                        <Form>
                            <FormGroup>
                                <Input defaultValue={this.props.dataIbu ? this.props.dataIbu.nama : ''} ref='nama' innerRef='namaInner' type="text" placeholder='Nama Ibu'/>
                            </FormGroup>
                            <FormGroup>
                                <Input type="number" value={this.state.ktp} onChange={this.handleOnChange} maxLength={16} defaultValue={this.props.dataIbu ? this.props.dataIbu.ktp : ''} ref='ktp' innerRef='ktpInner' placeholder='No KTP'/>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input defaultValue={this.props.dataIbu ? this.props.dataIbu.pekerjaan : ''} ref='pekerjaan' innerRef='pekerjaanInner' type='select' >
                                        <option value=''>Pekerjaan Ibu</option>
                                        <option>PNS</option>
                                        <option>Guru</option>
                                        <option>Buruh</option>
                                        <option>Swasta</option>
                                        <option>Petani</option>
                                        <option>Lainnya</option>
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input defaultValue={this.props.dataIbu ? this.props.dataIbu.penghasilan : ''} ref='penghasilan' innerRef='penghasilanInner' type='select' >
                                        <option value=''>Penghasilan Ibu</option>
                                        <option> Dibawah 500.000</option>
                                        <option> 500.000 - 1.000.000</option>
                                        <option> 1.000.000 - 2.900.000</option>
                                        <option> 3.000.000 - 4.900.000</option>
                                        <option> 5.000.000 - 10.000.000</option>
                                        <option> Di atas 10.000.000</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input defaultValue={this.props.dataIbu ? this.props.dataIbu.pendidikan : ''} ref='pendidikan' innerRef='pendidikanInner' type='select' >
                                        <option value=''>Pendidikan Terakhir</option>
                                        <option>SD</option>
                                        <option>SMP</option>
                                        <option>SMA</option>
                                        <option>S1</option>
                                        <option>S2</option>
                                        <option>S3</option>
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input defaultValue={this.props.dataIbu ? this.props.dataIbu.hidup : ''} ref='hidup' innerRef='hidupInner' type='select' >
                                        <option>Masih Hidup</option>
                                        <option>Sudah Meninggal</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col xs="6" sm={4} md={4}>
                                    <Input type='button' className='btn btn-danger' onClick={this.props.back} value='Kembali' />
                                </Col>
                                <Col xs="6" sm={'4 offset-md-4 offset-sm-4'} md={'4 offset-md-4 offset-sm-4'}>
                                    <Input type='button' className='btn btn-success' onClick={this.onBtnNextClick} value='Lanjut' />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
            </Fade>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        dataIbu : state.form.dataIbu
    }
}
export default connect (mapStateToProps, {saveDataIbu})(StepThree)