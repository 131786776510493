import React, { Component } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import {Link} from 'react-router-dom'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import LoadingPage from '../../components/LoadingPage'
import { RoundEpsilon } from '../../support/functions/RoundEpsilon'
import DataEmpty from '../../components/dataEmplty'

export class LihatRaportDetail extends Component {
    state = {
        data : null,
        dataKelas : null,
        dataKosong : false
    }
    componentDidMount(){
        var id = this.props.match.params.id
        this.getDataUsers(id)
    }

    getDataUsers = (id) => {
        Axios.get(urlApi + 'raport/getsantribykelasraport/' + id,getHeaderAuth())
        .then((res) => {
            console.log(res.data.data)
            if(res.data.data.length === 0) return this.setState({dataKosong : true})
            console.log(res.data.nilai)
            let dataKelas = res.data.data[0].nama
            let dataUser = res.data.data
            let dataNilai = res.data.nilai

            let dataToRender = []

            /**
             * id: 8
id_user: 102
nama_lengkap: "Wisnu Nugroho"
nama_panggilan: "Wisnu"
tanggal_lahir: "2002-07-01 17:00:00"
kelamin: "Pria"
anak_ke: 1
jumlah_saudara: "1"
nisn: "201906005"
foto: "public/registrasi/images/REG-1574862572887.jpg"
jenjang_pendidikan: "SMA/SMK"
id_kamar: 48
id_madin: 8
is_verified: 1
nama: "Takhosus"
id_pondok: 1
created_at: "2020-01-13T04:44:12.000Z"

id: 426
nilai: 84.4444
nama_lengkap: "Iyad Faiq Robbani"
nama: "Awaliyah"
             */

            dataUser.forEach((val) => {
                let obj = {}
                obj.nama_lengkap = val.nama_lengkap
                obj.id = val.id_user
                var nilai = dataNilai.filter((nil) => {
                    return nil.id === val.id_user
                })

                nilai = nilai.length > 0 ? nilai[0].nilai : 0
                obj.nilai = nilai
                dataToRender.push(obj)
            })

            // dataNilai.forEach((val) => {
            //     let obj = val
            //     let nama_lengkap = obj.nama_lengkap = dataUser.filter((usr) => {
            //         return usr.id_user === val.id
            //     })
            //     obj.nama_lengkap = nama_lengkap[0].nama_lengkap
            //     obj.nama = nama_lengkap[0].nama
            //     dataToRender.push(obj)
            // })

            console.log(dataToRender)
            dataToRender.sort((a, b) => b.nilai -  a.nilai );
            this.setState({data : dataToRender,dataKelas})
        })
        .catch((err) => {
            console.log(err)
        })
    }
    renderDataToJsx = () => {
        return this.state.data.map((val) => {
            return(
                <tr>
                    <td>{val.id}</td>
                    <td>
                        <Link to={'/user-detail/' + val.id}>
                            <span style={{textDecoration:'underline',fontStyle:'italic',cursor:'pointer'}}> {val.nama_lengkap} </span>
                        </Link>
                    </td>
                    <td>{RoundEpsilon(val.nilai)}</td>
                    <td>
                        <Link to={'/raport-santri/' + val.id}>
                            <span style={{textDecoration:'underline',fontStyle:'italic',cursor:'pointer'}}> Lihat Detail </span>
                        </Link>
                    </td>
                </tr>
            )
        })
    }
    render() {
        if(this.state.dataKosong){
            return(
                <DataEmpty title = 'Data Not Found' />
            )
        }
        if(this.state.data === null || this.state.dataKelas === null){
            return(
                <LoadingPage />
            )
        }
        
        return (
            <div>
                <h1>Kelas {this.state.dataKelas}</h1>
                <table className='table'> 
                    <thead>
                        <th>Id Santri</th>
                        <th>Nama Lengkap</th>
                        <th>Rata Rata</th>
                        <th>Detail</th>
                    </thead>
                    <tbody>
                        {this.renderDataToJsx()}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(LihatRaportDetail)
