import React, { Component } from 'react';
import {  Modal, ModalHeader, ModalBody,ModalFooter,Button } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DataEmpty from './../components/dataEmplty'
import {MDBDataTable} from 'mdbreact'
import {connect} from 'react-redux'
import Moment from 'moment'
import { urlApi } from '../support/constant';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { getHeaderAuth } from '../support/functions/GetCookies';
// import { faPlusCircle, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

// import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'

const list_columns = ['Id','Nama Lengkap','Kelamin','Tanggal Lahir','Jenjang Pendidikan','Kamar','Pondok','Select']

var columns = list_columns.map((val) => {
    return{
        label : val,
        field : val.toLowerCase(),
        sort : 'disabled',
        width : 150,

    }
})
const list_columns_2 = ['Id','Nama Lengkap','Kelamin','Tanggal Lahir','Jenjang Pendidikan','Kelas','Pondok','Select']

var columns_2 = list_columns_2.map((val) => {
    return{
        label : val,
        field : val.toLowerCase(),
        sort : 'disabled',
        width : 150,

    }
})

class ModalTable extends Component {
    state={
        modal : false,
        tambah : false,
        dataSelected : []

    }

    onSelectClick = (id_user) => {
        if(this.state.dataSelected.includes(id_user)){
            var index_to_delete = this.state.dataSelected.indexOf(id_user)
            var data = this.state.dataSelected
            data.splice(index_to_delete,1)
            this.setState({dataSelected:data})
        }else{
            this.setState({dataSelected : [...this.state.dataSelected,id_user]})
        }
    }
    toggle = () => {
        this.setState({modal : !this.state.modal ,tambah:false,dataSelected:[]})
    }
    onDeleteClick = () => {
        let api_delete = this.props.data_apa === 'kamar' ? urlApi+'admin/hapususerfromkamar' : urlApi+'admin/hapususerfrommadin' 
        if(window.confirm('Yakin Mau Menghapus ' + this.state.dataSelected.length + ' data')){
            Axios.post(api_delete, {id_users : this.state.dataSelected,id_user : this.props.dataAdmin.id},getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert('Data Berhasil Di Hapus')
                    this.props.resetData()
                    this.toggle()
                    // this.componentDidMount()
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    onUpdateClick = () => {
        let api_update = this.props.data_apa === 'kamar' ? urlApi+'admin/addusertospesifickamar' : urlApi+'admin/addusertospesificmadin'
        if(window.confirm('Yakin Mau Update ' + this.state.dataSelected.length + ' data')){
            Axios.post(api_update, {id_users : this.state.dataSelected , id_kamar : this.props.id_kamar_sub,id_user : this.props.dataAdmin.id},getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert('Data Berhasil Di Update')
                    this.props.resetData()
                    this.toggle()
                    // this.componentDidMount()
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    render() {
        return (
            <span>
            {/* <Button color="danger" onClick={this.toggle}>Open</Button> */}
            <FontAwesomeIcon onClick={this.toggle} style={{cursor:'pointer'}} className={`text-${this.props.iconcolor}`}
                        icon={this.props.icon}
                    />
            <Modal isOpen={this.state.modal} toggle={this.toggle} size='lg' >
                <ModalHeader toggle={this.toggle}>{this.props.title} <input onClick={() => this.setState({tambah:!this.state.tambah})} type='button' style={{fontSize:12}} className={`btn btn-${this.state.tambah ?'info' :'success'} ml-3`} value={this.state.tambah? 'Lihat' : 'tambah'}  /></ModalHeader>
                <ModalBody>
                    {
                        this.props.data_apa === 'kamar' ?
                        this.props.data.length === 0 && this.state.tambah === false?
                        <DataEmpty title='Kamar Masih Kosong' fontSize={12} />:
                        <MDBDataTable responsive
                            striped
                            bordered
                            small
                            data={{
                                columns : columns , 
                                rows : this.state.tambah ?
                                this.props.data_all.map((val) => {
                                    return{
                                        'id' : val.id_user,
                                        'nama lengkap' : val.nama_lengkap,
                                        'kelamin' : val.kelamin,
                                        'tanggal lahir' : Moment(val.tanggal_lahir).format('DD-MM-YYYY'),
                                        'jenjang pendidikan' : val.jenjang_pendidikan,
                                        'kamar' : val.kamar !== null ? val.kamar : '-',
                                        'pondok' : val.id_pondok === null ? '-' : val.id_pondok === 1 ? 'Putra Sirau' : val.id_pondok === 2 ? 'Putri Sirau' : val.id_pondok === 3 ? 'Putra Ciwarak' : val.id_pondok===4 ? 'Putri Ciwarak' : null,                                    // 'foto' : <img src={urlApi + val.foto} alt='broken' width='100px' />,
                                        'select' : <div className='d-flex justify-content-center btn bg-light' onClick={()=> this.onSelectClick(val.id_user)} style={{height:30,width:50}}>
                                            {
                                                this.state.dataSelected.includes(val.id_user) ? 
                                                <FontAwesomeIcon icon={faCheck} />
                                                :null
                                            }
                                        </div>
                                    }
                                })
                                :
                                
                                this.props.data.map((val) => {
                                return{
                                    'id' : val.id_user,
                                    'nama lengkap' : val.nama_lengkap,
                                    'kelamin' : val.kelamin,
                                    'tanggal lahir' : Moment(val.tanggal_lahir).format('DD-MM-YYYY'),
                                    'jenjang pendidikan' : val.jenjang_pendidikan,
                                    'kamar' : val.kamar !== null ? val.kamar : '-',
                                    'pondok' : val.id_pondok === null ? '-' : val.id_pondok === 1 ? 'Putra Sirau' : val.id_pondok === 2 ? 'Putri Sirau' : val.id_pondok === 3 ? 'Putra Ciwarak' : val.id_pondok===4 ? 'Putri Ciwarak' : null,
                                    // 'foto' : <img src={urlApi + val.foto} alt='broken' width='100px' />,
                                    'select' : <div className='d-flex justify-content-center btn bg-light' onClick={()=> this.onSelectClick(val.id_user)} style={{height:30,width:50}}>
                                        {
                                            this.state.dataSelected.includes(val.id_user) ? 
                                            <FontAwesomeIcon icon={faCheck} />
                                            :null
                                        }
                                    </div>
                                }
                            })
                        }
                        
                        }
                            />
                        : this.props.data_apa === 'madin' ?
                        this.props.data.length === 0 && this.state.tambah === false?
                        <DataEmpty title='Kelas Masih Kosong' fontSize={12} />:
                        <MDBDataTable responsive
                            striped
                            bordered
                            small
                            data={{
                                columns : columns_2 , 
                                rows : this.state.tambah ?
                                this.props.data_all.map((val) => {
                                    return{
                                        'id' : val.id_user,
                                        'nama lengkap' : val.nama_lengkap,
                                        'kelamin' : val.kelamin,
                                        'tanggal lahir' : Moment(val.tanggal_lahir).format('DD-MM-YYYY'),
                                        'jenjang pendidikan' : val.jenjang_pendidikan,
                                        'kelas' : val.madin !== null ? val.madin : '-',
                                        'pondok' : val.id_pondok === null ? '-' : val.id_pondok === 1 ? 'Putra Sirau' : val.id_pondok === 2 ? 'Putri Sirau' : val.id_pondok === 3 ? 'Putra Ciwarak' : val.id_pondok===4 ? 'Putri Ciwarak' : null,
                                        // 'foto' : <img src={urlApi + val.foto} alt='broken' width='100px' />,
                                        'select' : <div className='d-flex justify-content-center btn bg-light' onClick={()=> this.onSelectClick(val.id_user)} style={{height:30,width:50}}>
                                            {
                                                this.state.dataSelected.includes(val.id_user) ? 
                                                <FontAwesomeIcon icon={faCheck} />
                                                :null
                                            }
                                        </div>
                                    }
                                })
                                :
                                
                                this.props.data.map((val) => {
                                return{
                                    'id' : val.id_user,
                                    'nama lengkap' : val.nama_lengkap,
                                    'kelamin' : val.kelamin,
                                    'tanggal lahir' : Moment(val.tanggal_lahir).format('DD-MM-YYYY'),
                                    'jenjang pendidikan' : val.jenjang_pendidikan,
                                    'kelas' : val.madin !== null ? val.madin : '-',
                                    'pondok' : val.id_pondok === null ? '-' : val.id_pondok === 1 ? 'Putra Sirau' : val.id_pondok === 2 ? 'Putri Sirau' : val.id_pondok === 3 ? 'Putra Ciwarak' : val.id_pondok===4 ? 'Putri Ciwarak' : null,
                                    // 'foto' : <img src={urlApi + val.foto} alt='broken' width='100px' />,
                                    'select' : <div className='d-flex justify-content-center btn bg-light' onClick={()=> this.onSelectClick(val.id_user)} style={{height:30,width:50}}>
                                        {
                                            this.state.dataSelected.includes(val.id_user) ? 
                                            <FontAwesomeIcon icon={faCheck} />
                                            :null
                                        }
                                    </div>
                                }
                            })
                        }
                        
                        }
                            />
                        :null
                    }

                </ModalBody>
                <ModalFooter>
                {
                    this.state.tambah 
                    ?
                    <Button color="success" onClick={this.onUpdateClick}>Save</Button>
                    :
                    
                    <Button color="danger" onClick={this.onDeleteClick}>Delete</Button>

                }
                {/* <Button color="primary" onClick={this.toggle}>Save</Button>{' '} */}
                </ModalFooter>
            </Modal>
            </span>
        );
    }
}
const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(ModalTable);