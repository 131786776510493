// import 'semantic-ui-css/semantic.min.css'


import React, { Component } from 'react';
import { connect } from 'react-redux';
import {MDBCard,MDBCardBody,MDBCardTitle, MDBCardText} from 'mdbreact'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import {Modal,ModalHeader,ModalBody,ModalFooter,Button} from 'reactstrap'
import Loading from '../../components/LoadingPage'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope,faPhone,faUser} from '@fortawesome/free-solid-svg-icons'
import DataEmpty from '../../components/dataEmplty';


function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}

class ManageUstadz extends Component {
    state = {
        data : null,
        isOpen : false,
        error_email : '',
        error_nomor : '',
        error_username : '',
        error_nama_lengkap : '',
        male:1,
        username : ''
    }
    componentDidMount(){
        this.getData()
    }

    getData = () => {
        Axios.get(urlApi+'academic/getallustadz',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data : res.data.data})
            }
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    toogle = () => {
        this.setState({isOpen : !this.state.isOpen,username:''})
    }
    onDeleteUstadz = (id) => {
        // alert(id)
        // alert(this.props.dataAdmin.id)
        if(window.confirm('Are You Sure Want to Delete?')){
            Axios.delete(urlApi + 'academic/deleteustadz?id=' + id +'&id_user=' + this.props.dataAdmin.id,getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.getData()
                }
            })
        }
    }
    renderData = () => {
        if(this.state.data.length === 0){
            return(
                <DataEmpty title='Data Ustadz Masih Kosong' />
            )
        }
        return this.state.data.map((val) => {
            return(
                <div className='col-md-3 p-3'>
                <MDBCard>
                    {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
                    <MDBCardBody style={{height:200}} className='d-flex justify-content-between flex-column'>
                    <div style={{flex:1}}>
                        <MDBCardTitle style={{textTransform:'capitalize',fontSize:16}}>{val.jabatan} {val.nama_lengkap}</MDBCardTitle>
                    </div>
                    <div style={{flex:3}}>
                        <MDBCardText>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faUser} color='green' /> 
                                <span>{val.username}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faEnvelope} color='green' /> 
                                <span>{val.email}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faPhone} color='green' /> 
                                <span>{val.nomor_hp}</span> 
                            </div>
                        </MDBCardText>
                    </div>
                    <div style={{flex:1}}>
                        <button onClick={() => this.onDeleteUstadz(val.id)} style={{fontSize:'10px'}} className='btn btn-danger'> Delete </button>
                    </div>
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }

    onSubmit = () => {
        var username = this.state.username
        var nomor = this.refs.nomor.value
        var email = this.refs.email.value
        var nama_lengkap = this.refs.nama_lengkap.value
        var jabatan = this.refs.panggilan.value
        var male = this.state.male
        if(!username){
            this.setState({error_username:'Username tidak boleh kosong'})
            setTimeout(() => this.setState({error_username : ''}),2000)
        }
        if(!email){
            this.setState({error_email: 'Email tidak boleh kosong'})
            setTimeout(() => this.setState({error_email : ''}),2000)
        }
        if(!nomor){
            this.setState({error_nomor:'Nomor tidak boleh kosong'})
            setTimeout(() => this.setState({error_nomor : ''}),2000)
        }
        if(!nama_lengkap){
            this.setState({error_nama_lengkap:'Nama Lengkap tidak boleh kosong'})
            setTimeout(() => this.setState({error_nama_lengkap : ''}),2000)
        }

        if(username.split(' ').length !==1){
            this.setState({error_username:'Username Tidak Boleh Pakai Spasi'})
            setTimeout(() => this.setState({error_username : ''}),2000)
        }
        var data ={
            username,email,nomor_hp:nomor, nama_lengkap,jabatan,male
        }


        if(username && nomor && email && nama_lengkap && jabatan && username.split(' ').length===1){
            Axios.post(urlApi + 'admin/check-username' , {username_baru :username},getHeaderAuth())
            .then((res) => {
                if(res.data.error){
                    return alert('Username Sudah Diambil Orang Lain')
                }else{
                    Axios.post(urlApi+'academic/addustadz?id=' + this.props.dataAdmin.id ,data,getHeaderAuth())
                    .then((res) => {
                        if(!res.data.error){
                            alert(res.data.message)
                            this.getData()
                            this.toogle()
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
            })

        }
    }

    render() {
        const closeBtn = <button className="close" onClick={() => this.setState({isOpen:false})}>&times;</button>;
        if(this.state.data === null){
            return(
                <Loading/>
            )
        }

        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Manage Ustadz</h1> 
                    <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button>
                </div>
                <div className='row justify-content-center'>
                    {
                        this.renderData()
                    }
                    
                </div>


                {/* MODAL TAMBAH DATA */}
                
                <Modal isOpen={this.state.isOpen} toggle={this.toogle}>
                    <ModalHeader toggle={this.toogle} close={closeBtn}>Tambah Ustadz</ModalHeader>
                    <ModalBody>
                        <div className='p-3'> 

                            <input ref='nama_lengkap' id='nama_lengkap' placeholder='Nama Lengkap' type='text' className='form-control' />
                            <h6 for="nama_lengkap" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error_nomor} </h6>  
                            <input onChange={(e) => {var username = e.target.value.toLowerCase() ; this.setState({username:username})}} value={this.state.username} id='username' placeholder='Username' type='text' className='form-control' />
                            <h6 for="username" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error_username} </h6>
                            <input ref='email' id='email' placeholder='Email' type='text' className='form-control' />
                            <h6 for="email" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error_email} </h6>
                            <input ref='nomor' id='nomor' placeholder='Nomor Hp / WA' type='text' className='form-control' />
                            <h6 for="nomor" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error_nomor} </h6>
                            <select onChange={() =>this.setState({male : this.refs.gender.value})} className='form-control mt-2' ref='gender'>
                                <option value={1}>Pria</option>
                                <option value={0}>Wanita</option>
                            </select>     
                            <select ref='panggilan' id='panggilan' className='form-control mt-2' >
                                <option value='' selected disabled>Pilih Panggilan</option>
                                <option>Kyai</option>
                                <option>Nyai</option>
                                <option>Gus</option>
                                <option>Ning</option>
                                <option>Ustadz</option>
                                <option>Ustadzah</option>
                                <option>Kang</option>
                                <option>Mba</option>
                            </select>
                            {/* <h6 for="panggilan" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error_panggilan} </h6>      */}
                                               
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(ManageUstadz);