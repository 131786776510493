import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PageNotFound from './../../components/NotFound'
import MyProfile from '../admin/MyProfile';
import userDetail from '../userDetail/userDetail';
import {Helmet} from 'react-helmet'

function LandingPageSantri() {
    return (
        <div className='container mt-5 pt-5 pb-5 mb-5'>
            <Helmet>
                <title>Menu Santri App</title>
            </Helmet>
            <div className='alert alert-success'>
                Selamat Datang Santri 
            </div>
            <div>
            <Switch>
                <Route path='/santri/manage-account'  component={MyProfile} />
                <Route path='/santri/detail/:id'  component={userDetail} />
                <Route path='/santri/akun'  component={MyProfile} />
                <Route path='*' component={PageNotFound}/>
            </Switch>
            </div>
        </div>
    )
}

export default LandingPageSantri;
