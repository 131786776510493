import React, { Component } from 'react'
import './donation.css'
import Swal from 'sweetalert2';
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { Redirect } from 'react-router-dom';


export class DonasiMaterial extends Component {
    state = {
        anonim : false,
        nomorWhatsapp : '',
        redirect:false ,
        unique_id : null,
        loading : false
    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    renderListAngkatan = () => {

        var output = []
        for(var i = (2020-33) ; i <= 2020 ; i ++){
            output.push(
                <option value={i}>
                    th. {i}
                </option>
            )
        }

        return output
    }

    onBtnSubmitClick = () => {
        this.setState({loading : true})
        var data = {
            jenis_barang : this.jenisBarang.value,
            kuantitas_barang : this.kuantitasBarang.value,
            metode_pengiriman_barang : this.pengirimanBarang.value,
            rencana_tanggal_pengiriman : this.tanggalPengiriman.value,
            nama_donatur : this.namaAlumni.value,
            nomor_donatur : this.state.nomorWhatsapp,
            angkatan_ke : this.ankatanKe.value,
            is_anonim : this.state.anonim,
            pesan : this.pesan.value,
            unique_id : new Date().valueOf()

        }
        console.log(data)
        for(var props in data){
            if(data[props] === '' && props !== 'pesan'){
                this.setState({loading : false})
                return Swal.fire('error','Form masih ada yang kosong')
                
            }
        }

        Axios.post(urlApi + 'donation/material',data)
        .then((res) => {
            console.log(res)
            if(res.data.error){
                Swal.fire('error',res.data.message)  
                this.setState({loading : false})

            }else{
                Swal.fire('success','Donasi berhasil ditambahkan')
                .then((val) => {
                    this.setState({loading : false})
                    window.location = '/alumni-mbangun-pprq/order-material/' + data.unique_id
                })
            }
        })
        .catch((err) => {
            console.log(err)
            this.setState({loading : false})
            Swal.fire('error',err.message)
        })
    }
    render() {
        if(this.state.redirect && this.state.unique_id !== null){
            return(
                <Redirect  to={'/alumni-mbangun-pprq/order-material/' + this.state.unique_id} />

            )

        }
        return (
            <div className='py-5 section-four-donation'>
                <div className="pt-5">
                    <div className="text-center font-weight-bold blue-color header-one-donation">
                        Form Untuk Donasi Material
                    </div>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-md-4 mt-3 mt-md-0 px-4">
                            <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Jenis Barang 
                                    </div>
                                    <input type="text" placeholder='cth. Semen, Pasir' className='form-control' ref={(el) => this.jenisBarang = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Kuantitas Barang
                                    </div>
                                    <input placeholder='cth. 1 sak, 1 truck, 1 kg' type="text" className='form-control' ref={(el) => this.kuantitasBarang = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Metode Pengiriman Barang
                                    </div>
                                    <select ref={(el) => this.pengirimanBarang = el} className='form-control'>
                                        <option selected disabled value=''>Pilih Metode</option>
                                        <option>Dikirim Sendiri</option>
                                        <option>Dijemput Pihak Pondok</option>
                                        <option>Dikirim via Kurir</option>
                                    </select>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Rencana Tanggal Pengiriman
                                    </div>
                                    <input min={new Date().toISOString().split('T')[0]} type="date" className='form-control' ref={(el) => this.tanggalPengiriman = el}/>
                                  
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Nama Alumni
                                    </div>
                                    <input placeholder='Masukan nama mu' type="text" className='form-control' ref={(el) => this.namaAlumni = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Nomor Whatsapp
                                    </div>
                                    <input placeholder='Nomor Whatsappmu' type="text" value={this.state.nomorWhatsapp} onChange={(e) => e.target.value.replace(/\D/,'').length < 14 && this.setState( {nomorWhatsapp : e.target.value.replace(/\D/,'')} )} className='form-control' ref={(el) => this.nomorWhatsapp = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Tahun Masuk PPRQ                                       
                                    </div>
                                    <select ref={(el) => this.ankatanKe = el} className='form-control'>
                                        <option selected disabled value=''>Pilih Tahun Masuk</option>
                                        {this.renderListAngkatan()}
                                    </select>
                                </p>
                               
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Tulis pesan, doa atau harapan mu (optional)
                                    </div>
                                    <textarea placeholder='Cth. Semoga berkah' rows={5} className='form-control' ref={(el) => this.pesan = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="form-check">
                                        <input onClick={() => this.setState({anonim : !this.state.anonim})} type="checkbox" checked={this.state.anonim} className="form-check-input" id="anonim"/>
                                        <label className="form-check-label font-weight-bold" for="anonim">Sembunyikan nama saya (Donasi Anonim)</label>
                                    </div>                     
                                </p>
                                <div className="row justify-content-center mt-4">
                                    {
                                        this.state.loading ?
                                        <span style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                        loading ...
                                        </span>
                                        :
                                        <span onClick={this.onBtnSubmitClick} style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                            Buat Pesanan
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DonasiMaterial
