import React, { Component } from 'react'
import { MDBCard,MDBCardBody,MDBCardHeader,MDBCardFooter } from 'mdbreact'
import LoadingPage from '../../components/LoadingPage'
import DataEmpty from '../../components/dataEmplty'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import TooltipText from '../../components/TooltipText'



class VerifySantri extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data : null
        }
    }

    componentDidMount (){
        this.getDataApi()
    }

    getDataApi = () => {
        Axios.get(urlApi + 'admin/getsantribelumverifikasi',getHeaderAuth())
        .then((res) => {
            console.log(res.data.data)
            this.setState({data : res.data.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    onVerifyClickBtn = (id) => {
        Swal.fire({
            title: 'Verifikasi Santri?',
            text: "Pastikan Semua Data Sudah Benar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm!'
          }).then((result) => {
              console.log(id)
              if(result.value){
                  Axios.get(urlApi + 'admin/verifikasisantribyid/' + id ,getHeaderAuth())
                  .then((res) => {
                      if(!res.data.error){
                          Swal.fire('Sukses',res.data.message,'success')
                          this.getDataApi()
                      }
                  })
              }
          })
    }

    renderDataToJsx = () => {
        return this.state.data.map((val) => {
            return(
                <div className='col-md-3 col-6 p-2'> 
                    <MDBCard>
                        <MDBCardHeader>
                            <div style={{ textTransform:"capitalize"}}>
                                <TooltipText text={val.nama_lengkap.toLowerCase()} length={26} />
                            </div>
                            <div> 
                                {val.jenjang_pendidikan}  || {val.instansi ? val.instansi : null}
                            </div>
                            {
                                val.nik ?
                                <div>
                                    {val.nik}
                                </div>
                                :
                                null
                            }
                        </MDBCardHeader>
                        <MDBCardBody>
                            <img style={{width:'100%',height:'200px' ,objectFit:'cover',objectPosition:'top'}} src={urlApi + val.foto} alt='broken' />
                        </MDBCardBody>
                        <MDBCardFooter>
                            <input style={{padding: '5px' , fontSize:'12px'}} onClick={() => this.onVerifyClickBtn(val.id)} type='button' value='verify' className='btn btn-outline-info' />
                            <Link to={'/user-detail/' + val.id}>
                                <input style={{padding: '5px' , fontSize:'12px'}} type='button' value='lihat detail' className='btn btn-outline-success' />
                            </Link>
                        </MDBCardFooter>
                        
                    </MDBCard>
                </div>
            )
        })
    }
    render() {
        if(this.state.data === null){
            return(
                <LoadingPage />
            )
        }

        if(this.state.data.length === 0){
            return(
                <DataEmpty title = 'Data Kosong' fontSize='20px' />
            )
        }
        return (
            <div className='container'>
                <h4>Verifikasi Pendaftaran Santri Baru</h4>
                <div className='row'>
                    {this.renderDataToJsx()}
                    
                </div>
            </div>
        )
    }
}

export default VerifySantri
