import React, { Component } from 'react'
import './../support/css/utils.css'
import MyLink from './Link'

export default class NotFound extends Component {
    render() {
        return (
            <div style={{height:'80vh'}} className='container mt-5 pt-5 mb-5 pb-5'>
                <div  className='row justify-content-center'>
                    <div className='col-md-12 text-center mt-5 pt-5'>
                        <h1 className='font-green-dark font-40 bold'>
                            Sorry, Page Not Found
                        </h1>
                    </div>
                    <div className='col-md-12 text-center'>
                        <p className='font-black-natural-dark'>Maaf, Page tidak tersedia
                        </p>
                    </div>
                    <div className='col-md-4 text-center'>
                        <MyLink to='/'> <button className='mybtn-setengah-gold'>
                            Kembali Ke Home
                        </button> </MyLink>
                    </div>

                </div>
            </div>
        )
    }
}
