import React from 'react'
import { Link } from 'react-router-dom'

export default class MyLink extends React.Component{
    render(){
        return(
            <Link to={this.props.to} style={{textDecoration:'none',display:'inline'}}> 
                {this.props.children}
            </Link>
        )
    }
}