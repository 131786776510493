import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardText,MDBNav,MDBNavItem,MDBNavLink, MDBTabContent, MDBTabPane } from 'mdbreact'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import LoadingPage from '../../components/LoadingPage'
import EmptyPage from '../../components/dataEmplty'
import PageNotFound from '../../components/NotFound'
const titles = ['Santri Terbaik Se-Pondok','Santri Terbaik Se-Pondok Putra','Santri Terbaik Se-Pondok Putri','Pelajaran Terbaik']

const siap = false

export class StatisticsRaport extends Component {
    state = {
        data : null,
        titles : titles,
        activeItem :'1'
    }
    componentDidMount(){
        this.getDataStats()
        this.getNamaKelas()
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
            activeItem: tab
            });
        }
    };
    getDataStats = () => {
        Axios.get(urlApi + 'raport/getstatisticsraport',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data : res.data.data})
                console.log(res.data.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    

    getNamaKelas = () => {
        Axios.get(urlApi + 'academic/getallkelas',getHeaderAuth())
        .then((res) => {
            let nama_kelas = []
            let respon_kelas = res.data.data
            respon_kelas.forEach((val) => {
                nama_kelas.push('Ranking Kelas - ' + val.nama )
            })
            this.setState({titles : [...this.state.titles,...nama_kelas]})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderDataToJsx = () => {
        return this.state.titles.map((val,index) => {
            return(
                <div className="col-md-4 mt-3">
                <MDBCard>
                    <MDBCardHeader>
                        {val}
                    </MDBCardHeader>
                    <MDBCardBody>
                        {
                            this.state.data[index].length > 0?
                            this.state.data[index].slice(0,5).map((val,index) => {
                                return(
                                    <MDBCardText>
                                       {index + 1}.   {val.nama_lengkap} = {Math.round(val.rata_rata)}
                                    </MDBCardText>
                                )
                            })
                            :
                            <MDBCardText>
                                Data Masih Kosong
                            </MDBCardText>
                        }
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }
    render() {
        if(siap === false){
            return <PageNotFound />
        }
        if(this.state.data === null || this.state.titles === null){
            return(
                <LoadingPage/>
            )
        }

        if(this.state.data.length === 0){
            return (
                <EmptyPage />
            )
        }
        return (
            <div>
                {   this.state.activeItem === '1'?
                    <h3>Ranking Stats</h3>
                    :
                    
                    <h3>Statistics Data Raport Akhir Tahun</h3>
                    
                }

                <MDBNav className="nav-tabs mt-5">
                    <MDBNavItem>
                        <MDBNavLink style={{color:'black'}} to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                        Ranking
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink style={{color:'black'}} to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
                        Data Stats
                        </MDBNavLink>
                    </MDBNavItem>
                    
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem}>
                    <MDBTabPane tabId={'1'}>
                        <div className="row">    
                            {this.renderDataToJsx()}
                        </div>
                    </MDBTabPane>
                    <MDBTabPane  tabId={'2'}>
                        <div style={{height: '60vh'}} className='row justify-content-center align-items-center'>
                            Data Belum Siap
                        </div>
                    </MDBTabPane>
                    
                    

                </MDBTabContent>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user : state.admin.dataAdmin
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsRaport)
