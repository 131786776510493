import React, { Component } from 'react';
import { connect } from 'react-redux';
// import UnderMaintenence from '../../components/UnderMaintenence';
import {MDBCard,MDBCardBody,MDBCardTitle,MDBCardText} from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import DataEmpty from '../../components/dataEmplty';
import MyLink from '../../components/Link';

function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}



class InputNilai extends Component {
    state ={
        data : null
    }
    componentDidMount(){
        this.getData()
    }

    getData =() => {
        Axios.get(urlApi + 'raport/getjadwalustadz?id_ustadz=' + this.props.dataAdmin.id,getHeaderAuth())
        .then((res) => {
            var data = res.data.data
            console.log(data)
            var new_data = []
            if(data.length > 0){
                var id_kelas = []
                var id_pelajaran = []
                // var is_ada = false
                data.forEach((val) => {
                    if(!(id_kelas.includes(val.id_kelas) && id_pelajaran.includes(val.id_pelajaran))){
                        new_data.push(val)
                        id_kelas.push(val.id_kelas)
                        id_pelajaran.push(val.id_pelajaran)
                    }
                })
            }
            console.log(new_data)
            this.setState({data : new_data})
        }).catch((err) => {
            console.log(err)
            alert('Network Error, Try Again')
        })
    }

    renderData = () => {
        if(this.state.data.length === 0){
            return(
                <DataEmpty title='Belum Ada Jadwal Mengajar' />
            )
        }
        return this.state.data.map((val) => {
            return(
                <div className='col-md-3 p-3'>
                <MDBCard>
                    {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
                    <MDBCardBody style={{height:200}} className='d-flex justify-content-between flex-column'>
                    <div style={{flex:1}}>
                        <MDBCardTitle style={{textTransform:'capitalize'}}> {val.pelajaran}</MDBCardTitle>
                    </div>
                    <div style={{flex:3}}>
                        <MDBCardText>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faClipboardCheck} color='green' /> 
                                <span>{val.kelas}</span> 
                            </div>
                            {/* <div>
                                <FontAwesomeIcon className='mr-2' icon={faClock} color='green' /> 
                                <span>{val.waktu === 1 ? 'Isya' : 'Maghrib'} || {val.hari}</span> 
                            </div> */}
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faMapMarkerAlt} color='green' /> 
                                <span>{val.pondok === 1 ? 'Putra Sirau' : val.pondok === 2 ? 'Putri Sirau' : val.pondok === 3 ? 'Putra Ciwarak' : val.pondok === 4? "Putri Ciwarak" : null}</span> 
                            </div>
                        </MDBCardText>
                    </div>
                    <div style={{flex:1}}> 
                        <MyLink to={`input-nilai-detail?id_pelajaran=${val.id_pelajaran}&id_kelas=${val.id_kelas}`}> <button style={{fontSize:'10px'}} className='btn btn-success'> Input Nilai </button> </MyLink>
                    </div>
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }

    render() {
        if(this.state.data === null){
            return(
                <LoadingPage/>
            )
        }
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Input Nilai</h1> 
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button> */}
                </div>
                <div className='row justify-content-center'>
                    {
                        this.renderData()
                    }
                    
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(InputNilai);