import React, { Component } from 'react'

export default class MapSection extends Component {
    render() {
        return (
            <div style={{borderTop : "1px solid #f1f1f1"}} className='container-fluid py-3 my-5'>
                <div className='row justify-content-center'>
                    <div className='col-md-12 mt-3 mt-md-0'>
                        <h3 className='text-center mb-4'>
                            Lokasi di Google Map
                        </h3>
                        <iframe   title='PPNR On Google Map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15818.558561607051!2d109.2793221!3d-7.614133!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x844cec25af9a7867!2sPondok%20Pesantren%20Nuururrohman!5e0!3m2!1sid!2sid!4v1604588952856!5m2!1sid!2sid" width="100%" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>

            </div>
        )
    }
}
