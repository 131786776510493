import React, { Component } from 'react'
import { MDBCard, MDBCardTitle, MDBCardText, MDBBtn, MDBCardBody } from 'mdbreact'
import { urlApi } from '../../support/constant'
import {Helmet} from 'react-helmet'

const data = [
    {title : `PPRQ Sirau`, text : "Download Brosur disini",link : urlApi +"download/brosur-pondok"},
    {title : `SMP Ma'arif NU 2 Kemranjen`, text : "Download Brosur disini",link : urlApi +"download/brosur-smp"},
    {title : `SMA Ma'arif NU 1 Kemranjen`, text : "Download Brosur disini",link : urlApi +"download/brosur-sma"},
    {title : `SMK PPRQ Sirau`, text : "Download Brosur disini",link : urlApi +"download/brosur-smk"},
]

export default class DownloadBrosur extends Component {
    renderDataToJsx = () => {
        return data.map((val) => {
            return(
                <div className='col-md-4 mt-4'>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle>{val.title}</MDBCardTitle>
                            <MDBCardText>
                                {val.text}
                            </MDBCardText>
                            <MDBBtn href={val.link}>Download</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            )
        })
    }

    render() {
        return (
            <div className='container my-5 py-5'>
                <Helmet>
                    <title>
                        Download Brosur Seputar PPRQ Sirau
                    </title>
                </Helmet>
                
                <h2>Download Brosur Disini</h2>
                <div className='row mt-3'>
                    {this.renderDataToJsx()}
                </div>
                

            </div>
        )
    }
}
