import React, { Component } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import LoadingPage from './../../components/LoadingPage'
import { RoundEpsilon } from '../../support/functions/RoundEpsilon'
export class RaportSantri extends Component {
    state = {
        dataUser : null,
        dataKelasDanPelajaran : null,
        dataNilai : null,
        rata_rata : null
    }
    componentDidMount(){
        var id = this.props.match.params.id
        this.getDataSantri(id)

    }

    getDataSantri = (id) => {
        Axios.get(urlApi + 'raport/getuserbyid/' + id, getHeaderAuth())
        .then((res) => {
            this.setState({dataUser : res.data.data[0]})
            console.log(res.data.data)
            this.getPelajaranByKelas(res.data.data[0].id_madin)
            this.getDataNilaiByIdUser(id)
            this.getRataRataKelas(res.data.data[0].id_madin)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getDataNilaiByIdUser = (id_user) => {
        Axios.get(urlApi + 'raport/getdatanilaibyiduser/' + id_user,getHeaderAuth())
        .then((res) => {
            this.setState({dataNilai : res.data.data})
            console.log(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getPelajaranByKelas = (idkelas) => {
        Axios.get(urlApi + 'raport/getdatapelajaranustadzbyidkelas/' + idkelas,getHeaderAuth())
        .then((res) => {
            this.setState({dataKelasDanPelajaran : res.data.data})
            console.log(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    getRataRataKelas = (id_kelas) => {
        Axios.get(urlApi + 'raport/getrataratakelas/' + id_kelas , getHeaderAuth())
        .then((res) => {
            console.log(res.data.data)
            this.setState({rata_rata : res.data.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderNilai = () => {
        let data = []
        let avg_siswa = 0;
        this.state.dataKelasDanPelajaran.forEach((val) => {
            var obj = {}
            obj.pelajaran = val.pelajaran
            obj.ustadz = val.ustadz
            obj.panggilan = val.panggilan
            var nilai = this.state.dataNilai.filter((nilai) => {
                return nilai.id_pelajaran === val.id_pelajaran
            })
            obj.nilai = nilai.length > 0 ? nilai[0].nilai : 0
            avg_siswa += nilai.length > 0 ? nilai[0].nilai : 0
            var rata = this.state.rata_rata.filter((rata) => {
                return rata.id_pelajaran === val.id_pelajaran
            })
            obj.rata_rata = rata.length > 0 ? rata[0].rata_rata : 0

            data.push(obj)
        })

        avg_siswa = avg_siswa / this.state.dataNilai.length
        console.log(data)
        var output = data.map((val,index) => {
            return(
                <tr>
                    <td>{index +1}</td>
                    <td>{val.pelajaran}</td>
                    <td>{val.panggilan +' ' + val.ustadz}</td>
                    <td>{RoundEpsilon(val.rata_rata ? val.rata_rata : 0)}</td>
                    <td>{RoundEpsilon(val.nilai ? val.nilai : 0)}</td>
                </tr>
            )
        })

        output.push(
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Rata Rata</td>
                <td> {RoundEpsilon(avg_siswa ? avg_siswa : 0)}</td>
            </tr>
        )

        output.push(
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Kenaikan Kelas</td>
                {avg_siswa >= 70 ? 
                <td> <span>Naik</span> / <span style={{textDecoration:'line-through'}}>Tidak Naik</span> </td>
                :
                <td> <span style={{textDecoration:'line-through'}}>Naik</span> / <span >Tidak Naik</span> </td>
                }
            </tr>
        )

        return output
    }
        
    render() {
    
        if(this.state.dataUser === null || this.state.dataKelasDanPelajaran === null || this.state.dataNilai === null || this.state.rata_rata === null){
            return(
                <LoadingPage />
            )
        }
        return (
            <div className='my-5 py-5'>
                <div className="container">
                    <h4>Raport Santri</h4> 
                    <div className="row justify-content-between">
                        <div className="col-9">
                            <div>
                                Nama : {this.state.dataUser.nama_lengkap}
                            </div>
                            <div>
                                Kelas : {this.state.dataKelasDanPelajaran[0].kelas}
                            </div>
                        </div>
                        <div className="col-3">
                            <a href={'/print-raport-pdf/' + this.state.dataUser.id_user} rel="noopener noreferrer" target='_blank'>
                                <input type="button" value='print raport' className="btn btn-outline-info" style={{padding:'5px',fontSize:'12px'}}/>
                            </a>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <th>No</th>
                                <th>Pelajaran</th>
                                <th>Pengajar</th>
                                <th>Rata Rata Kelas</th>
                                <th>Nilai</th>
                            </thead>
                            <tbody>
                                {this.renderNilai()}
                            
                            </tbody>
                        </table>

                    </div>

                    

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(RaportSantri)
