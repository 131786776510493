import React, { Component } from 'react'
import Axios from 'axios'
import { urlApi } from '../../../support/constant'
import Swal from 'sweetalert2'
import LoadingPage from '../../../components/LoadingPage'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact'
import { formatRupiah } from '../../../support/functions'
import moment from 'moment'
import EmptyData from './../../../components/dataEmplty'
import { StatusPembayaran, StatusMaterial,MetodePembayaran, UangMessage, MaterialMessage, NumberProcess } from '../../donation/StatusPembayaran'

export class StatistikDonasi extends Component {
    state = {
        dataUang : null,
        dataMaterial : null,
        statsUang : null,
        statsMaterial : null,
        tableData : null,
        titleTable : null
    }

    componentDidMount(){
        this.getAllDonation()
    }

    getAllDonation = () => {
        Axios.get(urlApi+'donation/all-donation')
        .then((res) => {
            if(!res.data.error){
                console.log(res.data.data)
                var statsUang = {
                    total_donasi_ : [0,0],
                    total_donasi_Success : [0,0],
                    total_donasi_Pending : [0,0],
                    total_donasi_Gagal : [0,0]
                }
                var statsMaterial = {
                    total_donasi_ : 0,
                    total_donasi_Success : 0,
                    total_donasi_Pending : 0,
                    total_donasi_Gagal : 0
                }
                res.data.data[0].forEach((val) => {
                    if(val.status_pembayaran === 4){
                        statsUang.total_donasi_Gagal[0] ++
                        statsUang.total_donasi_Gagal[1] += val.nominal
                    }else if(val.status_pembayaran === 3){
                        statsUang.total_donasi_Success[0] ++
                        statsUang.total_donasi_Success[1] += val.nominal
                    }else{
                        statsUang.total_donasi_Pending[0] ++
                        statsUang.total_donasi_Pending[1] += val.nominal
                    }
                    statsUang.total_donasi_[0] ++
                    statsUang.total_donasi_[1] += val.nominal
                })

                res.data.data[1].forEach((val) => {
                    if(val.status_donasi === 4){
                        statsMaterial.total_donasi_Gagal ++
                    }else if(val.status_donasi === 3){
                        statsMaterial.total_donasi_Success ++
                    }else{
                        statsMaterial.total_donasi_Pending ++
                    }
                    statsMaterial.total_donasi_ ++
                })

                console.log(statsUang)
                console.log(statsMaterial)
                this.setState({dataUang : res.data.data[0],dataMaterial : res.data.data[1],statsUang,statsMaterial})
            }
        })
        .catch((err) => {
            Swal.fire('error',err.message)
        })
    }

    onFilterDataUang = (status) => {
        console.log(status)
        let data = this.state.dataUang
        switch(status){
            case 'total_donasi_':
                this.setState({tableData : data,titleTable : status + " (Uang)"})
                break
            case 'total_donasi_Success':
                console.log('success')
                data = data.filter((val) => {
                    return val.status_pembayaran === 3
                })
                this.setState({tableData : data,titleTable : status + " (Uang)"})
                break
            case 'total_donasi_Pending':
                data = data.filter((val) => {
                    return val.status_pembayaran < 3
                })
                this.setState({tableData : data,titleTable : status + " (Uang)"})
                break
            case 'total_donasi_Gagal':
                data = data.filter((val) => {
                    return val.status_pembayaran === 4
                })
                this.setState({tableData : data,titleTable : status + " (Uang)"})
                break
            default:
                this.setState({tableData : null})

        }
    }

    onFilterDataMaterial = (status) => {
        let data = this.state.dataMaterial
        switch(status){
            case 'total_donasi_':
                this.setState({tableData : data,titleTable : status + ' (Material)'})
                break
            case 'total_donasi_Success':
                data = data.filter((val) => {
                    return val.status_donasi === 3
                })
                this.setState({tableData : data,titleTable : status + ' (Material)'})
                break
            case 'total_donasi_Pending':
                data = data.filter((val) => {
                    return val.status_donasi === 1 || val.status_donasi === 2
                })
                this.setState({tableData : data,titleTable : status + ' (Material)'})
                break
            case 'total_donasi_Gagal':
                data = data.filter((val) => {
                    return val.status_donasi === 4
                })
                this.setState({tableData : data,titleTable : status + ' (Material)'})
                break
            default:
                this.setState({tableData : null})
        }
    }

    renderStatsUang = () => {
        var keys = Object.keys(this.state.statsUang)
        return keys.map((val) => {
            return(
                <div style={{textTransform : "capitalize"}}>
                    {val.split('_').join(' ')} =  Rp. {formatRupiah(this.state.statsUang[val][1])} <span className='badge badge-primary' style={{cursor : "pointer"}} onClick={() =>this.onFilterDataUang(val)}> {this.state.statsUang[val][0]} transaksi</span>
                </div>
            )
        })
    }

    renderStatsMaterial = () => {
        var keys = Object.keys(this.state.statsUang)
        return keys.map((val) => {
            return(
                <div style={{textTransform : "capitalize"}}>
                    {val.split('_').join(' ')} =  <span className='badge badge-primary' style={{cursor : "pointer"}} onClick={() =>this.onFilterDataMaterial(val)}> {this.state.statsMaterial[val]} transaksi</span>
                </div>
            )
        })

       
    }

    onUbahStatusClick = (title,unique_id) => {
        var link = title.includes('Uang') ? 'status-uang' : 'status-material'
        var option = title.includes('Uang') ? StatusPembayaran : StatusMaterial
        var inputOptions = {}
        option.forEach((val,index) => {
            inputOptions[index+1] = val
        })

        Swal.fire({
            title : "update Status",
            input : "select",
            inputOptions : inputOptions,
            inputPlaceholder : "Select Status",
            showCancelButton : true
        })
        .then((result) => {
            if(result.value){
                const data = title.includes('Uang') ? {status_pembayaran : result.value} : {status_donasi : result.value}
                Axios.patch(urlApi + 'donation/' + link + '/' + unique_id,data)
                .then((res) => {
                    if(!res.data.error){
                        Swal.fire('success' , res.data.message)
                        .then((val) => {
                            this.setState({tableData:null,titleTable :null})
                            this.getAllDonation()

                        })
                    }
                })
                .catch((err) => {
                    Swal.fire('error' , err.message)
                })
            }
        })
    }

    onClickKirimPesan = (data,title) => {
        var inputOptions = {1 : "Template 1" , 2  : "Template 2" ,'new' : "New Message"}
        Swal.fire({
            title : "Select Message",
            input : 'select',
            inputOptions : inputOptions,
            showCancelButton : true
        })
        .then((result) => {
            if(result.value === 'new'){
                Swal.fire({
                   input : "textarea",
                   inputPlaceholder : "type message here",
                   showCancelButton : true,
                   confirmButtonText : "Send"
                })
                .then((result) => {
                    if(result.value){
                        var nomor = NumberProcess(data.nomor_donatur)
                        var text = encodeURI(result.value)
                        window.open('https://wa.me/' + nomor + '?text='+text,'_blank')

                    }
                })
            }else if(result.value){
                Swal.fire({
                    title : "Isi Pesan : ",
                    input : "textarea",
                    inputValue : title.includes('Uang') ? UangMessage(data,result.value) : MaterialMessage(data,result.value),
                    showCancelButton : true,
                    confirmButtonText : "Send"
                })
                .then((result) => {
                    if(result.value){
                        var nomor = NumberProcess(data.nomor_donatur)
                        var text = encodeURI(result.value)

                        window.open('https://wa.me/' + nomor + '?text='+text,'_blank')
                    }
                })
            }
        })
    }

    renderDataTable = () => {
        let columnName = this.state.titleTable.includes('Uang') ? ['Tanggal','ID Transaksi','Nama Donatur','Nomor WA','Angkatan','Nominal', 'Metode Pembayaran','Status Transaksi','Action']    : ['Tanggal','Id Transaksi','Nama Donatur','Nomor WA','Angkatan','Jenis Barang','Kuantitas','Metode Pengiriman Barang','Status','Action']
        let keysName = this.state.titleTable.includes('Uang') ? 'unique_id nama_donatur nomor_donatur angkatan_ke nominal metode_pembayaran status_pembayaran'.split(' ') : 'unique_id nama_donatur nomor_donatur angkatan_ke jenis_barang kuantitas_barang metode_pengiriman_barang status_donasi'.split(' ')
        let headerTable = columnName.map((val) => {
            return (
                <th scope="col">{val}</th>
            )
        })

        let tableBodyContent =
        this.state.tableData.map((val) => {
            return(
                <tr>
                    <td>{moment(val.created_at.split('T')[0], "YYYY-MM-DD").fromNow() }</td>
                    {
                        keysName.map((col) => {
                            if(col === 'metode_pembayaran'){
                                return(
                                    <td><span className='badge badge-success'>{MetodePembayaran[val[col]-1]}</span> </td>
                                )
                            }
                            if(col === 'status_pembayaran'){
                                return(
                                    <td><span className='badge badge-warning'>{StatusPembayaran[val[col]-1]}</span> </td>
                                )
                            }
                            if(col === 'status_donasi'){
                                return(
                                    <td><span className='badge badge-warning'>{StatusMaterial[val[col]-1]}</span></td>
                                )
                            }
                            return(
                                <td>{val[col]}</td>
                            )
                        })
                    }
                    <td>
                       
                        <button className='badge badge-info mx-1' onClick={() => this.onClickKirimPesan(val,this.state.titleTable)}> kirim pesan</button>
                        <button className='badge badge-success mx-1' onClick={() => this.onUbahStatusClick(this.state.titleTable,val['unique_id'])}> ubah status</button>
                        {
                            val['status_pembayaran'] !== 1 && this.state.titleTable.includes('Uang') ?
                            <button className='badge badge-primary mx-1' onClick={() => Swal.fire({imageUrl: urlApi + val['payment_proof_image'],imageWidth:'80vw',imageAlt:"payment_proof"})}> Lihat Bukti TF</button>
                            :
                            null
                        }
                    
                    </td>
                </tr>
            )
        })        

        if(this.state.tableData.length === 0){
            return <EmptyData  title='data kosong'/>
        }

        return  (
            <div className="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            {headerTable}
                        </tr>
                    </thead>
                    <tbody>
                        {tableBodyContent}
                    </tbody>
                </table>
            </div>
    )
    }

    render() {
        console.log(this.state.tableData)
        console.log(this.state.dataUang)
        if(this.state.dataMaterial === null || this.state.dataUang === null || this.state.statsMaterial === null || this.state.statsUang === null){
            return(
                <LoadingPage />
            )
        }
        return (
            
            <div>
                <h1>Donasi PPRQ 3</h1>
                <div className="row mt-3">
                    <div className="col-md-6 ">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    Donasi Uang
                                </MDBCardTitle>
                                <MDBCardText>
                                   {this.renderStatsUang()}
                                </MDBCardText>
                            </MDBCardBody>
                           
                        </MDBCard>
                    </div>
                    <div className="col-md-6 mt-3 mt-md-0">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    Donasi Material
                                </MDBCardTitle>
                                <MDBCardText>
                                   {this.renderStatsMaterial()}
                                </MDBCardText>
                            </MDBCardBody>
                           
                        </MDBCard>
                    </div>
                </div>

                {
                    this.state.tableData !== null ?
                    <div className="mt-5">
                        <MDBCard>
                            <MDBCardHeader>
                                <MDBCardTitle>
                                   {this.state.titleTable.split('_').join(' ')}
                                </MDBCardTitle>
                            </MDBCardHeader>
                            <MDBCardBody>
                                {this.renderDataTable()}
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                :
                    null    
                }
                
              
            </div>
        )
    }
}



export default StatistikDonasi
