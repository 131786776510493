import React, { Component } from 'react';
import Image_1 from './../support/assets/images/empty.png'

class dataEmplty extends Component {
    render() {
        return (
            <div className='container'>
                <div className='row justify-content-center'>
                    <h4 style={{fontSize:this.props.fontSize}}>
                       {this.props.title}
                    </h4>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <img alt='broken' width='100%' src={Image_1}>
                        </img>
                    </div>
                </div>
            </div>
        );
    }
}

export default dataEmplty;