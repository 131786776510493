
import React, { Component } from 'react';
import MyLink from './Link'

class UnderMaintenence extends Component {
    render() {
        return (
            <div>
                 <div style={{height:'80vh'}} className='container'>
                <div  className='row justify-content-center'>
                    <div className='col-md-12 text-center mt-5 pt-5'>
                        <h1 className='font-green-dark font-40 bold'>
                            Fitur Masih dalam Tahap Development
                        </h1>
                    </div>
                    <div className='col-md-12 text-center'>
                        <p className='font-black-natural-dark'>Harap Bersabar
                        </p>
                    </div>
                    <div className='col-md-4 text-center'>
                        <MyLink to='/'> <button className='mybtn-setengah-gold'>
                            Kembali Ke Home
                        </button> </MyLink>
                    </div>

                </div>
            </div>
            </div>
        );
    }
}

export default UnderMaintenence;