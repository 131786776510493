import React,{ useState ,useEffect} from 'react';
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import DataEmpty from '../../components/dataEmplty';
import { MDBCard, Badge } from 'mdbreact';
import {Link} from 'react-router-dom';
import TooltipText from '../../components/TooltipText';
import Moment from 'moment'

function DaftarAlumni() {
    const [data,setData] = useState(null)

    useEffect(() => {
        getDataAlumni()
    }, [])


    const getDataAlumni = () => {
        Axios.get(urlApi+'alumni', getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                setData(res.data.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const renderData = () => {
        return data.map((val) => {
            return(
                <div className="col-md-4 mt-2 mt-md-4">
                    <MDBCard className='p-2'>
                        <div className='row'>
                            <div className="col-3">
                                <img width='70px' style={{borderRadius:'70px',objectFit: "cover",objectPosition:'middle'}} height='70px' src={urlApi + val.foto} alt="broken"/>
                            </div>
                            <div className="col-8">
                                <div style={{cursor:'pointer'}}>
                                    <Link style={{color :"inherit"}} to={'/user-detail/' + val.id_user}>
                                        <TooltipText
                                            length={27}
                                            text={val.nama_lengkap + ' ('+val.nama_panggilan+ ')'}
                                        />
                                    </Link>
                                </div>
                                <div>
                                    <Badge style={{cursor:'pointer'}} color="success">{Moment(val.created_at).format('YYYY')}</Badge>
                                    <Badge style={{cursor:'pointer'}} className='ml-2' color="info">{val.alasan}</Badge>
                                </div>
                                
                            </div>
                        </div>
                            
                    </MDBCard>
                </div>
            )
        })
    }

    if(data === null) return <LoadingPage />
    if(data.length === 0) return <DataEmpty title='Belum Ada Data Alumni' />
 
    return (
        <div>
            <h2>Daftar alumni</h2>
            <div className='row'>
                {
                    renderData()
                }
            </div>
        </div>
    )
}

export default DaftarAlumni;
