import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'querystring'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import {Modal,ModalHeader,ModalBody,ModalFooter,Button} from 'reactstrap'
import DataEmpty from '../../components/dataEmplty';

function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}

class InputNilaiDetail extends Component {
    state= {
        data : null,
        nilai : null,
        info : null,
        isOpen : false,
        dataEdit : null
    }
    componentDidMount(){
        this.getDataSantri()
        this.getDataNilai()
        this.getDataNamaKelasAndPelajaran()
    }

    toogle = () => {
        this.setState({isOpen : !this.state.isOpen,dataEdit : null})
    }
    getDataUrl = () => {
        var addres = this.props.location.search
        var data=  qs.parse(addres.substring(1))
        console.log(addres)
        console.log(data)
        return data
    }

    getDataNamaKelasAndPelajaran = () => {
        var data_url = this.getDataUrl()
        Axios.get(urlApi + 'raport/getnamakelasandpelajaran?id_kelas=' + data_url.id_kelas + '&id_pelajaran='+data_url.id_pelajaran,getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({info :res.data.data})
            }
        })
        .catch((err) => {
            return alert('Network Error')
        })
    }

    getDataNilai = () => {
        var data_url = this.getDataUrl()
        Axios.post(urlApi + 'raport/getnilaibykelasandpelajaranandustadz' , {id_pelajaran : data_url.id_pelajaran , id_kelas: data_url.id_kelas , id_ustadz : this.props.dataAdmin.id},getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                console.log(res.data.data)
                this.setState({nilai : res.data.data})
            }else{
                return alert('Something Error')
            }
        })
        .catch((err) => {
            return alert('Network Error')
        })
    }

    getDataSantri = () => {
        var data_url = this.getDataUrl()
        Axios.get(urlApi + 'raport/getsantribykelas?id_kelas='+data_url.id_kelas,getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                console.log(res.data.data)
                this.setState({data:res.data.data})
            }
        })
        .catch((err) => {
            return alert('Network Error')
        })
    }
    onClickRow = (data) => {
        this.setState({dataEdit : data,isOpen: true})
    }
    renderDataTable = () => {
        var new_data = []
        var {data,nilai} = this.state
        // if(data.length === 0){
        //     return (<DataEmpty title='Data Santri Masih Kosong' />)
        // }
        data.forEach((val,index) => {
            var new_obj = {}
            var udah = false
            nilai.forEach((score,i) => {
                if(Number(val.id_user) === Number(score.id_user)){
                    new_obj.id_nilai = score.id
                    new_obj.id_user = val.id_user
                    new_obj.nama_lengkap = val.nama_lengkap
                    new_obj.foto = val.foto
                    new_obj[score.kategori] = score.nilai
                    new_obj.is_edit = true
                    udah = true
                }
            })

            if(!udah){
                new_obj.id_user = val.id_user
                new_obj.nama_lengkap = val.nama_lengkap
                new_obj.foto = val.foto
                new_obj.is_edit = false
                // new_obj.Ujian = 0
                // new_obj.Sorogan = 0
                // new_obj.Absensi = 0
            }
            new_data.push(new_obj)
        })

        console.log(new_data)
        
        return new_data.map((val,index) => {
            return(
                <tr onClick={() => this.onClickRow(val)} style={{cursor:'pointer'}}>
                    <td>{index + 1}</td>
                    <td>{val.nama_lengkap}</td>
                    <td><img src={urlApi + val.foto} width={100} height={100} alt='broken' /></td>
                    <td>{val.Ujian ? val.Ujian : 0}</td>
                    <td>{val.Sorogan ? val.Sorogan : 0}</td>
                    <td>{val.Absensi ? val.Absensi: 0}</td>
                    <td>{Math.round(((val.Ujian ? val.Ujian : 0) + (val.Absensi?val.Absensi : 0) + (val.Sorogan ? val.Sorogan : 0)) / 3)}</td>
                </tr>
            )
        })
    }

    dataNilaiGenerator = (id_nilai_kategori,id_kelas,id_pelajaran,id_ustadz,id_user,nilai) => {
        return {
            id_nilai_kategori,id_kelas,id_pelajaran,id_ustadz,id_user,nilai
        }
    }

    onAdd = () => {
        var sorogan = this.refs.sorogan.value
        var absensi = this.refs.absensi.value
        var ujian = this.refs.ujian.value
        var kelas_pelajaran = this.getDataUrl()
        if((sorogan > 0 && sorogan < 101) &&(absensi > 0 && absensi < 101) && (ujian > 0 && ujian < 101) ){
            var data = [
                this.dataNilaiGenerator(1,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(ujian)),
                this.dataNilaiGenerator(2,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(sorogan)),
                this.dataNilaiGenerator(3,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(absensi)),            
            ]

            Axios.post(urlApi + 'raport/addnilai',data,getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.componentDidMount()
                    this.setState({dataEdit:null,isOpen:false})
                }
            })
            .catch((err) => {
                return alert('Network Error')
            })
            console.log(data)
        }else{
            return alert('Format Nilai Harus > 0 dan < 100')
        }
    }

    onEdit =() => {
        var sorogan = this.refs.sorogan.value
        var absensi = this.refs.absensi.value
        var ujian = this.refs.ujian.value
        var kelas_pelajaran = this.getDataUrl()
        if((sorogan > 0 && sorogan < 101) &&(absensi > 0 && absensi < 101) && (ujian > 0 && ujian < 101) ){
            var data = [
                this.dataNilaiGenerator(1,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(ujian)),
                this.dataNilaiGenerator(2,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(sorogan)),
                this.dataNilaiGenerator(3,Number(kelas_pelajaran.id_kelas),Number(kelas_pelajaran.id_pelajaran),this.props.dataAdmin.id,this.state.dataEdit.id_user,Number(absensi)),            
            ]

            Axios.post(urlApi + 'raport/editnilai',data,getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.componentDidMount()
                    this.setState({dataEdit:null,isOpen:false})
                }
            })
            .catch((err) => {
                return alert('Network Error')
            })
            console.log(data)
        }else{
            return alert('Format Nilai Harus > 0 dan < 100')
        }
    }
    // data  = {
    //     anak_ke: 1
    //     created_at: "2019-11-24T15:51:22.000Z"
    //     foto: "public/registrasi/images/REG-1574610682403.JPG"
    //     id: 24
    //     id_kamar: null
    //     id_madin: 1
    //     id_user: 29
    //     is_deleted: 0
    //     jenjang_pendidikan: "SMA/SMK"
    //     jumlah_saudara: "3"
    //     kelamin: "Wanita"
    //     nama_lengkap: "Luki Setiawati"
    //     nama_panggilan: "Luki"
    //     nisn: "2019060298"
    //     password: "1574610682400"
    //     role: 1
    //     tanggal_lahir: "2003-05-10T10:00:00.000Z"
    //     username: "Luki1574610682400"
    // }

    // data_nilai = {
    //     id: 1
    //     id_kelas: 1
    //     id_pelajaran: 1
    //     id_user: 29
    //     id_ustadz: 8
    //     kategori: "Ujian"
    //     nilai: 90
    // }
    render() {
        const closeBtn = <button className="close" onClick={() => this.setState({isOpen:false})}>&times;</button>;
        if(this.state.data === null || this.state.nilai === null || this.state.info === null){
            return(
                <LoadingPage />
            )
        }
        if(this.state.data.length === 0){
            return(
                <DataEmpty title='Data Santri Masih Kosong'/>
            )
        }
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Input Nilai</h1> 
                    <span style={{fontSize:14,fontStyle:'italic',color:'darkgrey'}}> {this.state.info.kelas} || {this.state.info.pelajaran}</span>
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button> */}
                </div>
                <MDBTable hover responsive className='mt-3'>
                    <MDBTableHead>
                        <tr>
                        <th>#</th>
                        <th>Nama Lengkap</th>
                        <th>Foto</th>
                        <th>Ujian</th>
                        <th>Sorogan</th>
                        <th>Absensi</th>
                        <th>Total</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                       {this.renderDataTable()}
                    </MDBTableBody>
                </MDBTable>


                {/* MODAL INPUT NILAI */}
                <Modal isOpen={this.state.isOpen} toggle={this.toogle}>
                    <ModalHeader toggle={this.toogle} close={closeBtn}>{ (this.state.dataEdit !== null ? this.state.dataEdit.is_edit:null) ? 'Edit' : 'Add'} < span style={{fontSize:12,fontStyle:'italic',color:'darkgrey'}}> {this.state.dataEdit !== null ? this.state.dataEdit.nama_lengkap : null} </span></ModalHeader>
                    <ModalBody>
                        <div className='p-3 row'> 
                            <input type='number' defaultValue={this.state.dataEdit !== null ? this.state.dataEdit.Ujian : null} placeholder='Ujian' ref='ujian' className='form-control col-4' />
                            <input type='number' defaultValue={this.state.dataEdit !== null ? this.state.dataEdit.Sorogan : null} placeholder='Sorogan' ref='sorogan' className='form-control col-4' />
                            <input type='number' defaultValue={this.state.dataEdit !== null ? this.state.dataEdit.Absensi : null} placeholder='Absensi' ref='absensi' className='form-control col-4' />                                               
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={(this.state.dataEdit !== null ? this.state.dataEdit.is_edit:null) ? this.onEdit : this.onAdd}>{(this.state.dataEdit !== null ? this.state.dataEdit.is_edit:null) ? 'Edit' : 'Add'}</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(InputNilaiDetail);