import React, { Component } from 'react'

// /import './../../support/css/admin.css'

// import './../../support/css/utils.css'

import UnderMaintenence from '../../components/UnderMaintenence'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
// import MyLink from '../../components/Link'
import LoadingPage from '../../components/LoadingPage'
import {connect} from 'react-redux'
import {MDBCard,MDBModal,MDBModalBody,MDBModalFooter,MDBModalHeader,MDBBtn} from 'mdbreact'
import { getHeaderAuth,resetCookie } from '../../support/functions/GetCookies'
import { Redirect } from 'react-router-dom'

const siap = true

class MyProfile extends Component {
    state = {
        data : null,
        modal_password : false,
        modal_username : false ,
        username_baru : ''
    }


    componentDidMount(){
        this.setState({data:this.props.users})
    }

    toggle_password =() =>{
        this.setState({modal_password:!this.state.modal_password})
    }

    toggle_username =() =>{
        this.setState({modal_username:!this.state.modal_username})
    }


    onSaveChangePassword = () => {
        var username = this.state.data.username
        var password = this.refs.password_lama.value
        var password_baru = this.refs.password_baru.value
        var password_konfirm = this.refs.password_konfirm.value
        if(password && password_baru && password_konfirm){
            if(password_baru === password_konfirm){
                if(password !== this.state.data.password){
                    return alert('Password Salah') 
                }else{ 
                    let urlChangePassword = urlApi
                    urlChangePassword +=   Number(this.state.data.role) === 1 ? 'santri/change-password' : 'admin/change-password'
                    Axios.post(urlChangePassword,{password_baru,username,id:this.state.data.id,password_lama:password},getHeaderAuth())
                    .then((res) => {
                        if(!res.data.error){
                            var new_data = this.state.data
                            new_data.password = password_baru
                            this.setState({modal_password: false,data:new_data})
                            return alert('Password Berhasil diubah')
                        }
                    })
                }
            }else{
                return alert('Password Konfirm Tidak Sama')
            }
        }
        else{
        return alert('Semua Form Harus Terisi')
        }
    }

    onSaveChangeUsername = () => {
        var username_baru = this.state.username_baru
        var password = this.refs.password_username.value
        if(username_baru.split(' ').length !== 1){
            return alert('Username Tidak Boleh Menggunakan Spasi')
        }
        if(username_baru && password){
            if(password !== this.state.data.password){
                return alert('Password Salah')
            }else{
                let urlCheck = urlApi
                urlCheck +=   Number(this.state.data.role) === 1 ? 'santri/check-username' : 'admin/check-username'
                console.log(urlCheck)
                Axios.post(urlCheck , {username_baru},getHeaderAuth())
                .then((res) => {
                    if(!res.data.error){
                        let urlChangeUsername = urlApi
                        urlChangeUsername +=   Number(this.state.data.role) === 1 ? 'santri/change-username' : 'admin/change-username'
                        Axios.post(urlChangeUsername,{username_baru,password,username_lama : this.state.data.username,id:this.state.data.id},getHeaderAuth())
                        .then((res) => {
                            if(!res.data.error){
                                var {key} = require('./../../support/constant/index')
                                localStorage.removeItem(key)
                                localStorage.setItem(key,res.data.token,{path : '/',expires:new Date(Date.now()+1.8e+6)})
                                var data = this.state.data
                                data.username = username_baru
                                resetCookie()
                                this.setState({modal_username :false,data:data})
                                return alert('Username Berhasil diubah')
                            }
                        })
                    }else{
                        return alert('Username Sudah Diambil Orang Lain')
                    }
                })
            }
        }else{
            return alert('Semua Form Harus Diisi')
        }
    }
    render() {
        if(siap === false){
            return(
                <UnderMaintenence/>
            )
        }
        if(this.props.users.username === ''){
            return(
                <Redirect to='/' />
            )
        }
        if(this.state.data === null){
            return(
                <LoadingPage/>
            )
        }
        return (
            
          <div className='row justify-content-center'>
              <div className='col-md-6'>
                <MDBCard className='p-5'>
                    <div className='row'>
                        <span className='label-data col-4'>Username</span>
                        <span style={{textTransform:'lowercase'}} className='data-label col-8' >{this.state.data.username}{' '} <span onClick={this.toggle_username} style={{fontSize:12,fontStyle:'italic' , color:'blue' , textDecoration:'underline' , cursor:'pointer'}}> Change Username </span> </span>

                    </div>
                    <div className='row'>
                        <span className='label-data col-4'>Jabatan </span>
                        <span className='data-label col-8' >{this.state.data.role ===1 ? "Santri" : this.state.data.role === 2 ? 'Pengurus' : this.state.data.role === 3 ? 'Pengajar' : this.state.data.role === 4 ? "Pengasuh" : null}</span>

                    </div>
                    <div className='row'>
                        <span className='label-data col-4'>Password </span>
                        <span onClick={this.toggle_password} className='data-label col-8' style={{fontStyle:'italic' , color:'blue' , textDecoration:'underline' , cursor:'pointer' , fontSize:12}} >Change Password</span>

                    </div>
                </MDBCard>
              </div>


              {/* CHANGE PASSWORD */}
              <MDBModal isOpen={this.state.modal_password} toggle={this.toggle_password}>
                <MDBModalHeader toggle={this.toggle_password}>Ganti Password</MDBModalHeader>
                <MDBModalBody>
                <input type='password' className='form-control '  ref='password_lama' placeholder='Masukan Password Lama ...'></input>
                <input type='password' className='form-control my-3' ref='password_baru' placeholder='Masukan Password Baru ...'></input>
                <input type='password' className='form-control ' ref='password_konfirm' placeholder='Konfirmasi Password Baru ...'></input>

                </MDBModalBody>
                <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle_password}>Close</MDBBtn>
                <MDBBtn color="primary" onClick={this.onSaveChangePassword}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

              {/* END CHANGE PASSWORD */}
              

            {/* CHANGE USERNAME */}
            <MDBModal isOpen={this.state.modal_username} toggle={this.toggle_username}>
                <MDBModalHeader toggle={this.toggle_username}>Ganti Username</MDBModalHeader>
                <MDBModalBody>
                <input type='text' className='form-control ' onChange={(e) => {var username_baru = e.target.value.toLowerCase();  this.setState({username_baru})}} value={this.state.username_baru} placeholder='Masukan Username Baru ...'></input>
                <input type='password' className='form-control my-3' ref='password_username' placeholder='Masukan Password'></input>

                </MDBModalBody>
                <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle_username}>Close</MDBBtn>
                <MDBBtn color="primary" onClick={this.onSaveChangeUsername}>Save changes</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            {/* END CHANGE USERNAME */}
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        users : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(MyProfile);
