

export const Rupiah = (num) => {
  
  var arr = new Intl.NumberFormat('in-IND', { style: 'currency', currency: 'IDR' }).format(num).split('')
  arr.splice(arr.length-3,3)
  return arr.join('')
}

export const formatRupiah = (angka, prefix) => {
  angka = String(angka)
  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		= number_string.split(','),
  sisa     		= split[0].length % 3,
  rupiah     		= split[0].substr(0, sisa),
  ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    var separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

export const handleOnChange = (e) => {
  var filter = e.target.value
  // filter = filter.toString().split('')
  if(filter.length > e.target.maxLength){
      alert('masuk')
  }
  else{
      this.setState({nisn : filter})
  }
  // alert(e.target.value)
}


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


// ucs-2 string to base64 encoded ascii
export const utoa = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
}
// base64 encoded ascii to ucs-2 string
export const atou = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
}