import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCardText, MDBCardImage ,MDBNav,MDBNavItem,MDBNavLink, MDBTabContent, MDBTabPane, MDBBadge} from 'mdbreact'
import { urlApi } from '../../support/constant'
import Axios from 'axios'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import LoadingPage from '../../components/LoadingPage'
import {Link, Redirect} from 'react-router-dom'
import DataEmpty from '../../components/dataEmplty'
import Moment from 'moment'
import Swal from 'sweetalert2'
import ChangeKamarKelasPondok from './ChangeKamarKelasPondok'


class UserDetail extends Component {
    state = {
        activeItem : '1',
        data : null,
        selectedFileImage : null,
        dataInfo : null
    }
    componentDidMount(){
        this.getDataUser()
        this.getUserInfo()
    }

    getUserInfo = () => {
        const id =  this.props.match.params.id
        console.log(id)
        Axios.get(urlApi + 'admin/getsantriinfokamarkelasandpondok/' + id, getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({dataInfo : res.data.data})
            } 
        })
        .catch((err) => {
            console.log(err)
        })
    }
    getDataUser = () => {
        var id = this.props.match.params.id
        Axios.get(urlApi + "admin/getsantribyid/" + id ,getHeaderAuth())
        .then((res) => {
            if(res.data.data.length > 0){
                this.setState({data : res.data.data[0]})
            }
            else{
                this.setState({data : []})
            }
            console.log(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
            activeItem: tab
            });
        }
    };
    onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
        console.log(event.target.files[0])
        if(event.target.files[0].size > 1050107 || event.target.files[0].type.split('/')[0] !== 'image' ){
            event.target.value = null
            return Swal.fire('File Error','Maksimal 1 mb','error')
        }else{
            this.setState({selectedFileImage : event.target.files[0]})
        }
    }
    }

    onSaveImage = (id) => {
        var fd = new FormData()
        var headers = {
          headers: 
          {'Content-Type': 'multipart/form-data'}
        }
        fd.append('edit_photo',this.state.selectedFileImage)
        console.log(this.state.selectedFileImage)

        Axios.post(urlApi  + 'admin/editphotosantri/' + id,fd,headers)
        .then((res) => {
            if(!res.data.error){
                this.setState({selectedFileImage : null})
                this.getDataUser()
                return Swal.fire('Edit Photo',res.data.message,'success')
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    postToAlumniHandler = (alasan) => {
        Axios.post(urlApi + 'alumni/' +this.props.match.params.id ,{alasan},getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                alert(res.data.message)
                this.getDataUser()
                this.getUserInfo()
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    onHapusDataClick = () => {
        Swal.fire({
            title: 'Alasan Hapus Data',
            input: 'select',
            inputOptions: {
              'Alumni' : 'Alumni',
              'Tidak Betah': 'Tidak Betah',
              'Pindah': 'Pindah',
              'Tanpa Kabar': 'Tanpa Kabar',
              'Kesalahan Data': 'Kesalahan Data',
              'Lainnya': 'Lainnya',
            },
            inputPlaceholder: 'required',
            showCancelButton: true,
            inputValidator: function (value) {
              return new Promise(function (resolve, reject) {
                if (value !== '') {
                  resolve();
                } else {
                  resolve('Pilih Alasan');
                }
              });
            }
          }).then((result) => {
            if (result.value) {
                if(result.value === 'Lainnya'){
                    Swal.fire({
                        title: "Tulis Alasan",
                        input : 'text',
                        showCancelButton: true,
                        closeOnConfirm: false,
                        animation: "slide-from-top",
                        inputPlaceholder: "Alasan Hapus Data",
                        inputValidator: function (value) {
                            return new Promise(function (resolve, reject) {
                              if (value !== '') {
                                resolve();
                              } else {
                                resolve('Alasan Tidak Boleh Kosong');
                              }
                            });
                          }
                      })
                      .then((result) => {
                        this.postToAlumniHandler(result.value)
                      
                      })
                }else{
                    this.postToAlumniHandler(result.value)

                }
            }
          });
    }
    render() {
        if(this.props.dataAdmin.username === '') return <Redirect to='/' />
        
        if(this.state.data === null || this.state.dataInfo === null){
            return(
                <LoadingPage />
            )
        }

        if(this.state.data.length === 0){
            return(
                <div className={'my-5 py-5'}>
                    <DataEmpty title='Data not Found' />
                </div>
            )
        }

        let {nama_lengkap,kelamin,tanggal_lahir,jenjang_pendidikan,anak_ke,jumlah_saudara,nisn,foto,is_verified} = this.state.data
        let {nama_ayah, no_kk,no_ktp_ayah,pekerjaan_ayah,penghasilan_ayah,pend_ayah,hidup_ayah,scan_kk,scan_ktp_ayah,is_deleted} = this.state.data
        let {nama_ibu,no_ktp_ibu,pekerjaan_ibu,penghasilan_ibu,pend_ibu,masih_hidup_ibu,alamat,nama_panggilan} = this.state.data
        let {madin,pondok,pondok_2,kamar} = this.state.dataInfo[0]
        return (
            <div className={this.props.dataAdmin.role === 1 ? 'container' :'my-5 py-5 container'}>
                {
                    is_verified ? 
                    null :
                    <div className='alert alert-danger'>
                        Santri Belum Terverifikasi
                    </div>

                }
                <div className="row">
                    <div className="col-md-4">
                        <MDBCard style={{minHeight : '50vh'}}>
                            <MDBCardHeader>
                                <MDBCardTitle>
                                    <div>
                                        {nama_lengkap} 
                                    </div>
                                    {
                                        is_verified === 1 && is_deleted === 0 &&
                                        <div style={{fontSize: "14px"}}>
                                            <MDBBadge pill color="default">{pondok ? pondok : pondok_2 ? pondok_2 : "belum dapat pondok"}</MDBBadge>
                                            {' '}
                                            <MDBBadge pill color="info">{kamar ? kamar : 'belum dapet kamar'}</MDBBadge>
                                            {' '}
                                            <MDBBadge pill color="success">{madin ? madin : 'belum dapet kelas'}</MDBBadge>
                                        </div>
                                    }

                                </MDBCardTitle>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <div className='row justify-content-center mb-3'>
                                <MDBCardImage style={{height : '200px'}} className="img-fluid" src={urlApi + foto} waves />
                                

                                </div>
                                <MDBCardText>
                                    <div>
                                    Nama Panggilan : {nama_panggilan}
                                    </div>
                                    <div>
                                    Nisn : {nisn}
                                    </div>
                                    <div>
                                    Jenis Kelamin : {kelamin}
                                    </div>
                                    <div>
                                    Tanggal Lahir : {Moment(tanggal_lahir).format('dddd, Do MMMM YYYY')}
                                    </div>
                                    <div>
                                    Jenjang Pendidikan : {jenjang_pendidikan}
                                    </div>
                                    <div>
                                    Anak Ke - {anak_ke} dari {jumlah_saudara} bersaudara
                                    </div>
                                        {
                                            Number(is_deleted) === 0 &&
                                            <div>
                                                <input onChange={this.onImageChange} type='file' accept="image/png, image/jpeg" ref='file' style={{display : 'none'}}/>
                                                <span style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}  onClick={() => this.refs.file.click()}>{ this.state.selectedFileImage ? this.state.selectedFileImage.name : 'Ubah Photo'}</span>
                                            </div>

                                        }
                                        {
                                            this.state.selectedFileImage !== null?
                                            <div>
                                                <span onClick={() => this.onSaveImage(this.state.data.id)} style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Save</span>
                                                <span onClick={() => this.setState({selectedFileImage : null})} style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}} className='ml-2'> Cancel</span>
                                            </div>
                                            :
                                            null

                                        }
                                    {
                                        this.props.dataAdmin.role > 1 &&
                                    <div>
                                        
                                        <div>
                                            {
                                                madin && is_deleted === 0 &&
                                                <Link style={{color:'inherit'}} to={'/raport-santri/' + this.state.data.id}>
                                                    <span style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Lihat Raport</span>
                                                </Link>
                                            }
                                        </div>
                                        {
                                        
                                        is_deleted === 0 &&
                                        <div>
                                            <Link style={{color:'inherit'}} to={'/edit-data-santri/' + this.state.data.id}>
                                                <span style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Edit Data Santri</span>
                                            </Link>
                                        </div>

                                        }
                                        {
                                            is_verified === 1 && is_deleted === 0&&
                                            <div>
                                                <ChangeKamarKelasPondok id_user ={this.props.match.params.id} />
                                            </div>
                                        }
                                        {
                                            is_deleted === 0&&
                                            <div>
                                                <span onClick={this.onHapusDataClick} style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Hapus Data / Alumni</span>
                                            </div>
                                        }
                                    </div>
                                     }
                                </MDBCardText>
                                
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="col-md-8 mt-3 mt-md-0">
                        <MDBCard style={{minHeight : '50vh'}}>
                            <MDBCardBody>
                                <MDBNav className="nav-tabs">
                                    <MDBNavItem>
                                        <MDBNavLink style={{color : 'black'}} to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                                        Data Ayah
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink style={{color : 'black'}} to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
                                        Data Ibu
                                        </MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink style={{color : 'black'}} to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
                                        Data Kontak
                                        </MDBNavLink>
                                    </MDBNavItem>
                                </MDBNav>
                                <MDBTabContent activeItem={this.state.activeItem}>
                                <MDBTabPane tabId="1" role="tabpanel">
                                    <MDBCardText className='mt-2'>
                                        <div>
                                            Nama Ayah = {nama_ayah}
                                        </div>
                                        <div>
                                            No KTP = {no_ktp_ayah}
                                        </div>
                                        <div>
                                            No KK = {no_kk}
                                        </div>
                                        <div>
                                            Pekerjaan Ayah = {pekerjaan_ayah}
                                        </div>
                                        <div>
                                            Pendidikan Ayah = {pend_ayah}
                                        </div>
                                        <div>
                                            Penghasilan Ayah = {penghasilan_ayah}
                                        </div>
                                        <div>
                                            Status Ayah = {hidup_ayah ===1 ? 'Masih Hidup' : "Sudah Meninggal"}
                                        </div>
                                        <div>
                                            <span onClick={ () => {
                                                Swal.fire({
                                                    imageUrl: urlApi + scan_kk,
                                                    imageWidth: '100%',
                                                    imageAlt: 'A tall image'
                                                  })
                                            }} style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Lihat Scan KK</span>
                                        </div>
                                        <div>
                                            <span onClick={
                                                () => {
                                                    Swal.fire({
                                                        imageUrl: urlApi + scan_ktp_ayah,
                                                        imageWidth: '100%',
                                                        imageAlt: 'A tall image'
                                                      })
                                                }
                                            } style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Lihat Scan KTP</span>
                                        </div>
                                    </MDBCardText>
                                
                                </MDBTabPane>
                                <MDBTabPane tabId="2" role="tabpanel">
                                    <MDBCardText className='mt-2'>
                                        <div>
                                            Nama ibu = {nama_ibu}
                                        </div>
                                        <div>
                                            No KTP = {no_ktp_ibu}
                                        </div>
                                        <div>
                                            Pekerjaan ibu = {pekerjaan_ibu}
                                        </div>
                                        <div>
                                            Pendidikan ibu = {pend_ibu}
                                        </div>
                                        <div>
                                            Penghasilan ibu = {penghasilan_ibu}
                                        </div>
                                        <div>
                                            {console.log(masih_hidup_ibu)}
                                            {console.log('jancuk')}
                                            Status ibu = {masih_hidup_ibu ===1 ? 'Masih Hidup' : "Sudah Meninggal"}
                                        </div>
                                        
                                    </MDBCardText>
                                
                                </MDBTabPane>
                                <MDBTabPane tabId="3" role="tabpanel">
                                    <MDBCardText className='mt-2'>
                                        <div>
                                            Propinsi = {this.state.data.propinsi}
                                        </div>
                                        <div>
                                            Kabupaten = {this.state.data.kabupaten}
                                        </div>
                                        <div>
                                            Kecamatan = {this.state.data.kecamatan}
                                        </div>
                                        <div>
                                            Kode Pos = {this.state.data.kode_pos}
                                        </div>
                                        <div>
                                            Alamat = {alamat}
                                        </div>
                                        <div>
                                            Nomor Ayah = {this.state.data.no_ayah}
                                        </div>
                                        <div>
                                            Nomor Ibu = {this.state.data.no_ibu}
                                        </div>
                                    </MDBCardText>
                                
                                </MDBTabPane>

                                </MDBTabContent>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(UserDetail);