import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div className="bg-dark">
                <div className="container py-5">
                    <div className="row mt-4">
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 text-white m-0">
                                LOCATION
                            </p>
                           
                            
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-white mt-1 m-0">
                                    Kompolek masjid Baiturrohman, Sirau, Kemranjen, Banyumas
                                </p>
                        
                           
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 text-white m-0">
                                DAFTAR ONLINE
                            </p>
                           
                                <p className="jamms-font-12 p-0 font-weight-light jamms-clickable-el text-white mt-3 m-0">
                                    <a target='_blank' rel="noopener noreferrer"  href="/register" className='jamms-link p-0 m-0'>
                                        Klik Disini
                                    </a>
                                </p>
                           
                             
                               
                            
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 text-white m-0">
                                CONTACT US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-white mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285842230413" className='jamms-link p-0 m-0'>
                                0858 4223 0413 (Kantor)
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-white mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285865254020" className='jamms-link p-0 m-0'>
                                0858 6525 4020 (Fakihudin)
                                </a>
                            </p>
                           
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 text-white m-0">
                                FOLLOW US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-white mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/jamms.id/" className='jamms-link p-0 m-0'>
                                Instagram
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-white mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://web.facebook.com/jamms.id" className='jamms-link p-0 m-0'>
                                Facebook
                                </a>
                            </p>
                            
                            
                        </div>
                        
                    </div>
                    <div className="text-center mt-5 jamms-font-12  text-white">
                        © 2020 PPNR Sirau. All Rights Reserved
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer
