import React,{useState,useEffect,useRef} from 'react'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import { getHeaderAuth } from '../../support/functions/GetCookies'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import Swal from 'sweetalert2'


function ChangeKamarKelasPondok (props) {
    const [data,setData] = useState({})
    const [isOpen,setIsOpen] = useState(false)
    const [pondokSelected,setPondokSelected] = useState(null)

    const madinRef = useRef()
    const kamarRef = useRef()

    useEffect(() => {
        Axios.get(urlApi + "admin/getallpondokkamarkelas", getHeaderAuth())
        .then((res) => {
            console.log(res)
            setData(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])


    const renderDataToOption = (data,item) => {
        if(data){
            let output = data.map((val) => {
                return(
                    <option value={val.id}>{val.nama}</option>
                )
            })
            if(item === 'pondok'){
                output.unshift(
                    <option disabled selected> Pilih Pondok</option>
                )
            }

            console.log(output)
            return output
        }
        return <option>Pilih {item}</option>
    }
    
    const onSaveBtnClick =() => {
        const id_madin = Number(madinRef.current.value)
        const id_kamar = Number(kamarRef.current.value)
        const id_user = Number(props.id_user)
        if(id_madin && id_kamar && id_user){
            console.log({id_user,id_madin,id_kamar})
            Axios.post(urlApi + 'admin/addusertokamarandkelas' , {id_user,id_madin,id_kamar},getHeaderAuth())
            .then((resp) => {
                if(!resp.data.error){
                    setIsOpen(!isOpen)
                    alert(resp.data.message)
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else{
            Swal.fire('Error Form')
        }
    }

    return(
        <div>
            <span onClick={() => setIsOpen(!isOpen)}  style={{fontWeight:'bolder',fontStyle:'italic' , textDecoration:'underline' , cursor:'pointer'}}>Edit Kelas Dan Kamar</span>
            <MDBModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <MDBModalHeader toggle={() => setIsOpen(!isOpen)}>
                    Pilih Pondok, Kamar dan Kelas
                </MDBModalHeader>
                <MDBModalBody>
                    <div className='px-3'>
                        <select onChange={(e) => setPondokSelected(e.target.value)} className='form-control mt-3' >
                            {renderDataToOption(data.pondok, 'pondok')}
                        </select>
                        <select ref={kamarRef} className='form-control mt-3' >
                            {renderDataToOption(pondokSelected ? data.kamar.filter((val) => val.id_pondok === Number(pondokSelected)) : null,'kamar')}
                        </select>
                        <select ref={madinRef} className='form-control mt-3' >
                            {renderDataToOption(pondokSelected ? data.madin.filter((val) => val.id_pondok === Number(pondokSelected)) : null,'madin')}
                        </select>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button onClick={onSaveBtnClick} className='btn btn-default'>Save </button>
                </MDBModalFooter>
            </MDBModal>
        </div>
    )
}

export default ChangeKamarKelasPondok;