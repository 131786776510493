import React, { Component } from 'react'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink,MDBDropdown,MDBDropdownToggle,MDBDropdownItem,MDBDropdownMenu} from 'mdbreact';
import {connect} from 'react-redux'
import {onLogout} from './../redux/action/loginAdmin'
import MyLink from './Link';
import { key } from '../support/constant';

// import BrandLogo from './../support/assets/images/logo/logo.png'
// const user = {
//     name : 'fikri',
//     roles : 2
// }

var text = 'Assalamualaikum , Saya pengin tanya tanya tentang Pondok'
text = text.replace(' ', '%20')
var nomor = '6285842230413'
  

class HomePage extends Component {
    state={
        collapse: false,
        isWideEnough: false,
        logout :false
    }
    onClick= () => {
        this.setState({
          collapse: !this.state.collapse,
        });
      }

      onLogoutClick =() => {
        if(window.confirm('Are You Sure Want to Logout?'))
        {
            localStorage.removeItem(key)
            this.props.onLogout()
            alert("You're Successfully logged out")
            this.setState({logout:true})
            window.location.reload()

        }
      }
    render() {
        return (
            <div>
                {
                       console.log(this.props.dataAdmin)

                }
                <MDBNavbar fixed="top" dark expand="md" scrolling className='my-navbar'>
                <MDBNavbarBrand href="/">
                    {/* <img src={BrandLogo} height='40px'/> */}

                    <strong className='brand'>PPNR SIRAU</strong>
                </MDBNavbarBrand>
                {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
                <MDBCollapse isOpen={this.state.collapse} navbar>
                   {
                       this.props.dataAdmin.role === 2?
                       
                       <MDBNavbarNav right>
                        <MDBNavItem active>
                            <MDBNavLink to="/admin" >Beranda</MDBNavLink>
                        </MDBNavItem>
                       <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <span className="mr-2">Data Management</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MyLink to="/admin/list-santri"><MDBDropdownItem>Manage Santri</MDBDropdownItem></MyLink>
                            <MyLink to="/admin/manage-kelas"><MDBDropdownItem>Manage Kelas</MDBDropdownItem></MyLink>
                            <MyLink to="/admin/manage-kamar"><MDBDropdownItem>Manage Kamar</MDBDropdownItem></MyLink>
                            <MyLink to="/admin/verifikasi-santri"><MDBDropdownItem>Verifikasi Santri Baru</MDBDropdownItem></MyLink>
                            
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem>
                        {/* <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <span className="mr-2">Academic</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MyLink to="/admin/manage-pelajaran"><MDBDropdownItem>Manage Pelajaran</MDBDropdownItem></MyLink>
                            <MyLink to="/admin/manage-ustadz"><MDBDropdownItem>Manage Ustadz</MDBDropdownItem></MyLink>
                            <MyLink to="/admin/manage-jadwal"><MDBDropdownItem>Manage Jadwal</MDBDropdownItem></MyLink>
                            
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem> */}
                        {/* <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <span className="mr-2">Raport</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MyLink to="/admin/lihat-raport"><MDBDropdownItem>Lihat Raport Santri</MDBDropdownItem></MyLink>                            
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem> */}
                        <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <span className="mr-2">Alumni</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MyLink to="/admin/daftar-alumni"><MDBDropdownItem>Lihat Daftar Alumni</MDBDropdownItem></MyLink>
                            {/* <MyLink to="/admin/manage-kamar"><MDBDropdownItem>Manage Ustadz</MDBDropdownItem></MyLink> */}
                            
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem>
                       
                        <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            <span className="mr-2">My Account</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MyLink to="/admin/my-profile"><MDBDropdownItem>My Profile</MDBDropdownItem></MyLink>
                            <MyLink> <span onClick={this.onLogoutClick}><MDBDropdownItem>Logout</MDBDropdownItem></span> </MyLink>                       
                            
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavItem>
                        </MDBNavbarNav>

                       :this.props.dataAdmin.role === 3 ?
                        <MDBNavbarNav right>
                        ` <MDBNavItem active>
                                <MDBNavLink to="/admin" >Beranda</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                <span className="mr-2">Menus</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                <MyLink to="/admin/lihat-jadwal"><MDBDropdownItem>Lihat Jadwal</MDBDropdownItem></MyLink>
                                <MyLink to="/admin/input-nilai"><MDBDropdownItem>Input Nilai</MDBDropdownItem></MyLink>
                                {/* <MyLink to="/admin/manage-kamar"><MDBDropdownItem>Manage Ustadz</MDBDropdownItem></MyLink> */}
                                
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            </MDBNavItem>
                           
                            <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                <span className="mr-2">My Account</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                <MyLink to="/admin/my-profile"><MDBDropdownItem>My Profile</MDBDropdownItem></MyLink>
                                <MyLink> <span onClick={this.onLogoutClick}><MDBDropdownItem>Logout</MDBDropdownItem></span> </MyLink>                       
                                
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            </MDBNavItem>`
                        </MDBNavbarNav>
                            
                       : Number(this.props.dataAdmin.role) === 1 ?
                        <MDBNavbarNav right>
                        <MDBNavItem>
                            <MDBNavLink to={"/santri/detail/" + this.props.dataAdmin.id} >Beranda</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to={"/edit-data-santri/" + this.props.dataAdmin.id} >Edit Data</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>                            
                                <MDBNavLink to={'/raport-santri/' + this.props.dataAdmin.id } >Lihat Raport</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>                            
                                <MDBNavLink to={'/santri/akun' } >Akun</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem onClick={() => this.onLogoutClick()}>       
                            <MDBNavLink>Logout</MDBNavLink>                     
                        </MDBNavItem>
                        </MDBNavbarNav>
                       :

                        <MDBNavbarNav right>
                        <MDBNavItem>
                            <MDBNavLink to="/" >Beranda</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>                            
                            <a className='nav-link' style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={`https://wa.me/${nomor}?text=${text}`}>                
                                Tanya Kami
                            </a>
                                {/* <MDBNavLink to='#' >Tanya Kami</MDBNavLink> */}
                        </MDBNavItem>
                    
                        <MDBNavItem>                            
                                <MDBNavLink to='/logadm' >Login</MDBNavLink>
                        </MDBNavItem>
                        </MDBNavbarNav>
                   }
                </MDBCollapse>
                </MDBNavbar>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps,{onLogout})(HomePage)
