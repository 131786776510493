
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {MDBCard,MDBCardBody,MDBCardTitle,MDBCardText} from 'mdbreact'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import Loading from '../../components/LoadingPage'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import MyLink from '../../components/Link';



function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}


class ManageJadwal extends Component {
    state = {
        data : null,
        isOpen : false,
        error : '',
        errorDesk : '',
        pondok : null,
        filtered: null
    }
    componentDidMount(){
        this.getData()
        this.getDataPondok()
    }

    getDataPondok = () => {
        Axios.get(urlApi+'academic/getallpondok',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({pondok:res.data.data})
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    getData = () => {
        Axios.get(urlApi+'academic/getallkelas',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data : res.data.data,filtered:res.data.data})
            }
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    onFilterChange =  () => {
        var id = this.refs.filter_pondok.value
        if(id === '-1' ){
            this.setState({filtered : this.state.data})
        }else{
            var data = this.state.data.filter((val) => {
                return Number(val.id_pondok) === Number(id)
            })
            this.setState({filtered : data})
        }
    }
    renderData = () => {
        return this.state.filtered.map((val) => {
            return(
                <div className='col-md-3 p-3'>
                <MDBCard>
                    {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
                    <MDBCardBody style={{height:170}} className='d-flex justify-content-between flex-column'>
                    <div style={{flex:1}}>
                        <MDBCardTitle>{val.nama}</MDBCardTitle>
                    </div>
                    <div style={{flex:3}}>
                        <MDBCardText>
                            <div>
                                <FontAwesomeIcon className='mr-2' color='darkgreen' icon={faMapMarkerAlt} /> 
                                <span>{val.pondok.split(' ')[2]}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' color='darkgreen' icon={faUser} /> 
                                <span>{val.pondok.split(' ')[1]}</span> 
                            </div>
                        </MDBCardText>
                    </div>
                    <div style={{flex:1}}>
                        <MyLink to={'/admin/manage-jadwal/' + val.id} ><button style={{fontSize:'10px'}} className='btn btn-info'> Atur Jadwal </button></MyLink>
                    </div>
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }


    render() {
        if(this.state.data === null || this.state.pondok === null){
            return(
                <Loading/>
            )
        }
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Manage Jadwal Kelas</h1> 
                    <MyLink to='/admin/manage-kelas'>
                        <input type='button' style={{fontSize:8}} className='btn btn-danger' value='tambah kelas' />
                    </MyLink>
                    <div className='col-4'>
                        <select ref='filter_pondok' onChange={this.onFilterChange} className='form-control'>
                            <option selected disabled>Filter By Pondok</option>
                            <option value = '-1'>All</option>                        
                            {
                                this.state.pondok.map((val) => {
                                    return(
                                        <option value={val.id} >{val.nama}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button> */}
                </div>
                <div className='row justify-content-center'>
                    {
                        this.renderData()
                    }
                    
                </div>


            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(ManageJadwal);