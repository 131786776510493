const INITIAL_STATE ={
    dataAdmin : {
        username : '',
        role : ''
    }
}

export default (state = INITIAL_STATE , action) => {
    switch(action.type){
        case 'LOGIN_ADMIN_SUCCESS':
            return {...state , dataAdmin : action.payload}
        case 'LOGOUT_ADMIN':
            return INITIAL_STATE
        default :
            return state
    }
}