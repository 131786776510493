// import 'semantic-ui-css/semantic.min.css'

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {MDBCard,MDBCardBody,MDBCardTitle,MDBCardText} from 'mdbreact'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import {Modal,ModalHeader,ModalBody,ModalFooter,Button} from 'reactstrap'
import Loading from '../../components/LoadingPage'
import { WordLimitation } from '../../support/functions/WordLimitation';
import DataEmpty from '../../components/dataEmplty';
function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}

// const data = [
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
//     {nama : 'Khulasoh Nurul Yakin',deskripsi : 'Ini untuk deskripsi pelajaran apa yaa guys'},
// ]

class ManagePelajaran extends Component {
    state = {
        data : null,
        isOpen : false,
        error : '',
        errorDesk : ''
    }
    componentDidMount(){
        this.getData()
    }

    getData = () => {
        Axios.get(urlApi+'academic/getallpelajaran',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data : res.data.data})
            }
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    toogle = () => {
        this.setState({isOpen : !this.state.isOpen})
    }
    onDeletePelajaran = (id) => {
        // alert(id)
        // alert(this.props.dataAdmin.id)
        if(window.confirm('Are You Sure Want to Delete?')){
            Axios.delete(urlApi + 'academic/deletepelajaran?id=' + id +'&id_user=' + this.props.dataAdmin.id,getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.getData()
                }
            })
        }
    }
    renderData = () => {
        if(this.state.data.length === 0){
            return (
                <DataEmpty title='Data Pelajaran Masih Kosong' /> 
            )
        }
        return this.state.data.map((val) => {
            return(
                <div className='col-md-3 p-3'>
                <MDBCard>
                    {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
                    <MDBCardBody style={{height:250}} className='d-flex justify-content-between flex-column'>
                    <div style={{flex:1}}>
                        <MDBCardTitle>{val.nama}</MDBCardTitle>
                    </div>
                    <div style={{flex:3}}>
                        <MDBCardText>
                        {WordLimitation(val.deskripsi,150)}
                        </MDBCardText>
                    </div>
                    <div style={{flex:1}}>
                        <button onClick={() => this.onDeletePelajaran(val.id)} style={{fontSize:'10px'}} className='btn btn-danger'> Delete </button>
                    </div>
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }

    onSubmit = () => {
        var nama = this.refs.nama.value
        var deskripsi = this.refs.deskripsi.value
        if(!nama){
            this.setState({error:'Nama tidak boleh kosong'})
            setTimeout(() => this.setState({error : ''}),2000)
        }
        if(!deskripsi){
            this.setState({errorDesk: 'Deskripsi tidak boleh kosong'})
            setTimeout(() => this.setState({errorDesk : ''}),2000)
        }
        if(deskripsi.split('').length > 150){
            this.setState({errorDesk:'Deskripsi tidak boleh lebih dari 150 karakter, ' + deskripsi.split('').length + ' / 150'})
            setTimeout(() => this.setState({errorDesk : ''}),2000)
        }
        if(nama && deskripsi && deskripsi.split('').length <= 150){
            Axios.post(urlApi+'academic/addpelajaran?id=' + this.props.dataAdmin.id , {nama,deskripsi},getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.getData()
                    this.toogle()
                }
            })
        }
    }

    render() {
        const closeBtn = <button className="close" onClick={() => this.setState({isOpen:false})}>&times;</button>;
        if(this.state.data === null){
            return(
                <Loading/>
            )
        }
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Manage Pelajaran</h1> 
                    <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button>
                </div>
                <div className='row justify-content-center'>
                    {
                        this.renderData()
                    }
                    
                </div>


                {/* MODAL TAMBAH DATA */}
                
                <Modal isOpen={this.state.isOpen} toggle={this.toogle}>
                    <ModalHeader toggle={this.toogle} close={closeBtn}>Tambah Pelajaran</ModalHeader>
                    <ModalBody>
                        <div className='p-3'> 
                            <input ref='nama' id='error' placeholder='Nama Pelajaran' type='text' className='form-control' />
                            <h6 for="error" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.error} </h6>
                            <textarea id='desk' style={{height:200}} ref='deskripsi' className='form-control' placeholder='Deskripsi Pelajaran (max : 150 character)'/>
                            <h6 for="desk" style={{fontSize:10}} className='text-danger mt-2 ml-2'>{this.state.errorDesk} </h6>                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.onSubmit}>Submit</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(ManagePelajaran);