import React, { Component } from 'react'
import {  Form, FormGroup, Input,Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import {saveDataAyah} from './../../redux/action/FormAction'
import Fade from 'react-reveal/Fade'
import Modal from './../../components/Modal'

class StepTwo extends Component {
    state={
        modalOpen : false,
        error : '',
        selectedFileImageKK : null,
        selectedFileImageKTP : null,
        kk : null,
        ktp : null
    }
    componentWillMount(){
        if(this.props.dataAyah){
            let {scan_kk , scan_ktp} = this.props.dataAyah
            this.setState({selectedFileImageKK : scan_kk, selectedFileImageKTP : scan_ktp})
        }
    }
    handleOnChange = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({kk : filter})
        }
        // alert(e.target.value)
    }

    handleOnChangektp = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({ktp : filter})
        }
        // alert(e.target.value)
    }

    onImageChangeKK = (event) => {
        if (event.target.files && event.target.files[0]) {
            console.log(event.target.files[0])
            if(event.target.files[0].size > 1050107 || event.target.files[0].type.split('/')[0] !== 'image' ){
                event.target.value = null
                this.setState({modalOpen : true , error : 'Pastikan File Berupa Image dan Kurang dari 1 mb'})
            }else{
                this.setState({selectedFileImageKK : event.target.files[0]})
            }
        }
    }
    onImageChangeKTP = (event) => {
        if (event.target.files && event.target.files[0]) {
            console.log(event.target.files[0])
            if(event.target.files[0].size > 1050107 || event.target.files[0].type.split('/')[0] !== 'image' ){
                event.target.value = null
                this.setState({modalOpen : true , error : 'Pastikan File Berupa Image dan Kurang dari 1 mb'})
            }else{
                this.setState({selectedFileImageKTP : event.target.files[0]})
            }
        }
    }

    onBtnNextClick = () => {
        var ref = 'nama kk ktp pekerjaan penghasilan pendidikan hidup'.split(' ')
        var check = true
        var dataAyah = {}
        ref.forEach((val) => {
            if(this.refs[val].refs[val + 'Inner'].value === '' || !this.state.selectedFileImageKK || !this.state.selectedFileImageKTP ){
                this.setState({modalOpen : true , error : 'Semua Form Harus Diisi'})
                check = false
            }else{
                dataAyah[val] = this.refs[val].refs[val + 'Inner'].value
            }
        })
        if(check){
            dataAyah.scan_kk = this.state.selectedFileImageKK
            dataAyah.scan_ktp = this.state.selectedFileImageKTP
            this.props.saveDataAyah(dataAyah)
            this.props.next()
        }
       
    }

    render() {
        return (
            <Fade big>
            <div className='d-flex justify-content-center'>
            <Modal 
                onClose={() => this.setState({modalOpen : false})} 
                title = 'Error' content ={this.state.error} 
                openModal ={this.state.modalOpen}/>
                <div className='col-md-6'>
                    <div className='d-flex justify-content-between p-4 top-info'>
                        <div>
                            <h3>Form 2 dari 4</h3>
                            <div> Data Ayah </div>
                        </div>
                        <div className='icon-user'>
                            <FontAwesomeIcon icon={faMale} size='4x'/>
                        </div>
                    </div>  
                    <div className='cont-small p-4'>
                        <Form>
                            <FormGroup>
                                <Input type="text" ref='nama' innerRef='namaInner' defaultValue={this.props.dataAyah ? this.props.dataAyah.nama : ''} placeholder='Nama Ayah'/>
                            </FormGroup>
                            <FormGroup>
                                <Input ref='kk' value={this.state.kk} onChange={this.handleOnChange} maxLength={16} innerRef='kkInner' defaultValue={this.props.dataAyah? this.props.dataAyah.kk : ''} type="number" placeholder='No KK'/>
                            </FormGroup>
                            <FormGroup>
                                <Input ref='ktp' value={this.state.ktp} onChange={this.handleOnChangektp} maxLength={16} innerRef='ktpInner' defaultValue={this.props.dataAyah? this.props.dataAyah.ktp : ''} type="number" placeholder='No KTP'/>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input ref='pekerjaan' defaultValue={this.props.dataAyah? this.props.dataAyah.pekerjaan : ''} innerRef='pekerjaanInner' type='select' >
                                        <option value=''>Pekerjaan Ayah</option>
                                        <option>PNS</option>
                                        <option>Guru</option>
                                        <option>Buruh</option>
                                        <option>Swasta</option>
                                        <option>Petani</option>
                                        <option>Lainnya</option>
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input ref='penghasilan' defaultValue={this.props.dataAyah? this.props.dataAyah.penghasilan : ''} innerRef='penghasilanInner' type='select' >
                                        <option value=''>Penghasilan Ayah</option>
                                        <option> Dibawah 500.000</option>
                                        <option> 500.000 - 1.000.000</option>
                                        <option> 1.000.000 - 2.900.000</option>
                                        <option> 3.000.000 - 4.900.000</option>
                                        <option> 5.000.000 - 10.000.000</option>
                                        <option> Di atas 10.000.000</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input ref='pendidikan' defaultValue={this.props.dataAyah? this.props.dataAyah.pendidikan : ''} innerRef='pendidikanInner' type='select' >
                                        <option value=''>Pendidikan Terakhir</option>
                                        <option>SD</option>
                                        <option>SMP</option>
                                        <option>SMA</option>
                                        <option>S1</option>
                                        <option>S2</option>
                                        <option>S3</option>
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input ref='hidup' defaultValue={this.props.dataAyah? this.props.dataAyah.hidup : ''} innerRef='hidupInner' type='select' >
                                        <option>Masih Hidup</option>
                                        <option>Sudah Meninggal</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input type='button' className='btn btn-primary' onClick={() => this.refs.kkImage.click()} value={this.state.selectedFileImageKK ? this.state.selectedFileImageKK.name : 'Upload Scan KK'}  />
                                    <input type='file' onChange={this.onImageChangeKK} style={{display: 'none'}} accept="image/png, image/jpeg"  ref='kkImage' />
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <input type='file' onChange={this.onImageChangeKTP} style={{display: 'none'}} accept="image/png, image/jpeg"  ref='ktpImage' />                                    
                                    <Input type='button' className='btn btn-primary' onClick={() => this.refs.ktpImage.click()} value={this.state.selectedFileImageKTP ? this.state.selectedFileImageKTP.name : 'Upload Scan KTP'} />                                    
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col xs="6" sm={4} md={4}>
                                    <Input type='button' className='btn btn-danger' onClick={this.props.back} value='Kembali' />
                                </Col>
                                <Col xs="6" sm={'4 offset-md-4 offset-sm-4'} md={'4 offset-md-4 offset-sm-4'}>
                                    <Input type='button' className='btn btn-success' onClick={this.onBtnNextClick} value='Lanjut' />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
            </Fade>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        dataAyah : state.form.dataAyah
    }
}


export default connect(mapStateToProps , {saveDataAyah})(StepTwo) 
