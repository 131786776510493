import React, { Component } from 'react'
import './donation.css'
import { formatRupiah } from '../../support/functions';
import { StatusPembayaran } from './StatusPembayaran';
import Swal from 'sweetalert2';
import IconSuc from './../../support/assets/images/Icons/icon-suc.svg'


export class OrderMoney extends Component {
    state = {
        anonim : false,
    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    renderListAngkatan = () => {
        var angkatans = [
            {tahun : 2018 , angkatan : 31},
            {tahun : 2019 , angkatan : 32},
            {tahun : 2020 , angkatan : 33} 
        ]

        
        return angkatans.map((val) => {
            return(
                <option value={val.angkatan}>
                    {val.angkatan} th. {val.tahun}
                </option>
            )
        })
    }

    donasiLagi = () => {
        window.location = '/alumni-mbangun-pprq'
    }
    onHubungiPengurusClick = () => {
        Swal.fire({
            title : "Hubungi Pengurus!!",
            input : 'select',
            inputOptions : {
                6285647978454 : "Hanan Ariq",
                6281584461819 : "Mustafidul",
                628999045895 : "Ahmad Munafidu Ahkam",
                6282226840026 : "Jamaludin Fikri"
            },
            showCancelButton : true,
            confirmButtonText : "Send",
            
        }).then((result) => {
            if(result.value){
                var text = "Assalamu'alaikum, Mau tanya soal donasi"
                window.open('https://wa.me/' + result.value + '?text='+text,'_blank')
            }
        })
    }
    render() {
        return (
            <div className='py-5 section-four-donation'>
                <div className="pt-5">
                    <div className="text-center font-weight-bold blue-color header-one-donation">
                        Data Transaksi
                    </div>
                    {
                        this.props.status_pembayaran === 3?
                        <div className="row mt-4 justify-content-center">
                        <div className="col-md-4 mt-3 mt-md-0 px-4">
                            <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                <div className="row justify-content-center">
                                    <p className='text-center desc-card-homepage mt-3'>
                                        <div className=" text-center font-weight-bold">
                                            Terimakasih {this.props.nama_donatur}
                                        </div>
                                        Donasi kamu Telah kami terima sebesar Rp. {this.props.nominal}
                                    </p>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <img src={IconSuc} alt="donation_success"/>
                                    </div>
                                </div>
                               
                                
                                <div className="row justify-content-center mt-4">
                                    
                                    <span onClick={this.donasiLagi} style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                        Donasi Lagi 
                                    </span>
                                </div>
                                <p className='text-center desc-card-homepage mt-3'>
                                    <div className=" text-center font-weight-bold">
                                        Jangan Lupa di Share !!
                                    </div>
                                    Biar lebih banyak yang berbuat baik seperti kamu
                                </p>
                            </div>
                        </div>
                    </div>
                        :
                        <div className="row mt-4 justify-content-center">
                            <div className="col-md-4 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <div className='alert alert-success'>
                                        Simpan id Transaksi mu untuk mengecek status donasi mu !!
                                    </div>
                                    <p className='desc-card-homepage mt-3'>
                                            <div className="font-weight-bold">
                                                ID Transaksi
                                            </div>
                                            {this.props.unique_id}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Nama Donatur
                                        </div>
                                        {this.props.nama_donatur}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Nominal Donasi
                                        </div>
                                        Rp. {formatRupiah(this.props.nominal)}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Metode Pembayaran
                                        </div>
                                        {this.props.metode}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Status Pembayaran
                                        </div>
                                        <div className='badge badge-primary'>
                                            {StatusPembayaran[this.props.status_pembayaran-1]}
                                        </div>
                                    </p>
                                    <p className='desc-card-homepage mt-3 alert alert-danger'>
                                        <div className="font-weight-bold">
                                            Apabila dalam waktu 1 x 24 Jam belum dikonfirmasi, Segera Hubungi Pengurus !!
                                        </div>
                                    
                                    </p>
                                    <div className="row justify-content-center mt-4">
                                        <span onClick={this.onHubungiPengurusClick} style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                            Hubungi Pengurus 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OrderMoney
