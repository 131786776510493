import React, { Component } from 'react'
import './../../support/css/admin.css'
import MyLink from '../../components/Link'
import Axios from 'axios'
import Loading from './../../components/LoadingPage'
import { urlApi } from '../../support/constant'
import UnderMaintenence from '../../components/UnderMaintenence'
import { getHeaderAuth } from '../../support/functions/GetCookies'


const siap = true
export default class ManageKelas extends Component {
    state={
        data : null,
        // unique_id : null
    }

    componentDidMount(){
        Axios.get(urlApi+'admin/getallmadin',getHeaderAuth())
        .then((res) =>{
            console.log(res.data.data)
            // const unique = [...new Set(res.data.data.map(item => item.id))]
            this.setState({data:res.data.data})
            
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderData = () => {
        return this.state.data.map((val)=> {
            return(
             <div className='col-md-6 container-card-kelas'>
                 <div className='label-data font-18 ml-3 mt-3 text-capitalize'>
                     {val.nama}
                 </div>
                 <div className='mx-3 mb-3 row justify-content-between'>
                     <span>
                         <span className='label-data'>Jumlah Kelas</span> 
                         <span className='data-label'> {
                         //    length_kelas/
                         val.jumlah_kelas
                         }</span>
                     </span>
                     <MyLink to={'/admin/detail-kelas/' + val.id}>
                         <button style={{fontSize:'10px'}} className='btn btn-success'> Lihat Detail Kelas </button>
                     </MyLink>
                 </div>
             </div>
            )
        })
    }


    render() {
        if(siap === false){
            return(
                <UnderMaintenence/>
            )
        }
        if(this.state.data === null){
            return(
                <Loading/>
            )
        }

    
        
        return (
            <div className='container'>
                 <div className='row justify-content-between'>
                    <h1 style={{display:'inline'}} className='judul-form'> Manage Kelas Madin</h1> 
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' disabled onClick={this.toggle_tambah_data}> Tambah Data </button> */}
                </div>
                <div className='row mt-5'>
                   {
                       this.renderData()
                   }
                </div>
            </div>
        )
    }
}
