const INITIAL_STATE ={
    dataSantri : null,
    dataAyah : null,
    dataIbu : null,
    dataAlamat : null
}

export default (state = INITIAL_STATE , action) => {
    switch(action.type){
        case 'DATA_SANTRI':
            return {...state , dataSantri : action.payload}
        case 'DATA_AYAH' :
            return{...state, dataAyah : action.payload}
        case 'DATA_IBU':
            return{...state,dataIbu : action.payload}
        case 'DATA_ALAMAT':
            return{...state, dataAlamat : action.payload}
        case 'RESET':
            return INITIAL_STATE
        default :
            return state
    }
}