import React, { Component } from 'react';
import './../../support/css/admin.css'

var text = 'Assalamualaikum , Saya mau tanya tentang Sekolah Formal di Sekitar PPRQ Sirau'
text = text.replace(' ', '%20')
var nomor = '6285643191642'


const data_dummies = [
    {sekolah : "SMA Ma'arif NU 1 Kemranjen",alamat : 'https://goo.gl/maps/qytbZHEXYjW7fUbC7',yayasan : false},
    {sekolah : 'SMK PPRQ Kemranjen' , alamat : 'https://goo.gl/maps/nviGc6qEHxtpWrRQA',yayasan : false},
    // {sekolah : "MA Ma'arif NU 1 Kemranjen" , alamat : 'https://goo.gl/maps/R9ZkRzJt7T76zNu7A',yayasan : false},
    {sekolah : "SMP Ma'arif NU 2 Kemranjen" , alamat : 'https://goo.gl/maps/AftpDwiCF4YA7cQL7',yayasan : false},
    // {sekolah : "MTS Ma'arif NU 1 Kemranjen" , alamat : 'https://goo.gl/maps/12q8UDgCrVDYE51v8',yayasan : false},
    {sekolah : "MI Fathul Ulum Sirau" , alamat : 'https://goo.gl/maps/MjrMXyenEguJiFTDA',yayasan : false},
]

class DaftarSekolah extends Component {
    state={tooltipOpen:false}
    toogle = () => {
        this.setState({tooltipOpen : !this.state.tooltipOpen})
    }
    render() {
        return (
            <div className='container py-5 my-5'>
               <h1 style={{display:'inline'}} className='judul-form'> Daftar Sekolah Formal Sekitar PPRQ Sirau</h1> 
           <div className='row mt-5'>
              {
                  data_dummies.map((val,index)=> {
                      return(
                       <div className='col-md-6 container-card-kelas mt-3'>
                           <div className='label-data font-18 ml-3 mt-3'>
                               {val.sekolah}
                               {
                                   val.yayasan ?
                                   <span class="d-inline-block" placement='top' tabindex="0" data-toggle="tooltip" title="Sekolah Ini Satu Yayasan Dengan PPRQ">

                                           <span style={{cursor:'pointer'}} className='ml-4 badge badge-pill badge-warning' id={`yayasan${index}`}> Satu Yayasan</span>
                                       
                                   </span>
                                    :
                                    null
                               }
                           </div>
                           <div className='mx-3 mb-3 row justify-content-between'>
                               <span>
                               <a className='nav-link' style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={val.alamat}>                
                                   <span className='label-data'>Lihat Di Map </span>
                               </a>                                
                               </span>
                                <a className='nav-link' style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={`https://wa.me/${nomor}?text=${text}`}>                
                                   <button style={{fontSize:'10px'}} className='btn btn-success'> Tanya Detail </button>
                                </a>
                           </div>
                       </div>
                      )
                  })
              }
           </div>
       </div>
        );
    }
}

export default DaftarSekolah;