import React from 'react';
import {  Modal, ModalHeader, ModalBody,ModalFooter } from 'reactstrap';

class ModalExample extends React.Component {

    state= {
        modal : null
    }
    componentWillReceiveProps(newProps){
        if(newProps.openModal){
            this.setState({modal : true})
        }
    }
    toggle = () => {
        this.setState({modal : false})
        this.props.onClose()
    }

  render() {
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;

      return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader style={{border : 'none'}} toggle={this.toggle} close={closeBtn}></ModalHeader>
          <ModalBody>
              <div className='d-flex flex-column align-items-center'>
                  <div className='garis-bawah-pendek'></div>
                    <h1 className='mt-4'>{this.props.title}</h1>
                    <p className='mt-1'>{this.props.content}</p>

              </div>
          </ModalBody>
          <ModalFooter style={{border:'none' ,display:'flex' , flexDirection:'row' , justifyContent:'center'}} className='pb-5'>
            <input type='button' className='mybtn-setengah-gold' value='Close' onClick={this.toggle} />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;










