import { key } from '../constant';
let cookie;



export const getCookie = () => {
    if(cookie){
        console.log('tidak ambil')
        console.log(cookie)
        return cookie
    }else{
        console.log('Ambil Cookie')
        let {key} = require('../constant/index')
        cookie= localStorage.getItem(key)
        return cookie
    }
}


export const getHeaderAuth = () => {
    getCookie()
    if(cookie){
        const headers = {
            headers: { 
                'Authorization': `Bearer ${cookie}`,
            }
        }
        return headers
    }
}

export const resetCookie = () => {
    if(cookie){
        cookie = localStorage.getItem(key)
    }
}