import React, { Component } from 'react'
import './donation.css'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import Swal from 'sweetalert2'
import Loading from './../../components/LoadingPage'
import { formatRupiah } from '../../support/functions'
import UserHavePaidComp from './orderMoneyCreated'
import DataEmpty from '../../components/dataEmplty'

export class Pay extends Component {
    state = {
        data : null,
        image : null,
        loadingBtn : false,
        havePaid : false
    }
    componentDidMount(){
        this.getTransactionByUniqueId()
    }

    getTransactionByUniqueId = () => {
        var id = this.props.match.params.unique_id
        Axios.get(urlApi + 'donation/gettransaction/'  + id)
        .then((res) => {
            if(!res.data.error){
                this.setState({data : res.data.data})
            }else{
                Swal.fire('error',res.data.message,'error')
            }
        })
        .catch((err) => {
            Swal.fire('error',err.message,'error')
        })
    }

    onUploadBuktiClick = () => {
        this.setState({loadingBtn : true})
        if(this.state.image === null){
            Swal.fire('error','Pilih image terlebih dahulu')
            this.setState({loadingBtn : false})
        }else{
            let fd = new FormData()
            var headers = {
                headers: 
                {'Content-Type': 'multipart/form-data'}
            }
            fd.append('payment_proof',this.state.image)
            Axios.post(urlApi + 'donation/upload-bukti-pembayaran/' + this.props.match.params.unique_id,fd,headers)
            .then((res) => {
                if(!res.data.error){
                    Swal.fire('success',res.data.message)
                    .then((val) => {
                        localStorage.setItem('paid',JSON.stringify(this.props.match.params.unique_id))
                        window.location.reload(true)
                    })
                }
            })
            .catch((err) => {
                Swal.fire('error',err.message,'error')
                this.setState({loadingBtn : false})
            })
        }
        
    }
    render() {
        if(this.state.data === null){
            return(
                <div className='section-four-donation'>
                    <Loading />
                </div>

            )
        }
        if(this.state.data === undefined){
            return(
                <div className='py-5 my-5'>
                    <div className="my-5">
                    <DataEmpty title='Data Not Found'/>

                    </div>
                </div>
            )
        }
        var paid = localStorage.getItem('paid')
        if(this.state.data.status_pembayaran !== 1 || JSON.parse(paid) === this.props.match.params.unique_id ){
            return(
                <UserHavePaidComp {...this.state.data}/>
            )
        }
        return (
            <div className='py-5  section-four-donation'>
                <div className="pt-5 ">
                    <div className="text-center font-weight-bold blue-color header-one-donation">
                        Instruksi Pembayaran
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mt-4 ">
                            <div className="col-md-6 mt-3 mt-md-0 px-4">
                                <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                    <div className='alert alert-success'>
                                        Simpan id Transaksi mu untuk mengecek status donasi mu !!
                                    </div>
                                    <p className='desc-card-homepage mt-3'>
                                            <div className="font-weight-bold">
                                                ID Transaksi
                                            </div>
                                        {this.state.data.unique_id}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Nama Alumni
                                        </div>
                                       {this.state.data.nama_donatur}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Jumlah Donasi
                                        </div>
                                        Rp. {formatRupiah(this.state.data.nominal)}
                                    </p>
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Pembayaran dilakukan ke
                                        </div>
                                        nomor =  {this.state.data.nomor_rekening} <br/>
                                        a/n =  {this.state.data.nama_rekening}<br/>
                                        metode = {this.state.data.metode}
                                    </p>
                                   
                                    <p className='desc-card-homepage mt-3'>
                                        <div className="font-weight-bold">
                                            Upload Bukti
                                        </div>
                                        <input onChange={(e) => this.setState({image : e.target.files[0]})} accept="image/*" type="file" className='form-control' ref={(el) => this.namaRek = el}/>
                                        
                                    </p>
                                    <div className="row justify-content-center mt-4">
                                        {
                                            this.state.loadingBtn ? 
                                            <span  style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                                loading ...
                                            </span>
                                            :
                                            <span onClick={this.onUploadBuktiClick} style={{fontSize:'16px'}} className='btn-circle-donation px-5 py-2'>
                                                Upload Bukti Pembayaran
                                            </span>
                                        }
                                    </div>
                                   
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}


export default Pay
