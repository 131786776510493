import React, { Component } from 'react';
import {Switch,Route,Redirect} from 'react-router-dom'
import ManageKelas from './ManageKelas'
import ManageKamar from './ManageKamar'
import Statistic from './Statistic';
import PageNotFound from './../../components/NotFound'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import DetailKamar from './DetailKamar';
import DetailKelas from './DetailKelas';
import MyProfile from './MyProfile';
import ManagePelajaran from '../academic/ManagePelajaran';
import ManageUstadz from '../academic/ManageUstadz';
import ManageJadwal from '../academic/ManageJadwal';
import ManageJadwalDetail from '../academic/ManageJadwalDetail';
import LihatJadwal from '../raport/LihatJadwal';
import InputNilai from '../raport/InputNilai';
import InputNilaiDetail from '../raport/InputNilaiDetail';
import VerifySantri from './VerifySantri';
import StatisticsRaport from './../hasilRaport/statistics'
import { LihatRaport } from '../hasilRaport/LihatRaport';
import { LihatRaportDetail } from '../hasilRaport/LihatRaportDetail';
import ListSantri from './ListSantri';
import DaftarAlumni from '../alumni/DaftarAlumni';
import {Helmet} from 'react-helmet';
import { StatistikDonasi } from './donation/statistik';
// import Fade from 'react-reveal/Fade'
class Dashboard extends Component {
    state ={
        welcome : true
    }
    render() {

        if(this.props.dataAdmin.role < 2){
            return(
                <Redirect to='/' />
            )
        }
        return (
            <div className='container mt-5 pt-5 pb-5 mb-5 min-vh-100'>
                <Helmet>
                    <title>
                        Menu Admin App
                    </title>
                </Helmet>
                {
                    this.state.welcome === false ? null:
                    this.props.dataAdmin.username !== '' ?
                    <div className="alert alert-success" role="alert">
                        <div className='d-flex justify-content-between'>
                            <div>
                                Selamat Datang {this.props.dataAdmin.jabatan} {this.props.dataAdmin.nama_lengkap} 
                            </div>
                            <div>
                                <FontAwesomeIcon style={{cursor:'pointer'}} onClick={() => this.setState({welcome:false})} icon={faTimesCircle}  />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                    <Switch>
                        {
                            this.props.dataAdmin.role === 2?
                            <Switch>
                                {/* <Route path='/admin/manage-santri' component={ManageSantri} /> */}
                                <Route path='/admin/verifikasi-santri' component={VerifySantri} />
                                <Route path='/admin/lihat-raport' component={LihatRaport} />
                                <Route path='/admin/list-santri' component={ListSantri} />
                                <Route path='/admin/lihat-raport-detail/:id' component={LihatRaportDetail} />
                                <Route path='/admin/manage-kelas'  component={ManageKelas} />
                                <Route path='/admin/manage-kamar'  component={ManageKamar} />
                                <Route path='/admin/manage-pelajaran'  component={ManagePelajaran} />
                                <Route path='/admin/manage-ustadz'  component={ManageUstadz} />
                                <Route path='/admin/manage-jadwal/:id'  component={ManageJadwalDetail} />
                                <Route path='/admin/manage-jadwal'  component={ManageJadwal} />
                                <Route path='/admin/detail-kamar/:id'  component={DetailKamar} />
                                <Route path='/admin/detail-kelas/:id'  component={DetailKelas} />
                                <Route path='/admin/my-profile'  component={MyProfile} />
                                <Route path='/admin/statistics-raport'  component={StatisticsRaport} />
                                <Route path='/admin/daftar-alumni'  component={DaftarAlumni} />
                                <Route path='/admin/donasi'  component={StatistikDonasi} />
                                <Route path='/admin' exact  component={Statistic} />
                                <Route path='*' component={PageNotFound}/>

                            </Switch>
                            : this.props.dataAdmin.role === 3?
                            <Switch>
                                <Route path='/admin/lihat-jadwal'  component={LihatJadwal} />
                                <Route path='/admin/input-nilai'  component={InputNilai} />
                                <Route path='/admin/my-profile'  component={MyProfile} />
                                <Route path='/admin/input-nilai-detail'  component={InputNilaiDetail} />
                                <Route path='/admin' exact  component={Statistic} />
                                <Route path='*' component={PageNotFound}/>

                            </Switch>
                            : null
                        }
                        
                    </Switch>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(Dashboard);