import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "mdbreact/dist/css/mdb.css";
import './support/css/bootstrap.css'
// import './App.css'
import './support/css/Jumbotron.css'
import './support/css/utils.css'
// import 'semantic-ui-css/semantic.min.css'
import App from './App'
import ScrollToTop from './components/scrollTopTop'
import { BrowserRouter } from 'react-router-dom'
import { createStore,applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import {unregister } from './serviceWorker';
import Reducers from './redux/reducer'

const store = createStore(Reducers, {} ,applyMiddleware(ReduxThunk))
if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
  }
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>
    </Provider>

, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
