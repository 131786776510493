import React, { Component } from 'react'
import './../../support/css/landingpage.css'

import Logo from './../../support/assets/images/logo/logo.jpg'

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faUser } from '@fortawesome/free-solid-svg-icons'
import Gallery from './Gallery';
import Map from './MapSection'
import { Helmet } from 'react-helmet'


export class LandingPage extends Component {
    state = {
        activePackage : 0
    }
    render() {
        return (
            
            <div>
                 <Helmet>
                    <title>
                        Ponpes Nuururohman (PPNR) Sirau Banyumas
                    </title>
                </Helmet>
                {/* Jumbotron */}
                <div className='jamms-bg-secondary jamms-jumbotron p-5'>
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12 text-center ">
                                <div className='bg-white d-inline-block p-4 jamms-shadow rounded-circle'>
                                    <img src={Logo} alt='logo' width='100px' />
                                </div>
                                <h1 className='jamms-light jamms-font-second jamms-font-40 m-0 p-0 mt-4'>Ponpes Nuurrohman Sirau</h1>
                                <p className='jamms-light m-0 p-0'>Daftra Online Disini !!</p>
                                    <Link to='/register' className='jamms-link'>
                                    <span className='d-inline-block mt-4 jamms-font-12 jamms-bg-main jamms-clickable-el text-white py-2 px-3 rounded jamms-shadow'> 
                                        Daftar Sekarang
                                        <FontAwesomeIcon icon={faUser} className='ml-2' />
                                    </span>
                                    </Link>
                                   
                            </div>
                        </div>
                    </div>
                </div>



                {/* Subsrciption Email & Login */}
                <Gallery />
                <Map />

                <div className="jamms-bg-light pt-0 pb-5 px-3">
                    <div className="container jamms-banner-subscription text-center jamms-light py-5">
                        <div className="row justify-content-center">
                            <div className="col-8 col-md-4">
                                <h3 className='p-0 m-0'>Ingin menjadi bagian dari kami?</h3>
                                <p className='p-0 m-0 font-weight-light mt-3'>Yuk !! Daftar sekarang juga !!</p>
                                <Link to='/register' className='jamms-link'>
                                    <button className='jamms-button-light jamms-clickable-el jamms-shadow mt-4'> Daftar disini</button>
                                </Link>
                                


                            </div>
                        </div>
                    </div>
                </div>

               
                {/* Footer */}

                

            </div>
        )
    }
}

export default LandingPage