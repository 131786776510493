import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
export default class LoadingPage extends Component {
    state = {
        tooLong : false
    }

    componentDidMount(){
        this.timer = setTimeout(() => this.setState({tooLong : true}), 10000)
    }

    componentWillUnmount(){
        clearInterval(this.timer)
    }

    
    render() {
        return (
            <div style={{height:'80vh'}} className='container mt-5 pt-5 mb-5 pb-5'>
                <div  className='row justify-content-center'>
                    <div className='col-md-12 text-center mt-5 pt-5'>
                    {
                        this.state.tooLong ? 
                        <h4>Sorry Something Wrong, Try Again</h4>
                        :
                        <Loader
                            type="ThreeDots"
                            color="#006b53"
                            height={50}
                            width={50}
                            timeout={3000} //3 secs

                        />}
                    </div>
                    <div className='col-md-12 text-center mt-1'>
                        <p className='font-black-natural-dark'> { this.state.tooLong ? '' : 'Please Wait...'}
                        </p>
                    </div>

                </div>
            </div>
        )
    }
}
