import React, { Component } from 'react';
import { connect } from 'react-redux';
// import UnderMaintenence from '../../components/UnderMaintenence';
import {MDBCard,MDBCardBody,MDBCardTitle,MDBCardText} from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faPen, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import DataEmpty from '../../components/dataEmplty';

function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}



class LihatJadwal extends Component {
    state ={
        data : null
    }
    componentDidMount(){
        this.getData()
    }

    getData =() => {
        Axios.get(urlApi + 'raport/getjadwalustadz?id_ustadz=' + this.props.dataAdmin.id,getHeaderAuth())
        .then((res) => {
            this.setState({data : res.data.data})
            console.log(res.data.data)
        }).catch((err) => {
            console.log(err)
            alert('Network Error, Try Again')
        })
    }

    renderData = () => {
        if(this.state.data.length === 0){
            return(
                <DataEmpty title='Belum Ada Jadwal Mengajar' />
            )
        }
        return this.state.data.map((val) => {
            return(
                <div className='col-md-3 p-3'>
                <MDBCard>
                    {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
                    <MDBCardBody style={{height:180}} className='d-flex justify-content-between flex-column'>
                    <div style={{flex:1}}>
                        <MDBCardTitle style={{textTransform:'capitalize'}}> {val.hari}</MDBCardTitle>
                    </div>
                    <div style={{flex:3}}>
                        <MDBCardText>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faClipboardCheck} color='green' /> 
                                <span>{val.kelas}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faPen} color='green' /> 
                                <span>{val.pelajaran}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faClock} color='green' /> 
                                <span>{val.waktu === 1 ? 'Isya' : 'Maghrib'}</span> 
                            </div>
                            <div>
                                <FontAwesomeIcon className='mr-2' icon={faMapMarkerAlt} color='green' /> 
                                <span>{val.pondok === 1 ? 'Putra Sirau' : val.pondok === 2 ? 'Putri Sirau' : val.pondok === 3 ? 'Putra Ciwarak' : val.pondok === 4? "Putri Ciwarak" : null}</span> 
                            </div>
                        </MDBCardText>
                    </div>
                    {/* <div style={{flex:1}}>
                        <button onClick={() => this.onDeleteUstadz(val.id)} style={{fontSize:'10px'}} className='btn btn-danger'> Delete </button>
                    </div> */}
                    </MDBCardBody>
                </MDBCard>
                </div>
            )
        })
    }

    render() {
        if(this.state.data === null){
            return(
                <LoadingPage/>
            )
        }
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h1 style={{display:'inline'}} className='judul-form'> Lihat Jadwal</h1> 
                    {/* <button style={{fontSize:'10px'}} className='btn btn-danger' onClick={this.toogle}> Tambah Data </button> */}
                </div>
                <div className='row justify-content-center'>
                    {
                        this.renderData()
                    }
                    
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(LihatJadwal);