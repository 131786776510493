import React, { Component } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import LoadingPage from '../../components/LoadingPage';
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import {Modal,ModalHeader,ModalBody,ModalFooter,Button} from 'reactstrap'
import {connect} from 'react-redux'
import MyLink from '../../components/Link';

// const data = [
//     {hari:'Senin' , pelajaran : 'Tarikh Islam' , waktu : 'Maghrib', pengajar : 'Jume'},
//     {hari:'Senin' , pelajaran : 'Tarikh Islam 2' , waktu : 'Isya', pengajar : 'Jume'},
//     {hari:'Selasa' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Selasa' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
//     {hari:'Rabu' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Rabu' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
//     {hari:'Kamis' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Kamis' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
//     {hari:'Jumat' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Jumat' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
//     {hari:'Sabtu' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Sabtu' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
//     {hari:'Minggu' , pelajaran : '-' , waktu : 'maghrib', pengajar : '-'},
//     {hari:'Minggu' , pelajaran : '-' , waktu : 'Isya', pengajar : '-'},
// ]

class ManageJadwalDetail extends Component {
    state = {
        waktu : null,
        jadwal : null,
        data_ustadz : null,
        data_pelajaran: null,
        selected_edit : 0,
        isOpen : false ,
        is_edit : false,
        data_selected : null,
        id_jadwal : null
        // data_gabung : []
    }
    componentDidMount(){
        this.getAllUstadz()
        this.getAllDay()
        this.getAllPelajaran()
        var id = this.props.match.params.id
        if(id){
            this.getJadwalByKelas(id)
        }
        
    }

    getAllUstadz  = () => {
        Axios.get(urlApi + 'academic/getallustadz',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data_ustadz : res.data.data})
            }
        })
    }


    getAllPelajaran  = () => {
        Axios.get(urlApi + 'academic/getallpelajaran',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({data_pelajaran : res.data.data})
            }
        })
    }

    getJadwalByKelas = (id_kelas) => {
        Axios.get(urlApi+'academic/getjadwalbykelas?id_kelas=' + id_kelas,getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({jadwal : res.data.data})
            }
        })
    }
    getAllDay = () => {
        Axios.get(urlApi + 'academic/getallday',getHeaderAuth())
        .then((res) => {
            if(!res.data.error){
                this.setState({waktu:res.data.data})
            }
        })
    }

    toogle =() => {
        this.setState({isOpen : !this.state.isOpen,selected_edit:0,is_edit:false,editjadwal:null})
    }

    select = (id,is_edit,id_jadwal=null) => {
        this.setState({isOpen:true,selected_edit : id,is_edit,id_jadwal})
    }

    onSubmitAdd = () => {
        var id_ustadz = this.refs.ustadz.value
        var id_pelajaran = this.refs.pelajaran.value
        var id_kelas = this.props.match.params.id
        var id_waktu = this.state.selected_edit
        if(id_ustadz && id_pelajaran&&id_kelas&&id_waktu){
            Axios.post(urlApi+'academic/addjadwal?id=' + this.props.dataAdmin.id,{id_ustadz,id_pelajaran,id_kelas,id_waktu},getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.componentDidMount()
                    this.toogle()
                }else{
                    alert('Something Error, Try Again')
                }
            })
            .catch((err) => {
                console.log(err)
                alert(err.message)
            })
        }else{
            alert('Data Belum Lengkap')
        }
    }

    onSubmitEdit = () => {
        // alert(this.state.id_jadwal)
        console.log(this.state.id_jadwal)
        var id_ustadz = this.refs.ustadz.value
        var id_pelajaran = this.refs.pelajaran.value
        var id_jadwal = this.state.id_jadwal.id_jadwal
        // alert(id_jadwal)
        if(id_ustadz && id_pelajaran&&id_jadwal){
            Axios.post(urlApi+'academic/editjadwal?id=' + this.props.dataAdmin.id,{id_ustadz,id_pelajaran,id_jadwal},getHeaderAuth())
            .then((res) => {
                if(!res.data.error){
                    alert(res.data.message)
                    this.componentDidMount()
                    this.toogle()
                }else{
                    alert('Something Error, Try Again')
                }
            })
            .catch((err) => {
                console.log(err)
                alert(err.message)
            })
        }else{
            alert('Data Belum Lengkap')
        }
    }

    // {hari: "Senin"
    // id: 2
    // id_hari: 4
    // kelas: "Ula"
    // pelajaran: "'Aqidatul Awam"
    // pengajar: "jumaeni ali"
    // waktu: 1}

    // {id: 1
    // nama: "Ahad"
    // waktu: 0}
    renderData = () => {
        var {jadwal,waktu} = this.state        
        var new_data = []
        waktu.forEach((day) => {
            var new_obj = {}
            var gabung = false
            jadwal.forEach((schedule) => {
                if(Number(day.id) === Number(schedule.id_hari)){
                    new_obj.id = day.id
                    new_obj.hari = day.nama
                    new_obj.pelajaran = schedule.pelajaran
                    new_obj.waktu = day.waktu === 0? 'Maghrib' : 'Isya'
                    new_obj.pengajar = schedule.pengajar
                    new_obj.id_jadwal = schedule.id
                    new_obj.id_pelajaran = schedule.id_pelajaran
                    new_obj.id_ustadz = schedule.id_ustadz
                    new_obj.jabatan = schedule.jabatan
                    gabung = true
                }
                
            })
            if(!gabung){
                new_obj.id = day.id
                new_obj.hari = day.nama
                new_obj.pelajaran = '-'
                new_obj.waktu = day.waktu === 0? 'Maghrib' : 'Isya'
                new_obj.pengajar = '-'
            }
            new_data.push(new_obj)
            // if(val.hari)
        })

        console.log(jadwal)
        console.log(new_data)
        console.log(waktu)
        // this.setState({data_gabung :new_data})
        return new_data.map((val,index) => {
            if(index %2 === 0){
                return(
                    <tr>
                        <td style={{verticalAlign:'middle',textAlign:'center'}} rowSpan='2'>{index +1}</td>
                        <td  style={{verticalAlign:'middle',textAlign:'center'}}  rowSpan='2'>{val.hari}</td>
                        <td>{val.waktu}</td>
                        <td>{val.pelajaran}</td>
                        <td style={{textTransform:'capitalize'}}>{val.jabatan} {val.pengajar}</td>
                        <td>
                            {
                                val.pelajaran !== '-' ? 
                                <input type='button' onClick={() => this.select(val.id,true,val)} className='btn btn-warning' value='edit' style={{fontSize:12}}/>
                                :
                                <input type='button' onClick={() => this.select(val.id,false)} className='btn btn-info' value='add' style={{fontSize:12}}/>
                            }
                        </td>
                    </tr>
                )
            }else{
                return(
                    <tr>
                        <td>{val.waktu}</td>
                        <td>{val.pelajaran}</td>
                        <td style={{textTransform:'capitalize'}}>{val.jabatan} {val.pengajar}</td>
                        <td>
                            {
                                val.pelajaran !== '-' ? 
                                <input type='button' onClick={() => this.select(val.id,true,val)} className='btn btn-warning' value='edit' style={{fontSize:12}}/>
                                :
                                <input type='button' onClick={() => this.select(val.id,false)} className='btn btn-info' value='add' style={{fontSize:12}}/>
                            }
                        </td>

                    </tr>
                )
            }
        })
    }  
  render() {
    if(this.state.waktu === null || this.state.jadwal ===null || this.state.data_pelajaran === null || this.state.data_ustadz === null){
        return(
            <LoadingPage/>
        )
    }
    const closeBtn = <button className="close" onClick={() => this.setState({isOpen:false})}>&times;</button>;
    return (
        
        <div>
            <MDBTable bordered responsive>
            <MDBTableHead>
            <tr>
                <th>#</th>
                <th>Hari</th>
                <th>Waktu</th>
                <th>Pelajaran</th>
                <th>Pengajar</th>
                <th>Edit</th>
            </tr>
            </MDBTableHead>
            <MDBTableBody>
            
            {this.renderData()}
            </MDBTableBody>
        </MDBTable>


        {/* MODAL EDIT */}
        <Modal isOpen={this.state.isOpen} toggle={() => this.toogle()}>
            <ModalHeader toggle={() => this.toogle()} close={closeBtn}>
                {this.state.is_edit ? "Edit Jadwal" : "Add Jadwal"}
                <span style={{fontSize:11,color:'grey',fontStyle:'italic'}}> {this.state.selected_edit !== 0? `${this.state.waktu[this.state.selected_edit-1].nama} || ${this.state.waktu[this.state.selected_edit-1].waktu === 0 ? 'Maghrib' : "Isya"} ` : null} </span>
            </ModalHeader>
            <ModalBody>
                <div className='p-3'> 
            
                    <select defaultValue={this.state.is_edit ? this.state.id_jadwal.id_pelajaran : null} ref='pelajaran' className='form-control'>
                        <option selected disabled value=''>Pilih Pelajaran</option>
                        {
                            this.state.data_pelajaran.map((val) =>{
                                return(
                                    <option value={val.id}>{val.nama}</option>
                                )
                            })
                        }
                    </select>
                    <select ref='ustadz'  defaultValue={this.state.is_edit ? this.state.id_jadwal.id_ustadz : null} className='form-control mt-3'>
                        <option selected disabled value=''>Pilih Ustadz</option>
                        {
                            this.state.data_ustadz.map((val) =>{
                                return(
                                    <option value={val.id}>{val.nama_lengkap}</option>
                                )
                            })
                        }
                    </select>           
                </div>
            </ModalBody>
            <ModalFooter>
            <MyLink to='/admin/manage-ustadz'>
            <Button color="info">Tambah Ustadz</Button>{' '}
            </MyLink>            
            <MyLink to='/admin/manage-pelajaran'>
                <Button color="info">Tambah Pelajaran</Button>{' '}            

            </MyLink>
            <Button color="success" onClick={this.state.is_edit ? this.onSubmitEdit :this.onSubmitAdd}>Save</Button>{' '}
            </ModalFooter>
        </Modal>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}
export default connect(mapStateToProps)(ManageJadwalDetail);
