import React, { Component } from 'react'
import IconSuc from './../../support/assets/images/Icons/icon-suc.svg'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import {resetData} from './../../redux/action/FormAction'
import {connect  } from 'react-redux'
import NotFound from '../../components/NotFound';
import { Redirect } from 'react-router-dom'
// import { Url } from 'url';

const user = {
    role : 2
}

class RegSuccess extends Component {
    state ={
        data : null,
        loading :false,
        done : false,
        reset : false
    }
    componentDidMount(){
        
        var link = this.props.location.search
        var nisn = link.split('=')[1]
        nisn = nisn / 2
        console.log(nisn)
        Axios.get(urlApi + 'register/getuserbynisn/' + nisn)
        .then((res) => {
            console.log(res.data.data)
            this.setState({ data : res.data.data[0]})
        })
        .catch((err) => {
            console.log(err)
        }) 
    }
    onClickLinkDownload =() => {
        this.setState({loading : true})
        var data = this.state.data
        // console.log(data)
        Axios.get(urlApi+'register/downloadpdf?uid=' + data.id +'&nama=' + data.nama_lengkap + '&kelamin=' + data.kelamin + '&kabupaten=' + data.kabupaten + '&tanggal=' + data.tanggal_lahir + '&jenjang=' + data.jenjang_pendidikan + '&foto=' + data.foto)
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
        setTimeout(() => this.setState({loading : false , done:true}) , 10000)
    }
    onTambahLagiClick = () => {
        this.props.resetData()
        this.setState({reset : true})
    }
    render() {
        if(this.state.reset){
            return(
                <Redirect to='/register' />
            )
        }
        if(user.role !== 2){
            return(
                <NotFound/>
            )
        }
        if(this.state.data === null){
            
            // alert('masuk')
            return (
                <p>Loading...</p>
            )
        }
        return (
            <div className='container-fluid'>
                <div className='reg-success-container' >
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-5' style={{border:'1px solid white'}}>
                        <div className='text-reg headline-reg-suc mb-3'>
                            Registrasi { this.state.data ?  this.state.data.nama_lengkap.split(' ')[0] : null} Berhasil
                        </div>
                        <div  className='icon-img' >
                            <img width={235} src={IconSuc} alt='icon-success' />
                        </div>
                        {
                            this.props.data.username ? 
                                <div className='row justify-content-center mt-4'>
                                    <button className='btn btn-success' onClick={this.onTambahLagiClick} > Tambah Data Lagi </button>
                                </div>

                            :
                            <div className='text-center mt-3' >
                                Silahkan Ke Kantor Pusat untuk verifikasi data dan Pembayaran administrasi
                            </div>
                        }

                        {/* {this.state.done ?
                         <div className='text-reg head-reg-suc-2 mt-4'>
                            Apabila ada masalah Download, hubungi nomor panitia, terimakasih,  kembali ke <span class='link-reg-suc'> home </span>
                        </div>
                        :
                        <div className='text-reg head-reg-suc-2 mt-4'>
                            SILAHKAN DOWNLOAD KARTU REGISTRASI 
                            {this.state.loading 
                                ?
                                <span className='link-reg-suc'> LOADING.... </span>
                                :
                                <span className='link-reg-suc' onClick={this.onClickLinkDownload}> DISINI </span>
                            }
                            <br/>
                            DAN LAKUKAN DAFTAR ULANG DI  <span className='link-reg-suc'> KANTOR PPRQ </span> 
                        </div>
                        } */}
                        {/* <div className='text-reg head-reg-suc-3 mt-4'>
                        SEMUA CALON SANTRI WAJIB DIDAMPINGI ORANG TUA / WALI KETIKA DAFTAR ULANG DAN MEMBAWA KARTU PENDAFTARAN YANG TELAH DI PRINT
                        </div> */}

                    </div>
                </div>
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
  return{
    data : state.admin.dataAdmin
  }
}

export default connect(mapStateToProps,{resetData})(RegSuccess);

