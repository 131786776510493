import React, { Component } from 'react'
import './donation.css'

import {formatRupiah} from './../../support/functions/index'
import {urlApi } from './../../support/constant'
import Swal from 'sweetalert2'
import Axios from 'axios'

export class DonasiUang extends Component {
    state = {
        anonim : false,
        nominalDonasi : '',
        namaAlumni : '',
        nomorWhatsapp : '',
        pesan : '',
        error : [],
        redirectToPay :false,
        loadingButton : false,
        unique_id : null,
        metodePembayaran : null,

    }
    componentDidMount(){
        this.getMetodePembayaran()
    }

    getMetodePembayaran = () => {
        Axios.get(urlApi + 'donation/metode-pembayaran')
        .then((res) => {
            if(!res.data.error){
                this.setState({metodePembayaran : res.data.data})
            }
        })
        .catch((err) => {
            Swal.fire('error',err.message,'error')
        })
    }

    validatorCurrency = () => {
      var nominal = this.nominalDonasi.value
      console.log(nominal.toString())
      console.log(nominal.toString().includes('e'))
      if(nominal < 0 || nominal.toString().includes('e')){
          alert('masukl')
      }
     

    }

    
    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    renderListAngkatan = () => {

        var output = []
        for(var i = (2020-33) ; i <= 2020 ; i ++){
            output.push(
                <option value={i}>
                    th. {i}
                </option>
            )
        }

        return output
    }

    onLanjutPembayaranClick = () => {
        this.setState({loadingButton : true})
        var nominal = Number(this.state.nominalDonasi.split('.').join('')) 
        var metode_pembayaran = Number(this.metodePembayaran.value)
        var nama_donatur = this.state.namaAlumni
        var nomor_donatur = this.state.nomorWhatsapp
        var angkatan_ke = this.ankatanKe.value
        var pesan = this.state.pesan
        var is_anonim = this.state.anonim
        var unique_id = new Date().valueOf();

        if(nominal && metode_pembayaran !== String(0) && nama_donatur && nomor_donatur && angkatan_ke !== String(0)){
            var data = {nominal,metode_pembayaran,nama_donatur,nomor_donatur,angkatan_ke,pesan,is_anonim,unique_id}
            Axios.post(urlApi + 'donation/uang',data)
            .then((res) => {
                if(res.data.error){
                    Swal.fire('error',res.data.message,'error')
                    this.setState({loadingButton : false})
                }else{
                    window.location = '/alumni-mbangun-pprq/order-uang/' + unique_id
                }
            })
            .catch((err) => {
                Swal.fire('error',err.message,'error')
                this.setState({loadingButton : false})
            })
        }else{
            this.setState({loadingButton : false})
            Swal.fire('error','Form masih ada yang kosong','error')

        }
        
        
    }
    render() {
        return (
            <div className='py-5 section-four-donation'>
                <div className="pt-5">
                    <div className="text-center font-weight-bold blue-color header-one-donation">
                        Form Untuk Donasi Uang
                    </div>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-md-4 mt-3 mt-md-0 px-4">
                            <div className='card-wrapper-homepage pt-3 px-3 pb-5'>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Isi Nominal Donasi (min. 10.000)
                                    </div>
                                    <input required type="text" pattern="[0-9]*" placeholder='Masukan nominal' className='form-control' onChange={event => {
                                        var nominal = event.target.value.replace(/\D/,'')
                                        if(nominal.length > 1){
                                            if(nominal[0] > 0){
                                                this.setState({nominalDonasi: formatRupiah(nominal) })
                                            }
                                        }else{
                                            if(nominal > 0 || nominal === ''){
                                                this.setState({nominalDonasi: formatRupiah(nominal) })
                                            }
                                        }
                                       }} value={this.state.nominalDonasi}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Metode Pembayaran
                                    </div>
                                    <select ref={(el) => this.metodePembayaran = el} className='form-control'>
                                        <option value={0} selected disabled>Pilih Metode Pembayaran</option>
                                       {
                                           this.state.metodePembayaran === null?
                                           <option value={0}>loading ...</option>
                                           :
                                           this.state.metodePembayaran.map((val) => {
                                               return(
                                                <option value={val.id}>{val.metode}</option>
                                               )
                                           })
                                       }
                                    </select>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Nama Alumni
                                    </div>
                                    <input onChange={(e) => e.target.value.length < 100 && this.setState({namaAlumni : e.target.value}) } value={this.state.namaAlumni} placeholder='Nama Lengkapmu' type="text" className='form-control' ref={(el) => this.namaAlumni = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Nomor Whatsapp
                                    </div>
                                    <input placeholder='Nomor Whatsappmu' type="text" value={this.state.nomorWhatsapp} onChange={(e) => e.target.value.replace(/\D/,'').length < 14 && this.setState( {nomorWhatsapp : e.target.value.replace(/\D/,'')} )} className='form-control' ref={(el) => this.nomorWhatsapp = el}/>
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Tahun Masuk PPRQ                                       
                                    </div>
                                    <select ref={(el) => this.ankatanKe = el} className='form-control'>
                                        <option value={0} disabled selected> Pilih Tahun Masuk </option>
                                        {this.renderListAngkatan()}
                                    </select>
                                </p>
                               
                                <p className='desc-card-homepage mt-3'>
                                    <div className="font-weight-bold">
                                        Tulis pesan, doa atau harapan mu (optional)
                                    </div>
                                    <textarea onChange={(e) => e.target.value.length < 255 && this.setState({pesan : e.target.value})} placeholder='Semoga Semakin maju PPRQ' rows={5} className='form-control' />
                                </p>
                                <p className='desc-card-homepage mt-3'>
                                    <div className="form-check">
                                        <input onClick={() => this.setState({anonim : !this.state.anonim})} type="checkbox" checked={this.state.anonim} className="form-check-input" id="anonim"/>
                                        <label className="form-check-label font-weight-bold" for="anonim">Sembunyikan nama saya (Donasi Anonim)</label>
                                    </div>                     
                                </p>
                                <div className="row justify-content-center mt-4">
                                    {
                                        this.state.loadingButton  ?
                                        <span style={{fontSize:'16px'}}  className='btn-circle-donation disabled px-5 py-2'>
                                            loading ..
                                        </span>
                                        :
                                        <span style={{fontSize:'16px'}}  onClick={this.onLanjutPembayaranClick} className='btn-circle-donation px-5 py-2'>
                                            Lanjut ke pembayaran 
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DonasiUang
