export const StatusPembayaran = ['Menunggu Pembayaran','Menunggu Konfirmasi Admin','Success','Gagal']

export const StatusMaterial = ['Menunggu Admin Menghubungi','Menunggu Barang Sampai Ke Pondok','Success','Gagal']
export const MetodePembayaran = 'Transfer BRI_Transfer BNI_Transfer Mandiri_Transfer OVO_Transfer Gopay'.split('_')

let home = 'http://localhost:3000/alumni-mbangun-pprq'

export const UangMessage = (data,index) => {
    let lib = [
        `Assalamu'alaikum Wr. Wb\n\nHallo ${data.nama_donatur}\n\nPerkenalkan, saya admin Alumni Mbangun PPRQ, terimakasih telah berniat untuk berdonasi.\n\nHarap selesaikan pembayaran sebesar Rp. ${data.nominal} !!\n\nkunjungi ${home}/order-money/${data.unique_id} untuk melihat status donasi mu\n\nhubungi nomor ini apabila ada pertanyaan dan kendala seputar donasi\n\nTerimakasih :)`,
        `Assalamu'alaikum Wr. Wb\n\nHallo ${data.nama_donatur}\n\nPerkenalkan, saya admin Alumni Mbangun PPRQ.\n\nTerimakasih telah berdonasi sebesar Rp. ${data.nominal}, uang anda telah kami terima.\n\nkunjungi ${home}/order-money/${data.unique_id} untuk melihat status donasi mu\n\nhubungi nomor ini apabila ada pertanyaan dan kendala seputar donasi\n\nTerimakasih :)`
    ] 
    return lib[index-1]
}


export const MaterialMessage = (data,index) => {
    let lib = [
        `Assalamu'alaikum Wr. Wb\n\nHallo ${data.nama_donatur}\n\nPerkenalkan, saya admin Alumni Mbangun PPRQ, terimakasih telah berniat untuk berdonasi.\n\nBerikut data donasi anda,\n\nBentuk donasi = ${data.jenis_barang} sebanyak ${data.kuantitas_barang}\n\nkunjungi ${home}/order-money/${data.unique_id} untuk melihat status donasi mu\n\nhubungi nomor ini apabila ada pertanyaan dan kendala seputar donasi\n\nTerimakasih :)`,
        `Assalamu'alaikum Wr. Wb\n\nHallo ${data.nama_donatur}\n\nPerkenalkan, saya admin Alumni Mbangun PPRQ.\n\nBerikut data donasi anda,\nBentuk donasi = ${data.jenis_barang} sebanyak ${data.kuantitas_barang}\n\nDonasi anda telah kami terima.\n\nkunjungi ${home}/order-money/${data.unique_id} untuk melihat status donasi mu\n\nhubungi nomor ini apabila ada pertanyaan dan kendala seputar donasi\n\nTerimakasih :)`
    ] 
    return lib[index]
}


export const NumberProcess = (num) => {
    num = String(num)
    if(num[0] === '0'){
        num = num.slice(1)
        num = '62' + num
    }
    return num
}


// href={`https://wa.me/${nomor}?text=${text}`