import React, { Component } from 'react'
import {  Form, FormGroup, Input,Col,Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { saveDataAlamat } from './../../redux/action'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import Modal from './../../components/Modal'
import ModalConfrim from './../../components/ModalConfirm'
import Axios from 'axios';

const apiUrl = 'https://x.rajaapi.com/MeP7c5ne'

class StepFour extends Component {
    state = {
        provinsi : [],
        kabupaten : [],
        kecamatan : [],
        loadingKabupaten : false,
        loadingKecamatan : false,
        token : null,
        modalOpen : false,
        error : '',
        modalConfirmOpen : false,
        ayahno : null,
        ibuno : null,
        kodepos : null
    }
    
    componentDidMount(){
        Axios.get('https://x.rajaapi.com/poe')
        .then((res) => {
            if(res.data.success){
                this.setState({token : res.data.token})
                Axios.get(`https://x.rajaapi.com/MeP7c5ne${res.data.token}/m/wilayah/provinsi`)
                .then((resData) => {
                    this.setState({provinsi : resData.data.data})
                })
                .catch((err) => {
                    this.setState({modalOpen : true, error : err.message})
                })
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })
    }

    handleOnChange = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({ayahno : filter})
        }
        // alert(e.target.value)
    }

    handleOnChangeIbu = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({ibuno : filter})
        }
        // alert(e.target.value)
    }
    handleOnChangePos = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({kodepos : filter})
        }
        // alert(e.target.value)
    }
    renderOption = (state) => {
        var jsx =  state.map((val) => {
            return(
                <option key={val.id}  value={val.id} > {val.name} </option>
            )
        })
        return jsx
    }
    onChangeProvHandler = (event) => {
        this.setState({loadingKabupaten : true , kabupaten : [] , kecamatan : []})
        this.refs.kab.refs.kabInner.value = 0
        Axios.get(`${apiUrl}${this.state.token}/m/wilayah/kabupaten?idpropinsi=${event.target.value}`)
        .then((res) => {
            if(res.data.success){
                this.setState({kabupaten : res.data.data , loadingKabupaten : false})
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })
    }

    onChangeKabHandler = (event) => {
        this.setState({loadingKecamatan : true ,kecamatan : []})
        this.refs.kec.refs.kecInner.value = 0
        Axios.get(`${apiUrl}${this.state.token}/m/wilayah/kecamatan?idkabupaten=${event.target.value}`)
        .then((res) => {
            if(res.data.success){
                this.setState({kecamatan : res.data.data , loadingKecamatan : false})
            }
        })
        .catch((err) => {
            this.setState({modalOpen : true, error : "Pastikan Perangkat Anda Terhubung dengan Internet"})
        })
    }

    onBtnSelesaiKlik = () => {
        if(this.refs.check.refs.checkInner.checked){
            var ref = 'prov kab kec pos alamat ayah ibu'.split(' ')
            var dataAlamat = {}
            var check = true
            ref.forEach((val) => {
                if(this.refs[val].refs[val + 'Inner'].value === '' || this.refs[val].refs[val+'Inner'].value === 0){
                    this.setState({modalOpen : true , error : 'Semua Form Harus Diisi'})
                    check = false
                }else{
                    if(val === 'prov'){
                        var indexProv = this.refs[val].refs[val + 'Inner'].selectedIndex
                        dataAlamat[val] = this.state.provinsi[indexProv-1].name
                    }else if(val === 'kab'){
                        var indexKab = this.refs[val].refs[val + 'Inner'].selectedIndex
                        dataAlamat[val] = this.state.kabupaten[indexKab-1].name  
                    }else if(val === 'kec'){
                        var indexKec = this.refs[val].refs[val + 'Inner'].selectedIndex
                        dataAlamat[val] = this.state.kecamatan[indexKec-1].name
                    }else{
                        dataAlamat[val] = this.refs[val].refs[val + 'Inner'].value
                    }
                }
            })
            if(check){
                this.props.saveDataAlamat(dataAlamat)
                this.setState({modalConfirmOpen : true})
                console.log(dataAlamat)

                // this.props.next()
            }
        }else{
            this.setState({modalOpen : true , error : 'Harus Di Ceklis Dahulu'})
        }

  
        
    }

    render() {
        
        return (
            <Fade big>
            <div className='d-flex justify-content-center'>
                <Modal 
                onClose={() => this.setState({modalOpen : false})} 
                title = 'Error' content ={this.state.error} 
                openModal ={this.state.modalOpen}/> 

                <ModalConfrim 
                prompt = {this.props.prompt}
                onClose={() => this.setState({modalConfirmOpen : false , error : ''})} 
                title = 'Konfirmasi' content ='Setelah di klik Konfirm, Anda tidak bisa mengubah data lagi.'
                openModal ={this.state.modalConfirmOpen}/> 
                <div className='col-md-6'>
                    <div className='d-flex justify-content-between p-4 top-info'>
                        <div>
                            <h3>Form Terakhir</h3>
                            <div> Data Alamat dan Kontak </div>
                        </div>
                        <div className='icon-user'>
                            <FontAwesomeIcon icon={faAddressBook} size='4x'/>
                        </div>
                    </div>  
                    <div className='cont-small p-4'>
                        <Form>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input ref='prov' innerRef='provInner' defaultValue={0} onChange={this.onChangeProvHandler} type='select' >
                                        <option value={0} disabled> {this.state.provinsi.length > 0 ? 'Pilih Provinsi' : 'loading ...'} </option>
                                        {this.renderOption(this.state.provinsi)}
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input ref='kab' innerRef='kabInner' onChange={this.onChangeKabHandler} defaultValue={0} type='select' >
                                        <option value={0} disabled>{this.state.loadingKabupaten ? 'loading ...' : 'Pilih Kabupaten'}</option>
                                        {this.renderOption(this.state.kabupaten)}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input ref='kec' innerRef='kecInner' type='select' >
                                        <option value={0}>{this.state.loadingKecamatan ? 'loading...' : 'Pilih Kecamatan'}</option>
                                        {this.renderOption(this.state.kecamatan)}
                                    </Input>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input ref='pos' value={this.state.kodepos} onChange={this.handleOnChangePos} maxLength={5} innerRef='posInner' type='number' placeholder='Kode Pos'/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Input ref='alamat' innerRef='alamatInner' type='textarea' placeholder='Detail Alamat' />
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6} md={6}>
                                    <Input type='number' ref='ayah' value={this.state.ayahno} maxLength={13} onChange={this.handleOnChange} innerRef='ayahInner' placeholder='No Telpon Ayah'/>
                                </Col>
                                <Col sm={6} md={6} className='mt-md-0 mt-4 mt-sm-0'>
                                    <Input type='number' ref='ibu' innerRef='ibuInner' value={this.state.ibuno} maxLength={13} onChange={this.handleOnChangeIbu} placeholder='No Telpon Ibu'/>
                                </Col>
                            </FormGroup>
                            <FormGroup check className='mb-3'>
                                <Label check>
                                    <Input ref='check' innerRef='checkInner' type="checkbox" />{' '}
                                    Semua data diisi dengan benar dan dapat dipertanggungjawabkan kebenarannya 
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                                <Col xs="6" sm={4} md={4}>
                                    <Input type='button' className='btn btn-danger' onClick={this.props.back} value='Kembali' />
                                </Col>
                                <Col xs="6" sm={'4 offset-md-4 offset-sm-4'} md={'4 offset-md-4 offset-sm-4'}>
                                    <Input type='button' className='btn btn-success' onClick={this.onBtnSelesaiKlik} value='Selesai'  />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
            </Fade>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        dataAlamat : state.form.dataAlamat
    }
}

export default connect(mapStateToProps , {saveDataAlamat})(StepFour)
